import {
    SET_CAMPAIGNS_PAYMENT_REPORT,
    SET_CAMPAIGN_PAYMENT_REPORT_LOADER,
    SET_ALLOCATE_CAMPAIGN_PAYMENT_REPORT_STATUS,
  } from '../../constants/payment/paymentConstant';
  
  const initial_state = {
    all_campaigns_payment_report: [],
    campaign_payment_report_loader: false,
    allocate_campaign_payment_report_status: '',
  };
  
  export default function reducer(state = initial_state, action) {
    switch (action.type) {
      case SET_CAMPAIGNS_PAYMENT_REPORT:
        return (state = { ...state, all_campaigns_payment_report: action.payload });
      case SET_ALLOCATE_CAMPAIGN_PAYMENT_REPORT_STATUS:
        return (state = { ...state, allocate_campaign_payment_report_status: action.payload });
      case SET_CAMPAIGN_PAYMENT_REPORT_LOADER:
        return (state = { ...state, campaign_payment_report_loader: action.payload });
      default:
        return state;
    }
  }
  