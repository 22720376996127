import styled from 'styled-components';
import { Card } from '@mui/material';

export const Wrapper = styled.section`
  width: 100%;
  /* height: 20rem; */
  padding: 1.5rem;

  h3 {
    font-weight: 500;
    font-size: 1rem;
    color: #444;
    margin-bottom: 0.6rem;
  }
`;

export const CustomCard = styled(Card)`
  margin-top: 1rem;
  margin-bottom: 1.6rem;
  padding: 1rem;
  width: ${(props) => props.width || 'max-content'};
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%) !important;

  p {
    /* color: #74788d; */
    font-size: 0.85rem;
  }

  h4 {
    font-weight: 500;
    font-size: 1rem;
  }

  & > *:not(:last-child) {
    margin-bottom: 0.5rem;
  }
`;

export const Filters = styled.div`
  display: flex;
  justify-content: space-between;
  /* border-bottom: 1px solid #eee; */
  padding-bottom: 0.5rem;
`;

export const Flex = styled.div`
  display: flex;
  /* justify-content: space-between; */
  margin-bottom: 0.2rem;

  & > *:not(:last-child) {
    margin-right: 1rem;
  }
`;

export const FilterItem = styled.div`
  display: flex;
  align-items: center;

  label {
    margin-right: 0.5rem;
    /* font-size: 0.8rem; */
    color: #388e3c;
  }
`;

export const TableContainer = styled.div`
  width: 100%;
  /* overflow-x: auto; */
  margin-left: auto;
  margin-right: auto;

  &::-webkit-scrollbar {
    border-radius: 0.5rem;
    height: 0.6rem;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0.5rem;
    background-color: darkgrey;
    /* outline: 1px solid slategrey; */
  }
`;

export const ModalContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 1rem;
  border-radius: 0.7rem;
`;

export const SelectedCampaignModal = styled.div`
  position: absolute;
  right: 2rem;
  bottom: 2rem;
  /* transform: translate(20%, -50%); */
  background-color: #43a047;
  padding: 1rem;
  color: #fff;
  height: 4rem;
  width: max-content;
  display: flex;
  align-items: center;

  p {
    flex-shrink: 0;
    margin-right: 1rem;
  }
`;

export const ProductCell = styled.div`
  max-width: 12rem;
  padding: 0.5rem;
  text-align: left;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    object-fit: contain;
    object-position: center;
    margin-bottom: 0.5rem;
    border-radius: 0.5rem;
  }

  h5 {
    font-weight: 500;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 0.5rem;
  }

  h4 {
    font-weight: 500;
    width: 100%;
  }

  p {
    font-size: 0.75rem;
    width: 100%;
  }
`;

export const EmptyCampaign = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: max-content;
  /* height: 10rem; */
  /* position: absolute; */
  left: 50%;
  /* transform: translateX(-50%); */
  padding: 2rem;

  img {
    width: 16rem;
  }
`;

export const Detail = styled.div`
  width: 100%;
  background-color: #f9f9f9;
  min-height: 5rem;
  padding: 1rem;
  border-radius: 0.4rem;
`;

export const DetailHead = styled.div`
  width: 100%;
  border-bottom: 1px solid #ececec;
  display: flex;
  padding-bottom: 0.5rem;
`;

export const DetailHeadLeft = styled.div`
  width: 60%;
`;

export const DetailHeadRight = styled.div`
  width: 40%;
  display: flex;

  p {
    width: 50%;
  }
`;

export const Desc = styled.div`
  width: 100%;
  margin-top: 1rem;
  display: flex;
`;

export const AddCopyContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 50%;
  margin-right: 2rem;

  & > *:not(:last-child) {
    margin-bottom: 0.5rem;
  }
`;

export const AddCopy = styled.div`
  img {
    margin-right: 0.5rem;
  }

  img,
  video {
    width: 9rem;
    height: 6rem;
    border-radius: 0.6rem;
  }

  p {
    width: 9rem;
    margin-top: 0.4rem;
    font-size: 0.8rem;
  }
`;

export const Product = styled.div`
  display: flex;

  width: 50%;
  margin-right: 1rem;

  img {
    margin-right: 0.5rem;
    width: 3.625rem;
    height: 3.625rem;
    border-radius: 0.5rem;
  }
`;

export const ProductDetail = styled.div`
  width: 50%;
  h4 {
    font-size: 0.9rem;
  }

  p {
    font-size: 0.8rem;
  }
`;

export const ProductDescription = styled.div`
  width: 50%;
  height: max-content;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 0.5rem;
  font-size: 0.8rem;
  color: #444;

  & > * {
    margin: 0;
  }
`;

export const StatusChip = styled.div`
  font-size: 0.8rem;
  width: 7rem;
  border-radius: 4rem;
  padding: 0.4rem 0.8rem;
  background-color: ${(props) => {
    if (props.status === 'LIVE_CAMPAIGNS') {
      return '#f1f8e9';
    } else if (props.status === 'STOPPED_CAMPAIGNS') {
      return '#ffecb3';
    } else if (props.status === 'COMPLETED_CAMPAIGNS') {
      return '#e1f5fe';
    } else if (props.status === 'AWAITING_APPROVAL') {
      return '#FFF8E0';
    }
  }};

  color: ${(props) => {
    if (props.status === 'LIVE_CAMPAIGNS') {
      return '#689f38';
    } else if (props.status === 'STOPPED_CAMPAIGNS') {
      return '#ef6c00';
    } else if (props.status === 'COMPLETED_CAMPAIGNS') {
      return '#0288d1';
    } else if (props.status === 'AWAITING_APPROVAL') {
      return '#D1A307';
    }
  }};
`;

export const BusinessDetail = styled.div`
  p {
    color: #000;
    line-height: 1rem;
  }
  span {
    font-size: 0.7rem;
    color: #74788d;
  }
`;

export const SearchBox = styled.div``;

export const CustomChip = styled.div`
  width: ${(props) => props.width || 'max-content'};
  background-color: rgba(0, 0, 0, 0.08);
  border-radius: 1rem;
  height: max-content;
  padding: 0.5rem 0.8rem;
`;

export const TotalRows = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.2rem !important;
`;

export const RowCountChip = styled.div`
  border-radius: 2rem;
  padding: 0.2rem 0.8rem;
  background-color: #388e3c;
  color: #fff;
  margin-right: 0.5rem;
`;

export const StatsFlex = styled.div`
  /* display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  & > *:not(:last-child) {
    margin-right: 1rem;
  } */
  display: flex;
  align-items: center;
`;

export const StatsCard = styled(Card)`
  margin-bottom: 1rem;
  padding: 1.5rem;
  height: 9rem;
  /* width: 1fr; */
  flex: 1;
  display: flex;
  align-items: center;
  box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%) !important;

  border: 3px solid #e0e0e0;
`;

export const StatsCard2 = styled(Card)`
  margin-bottom: 1rem;
  padding: 1.5rem;
  width: 16rem;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%) !important;
  margin-right: 1rem;

  p {
    margin-bottom: 0.5rem;
  }

  h3 {
    font-size: 1.5rem;
    margin-bottom: 0;
  }
`;

export const StatCardDetails = styled.div`
  flex: 1;
  align-items: center;
  justify-content: center;
  width: ${(props) => (props.width ? props.width : '8rem')};

  p {
    margin-bottom: 0.5rem;
    /* min-width: 9rem; */
    text-align: center;
    font-size: 1rem;
  }

  h3 {
    font-size: 2rem;
    margin-bottom: 0;
    text-align: center;
  }
`;

export const GraphWrapper = styled.div`
  width: ${(props) => (props.width ? props.width : '15rem')};
  height: 5rem;
  margin-right: 0.5rem;
`;

export const TableHeadCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
`;

export const SortIcon = styled.div`
  display: flex;

  img {
    width: 0.6rem;
    margin-left: 0.3rem;
  }
`;

export const AllocateCampaign = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  float: right;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  background-color: #e0e0e0;
  margin-bottom: 1rem;
  border-radius: 0.5rem;

  p {
    margin-right: 1rem;
  }
`;

export const CampaignModalWrapper = styled.div`
  padding: 2rem 1rem 1rem;
  width: 25rem;

  h3 {
    font-weight: 500;
  }
  p {
    color: rgba(0, 0, 0, 0.5);
  }
`;

export const AlloteeList = styled.ul`
  list-style: none;
  margin-top: 1rem;
  margin-bottom: 1rem;
  max-height: 14rem;
  overflow: auto;

  & > *:not(:last-child) {
    border-bottom: 1px solid #eee;
  }

  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  p {
    color: #000;
    font-size: 1rem;
  }

  &::-webkit-scrollbar {
    width: 0.6rem;
    border-radius: 0.5rem;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0.5rem;
    background-color: darkgrey;
    /* outline: 1px solid slategrey; */
  }
`;

export const TitleFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const PdfSnapshot = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  min-width: 80rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  height: 95vh;
  overflow-y: scroll;
`;

export const PDF = styled.div`
  padding: 10px;
  /* border: 1px solid #eee; */
  font-size: 1rem;
`;

export const PDFTitle = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.5rem;

  img {
    width: 3rem;
    margin-right: 0.5rem;
  }

  h4 {
    font-weight: 500;
    font-size: 2rem;
    display: flex;
    align-items: center;
  }

  p {
    font-size: 1rem;
  }
`;

export const PDFFilters = styled.div`
  margin-bottom: 1rem;
  p {
    display: flex;
    align-items: center;
  }

  span {
    margin-left: 0.5rem;
  }
`;

export const PaymentHistory = styled.div`
  border-radius: 1rem;
  min-width: 30rem;
  padding: 1rem;

  & > *:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

export const Payment = styled.div`
  background-color: #eee;
  border-radius: 0.6rem;
  padding: 0.5rem;

  h4 {
    font-weight: 500;
  }
`;
