import React, { useEffect, useState, useRef } from 'react';
import {
  Flex,
  Header,
  Layout,
  Left,
  Main,
  Right,
  Label,
  Field,
  CostDetail,
  ModalContent,
  FileUploadLabel,
  FileUploadInput,
  ImgCont,
  Form,
  GenImage,
  GenImageWrapper,
  Image,
  RunAd,
  Cost,
  Desc,
  MobileInput,
  BusinessDetailWrapper,
  BusinessDetail,
} from './ReachReward.styles';

import {
  Button,
  Select,
  MenuItem,
  TextField,
  FormControlLabel,
  Checkbox,
  Divider,
  Modal,
  InputAdornment,
  Icon,
} from '@mui/material';
import Buffer from 'buffer';
window.Buffer = window.Buffer || Buffer.Buffer;
import { useNavigate } from 'react-router-dom';
import Lottie from 'lottie-react';
import SuccessAnimation from '../../assets/success.json';

import AnnouncementImg from '../../assets/announcement.png';
import { UploadFileToS3 } from '../../util/UploadFileToS3';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import html2canvas from 'html2canvas';

const ReachReward = ({
  business,
  reachReward,
  add_reach_reward,
  set_reach_reward_status,
  do_login,
}) => {
  const navigate = useNavigate();
  const [successModal, setSuccessModal] = useState(false);
  const [budget, setBudget] = useState('');
  const [reach, setReach] = useState('');
  const bannerEl = useRef(null);
  const [mobileLogin, setMobileLogin] = useState(false);
  const [phone, setPhone] = useState('');

  useEffect(() => {
    console.log(business, 'DETIALS');
    if (business?.details) {
      setMobileLogin(true);
    } else {
      setMobileLogin(false);
    }
  }, [business]);

  useEffect(() => {
    if (reachReward.add_reach_reward_status === 'success') {
      setSuccessModal(true);
    }
  }, [reachReward]);

  const downloadImage = async (el, imageFileName) => {
    const canvas = await html2canvas(el);
    console.log(canvas);
    const blob = canvas.toDataURL('image/png', 1.0);

    const fakeLink = window.document.createElement('a');
    fakeLink.download = 'reward.png';

    fakeLink.href = blob;

    fetch(fakeLink)
      .then((res) => res.blob())
      .then((blob) => uploadTos3(blob));

    // fakeLink.click();
  };

  const uploadTos3 = (blob) => {
    let file = new File([blob], `${Date.now()}.png`);

    UploadFileToS3(file, 'reward-banner-test')
      .then((data) => {
        console.log(data.location);
        add_reach_reward({
          business_id: business?.details?.business_id,
          reward_image: data.location,
          budget: budget,
          reach: reach,
        });
      })
      .catch((err) => {
        console.log(err, 'ERROR');
      });
  };

  // useEffect(() => {
  //   if (business === '') {
  //     navigate('/');
  //   }
  // }, [business]);

  const handle_add_reward = (el) => {
    downloadImage(el);
    // setSuccessModal(true);
  };

  // useEffect(() => {
  //   if (isTax) {
  //     let tmp = leads * leadPrice * 0.18;

  //     setAmountToPay(Math.round(tmp + leads * leadPrice));
  //   } else {
  //     setAmountToPay(leads * leadPrice);
  //   }
  // }, [campaignBudget, campaignDiscount, isTax, leadPrice, leads]);

  if (!mobileLogin) {
    return (
      <Layout>
        <h3>Add reach reward</h3>
        <MobileInput width="100%">
          <TextField
            label="Phone no."
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
          <Button
            sx={{ marginLeft: '1rem', height: 'max-content' }}
            variant="contained"
            onClick={() => do_login(phone)}
          >
            Submit
          </Button>
        </MobileInput>
      </Layout>
    );
  }

  return (
    <Layout>
      <h3>Add reach reward</h3>
      <BusinessDetailWrapper>
        <div>
          <BusinessDetail>
            <p>Business name: </p>
            <TextField
              size="small"
              readOnly
              value={business?.details?.business_name}
            />
          </BusinessDetail>
          <BusinessDetail>
            <p>Business category: </p>
            <TextField
              size="small"
              readOnly
              value={business?.details?.business_category}
            />
          </BusinessDetail>
          <BusinessDetail>
            <p>Mobile No: </p>
            <TextField
              size="small"
              readOnly
              value={business?.details?.mobile}
            />
          </BusinessDetail>
          <BusinessDetail>
            <p>Address: </p>
            <TextField
              size="small"
              readOnly
              value={`${business?.details?.address_2},${business?.details?.city}`}
            />
          </BusinessDetail>
        </div>
        <Button variant="outlined" onClick={() => setMobileLogin(false)}>
          {' '}
          Change Business
        </Button>
      </BusinessDetailWrapper>
      <Main width="100%">
        <Left>
          {/* <Form> */}
          <Field>
            <Label>Budget</Label>
            <TextField
              error={isNaN(budget)}
              helperText={isNaN(budget) ? 'Number only' : ''}
              size="small"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">Rs.</InputAdornment>
                ),
              }}
              value={budget}
              onChange={(e) => setBudget(e.target.value)}
            />
          </Field>
          <Field>
            <Label>Reach</Label>
            <TextField
              error={isNaN(reach)}
              helperText={isNaN(reach) ? 'Number only' : ''}
              size="small"
              fullWidth
              value={reach}
              onChange={(e) => setReach(e.target.value)}
            />
          </Field>

          <Field>
            <Button
              disabled={reach === '' || budget === ''}
              variant="contained"
              onClick={() =>
                handle_add_reward(bannerEl.current, `${Date.now()}`)
              }
            >
              Submit
            </Button>
          </Field>

          {/* </Form> */}
        </Left>
        <Right>
          <GenImageWrapper>
            <h4>Image Banner</h4>
            <Image ref={bannerEl}>
              <Desc style={{ margin: '0.5rem 0' }}>
                &#8377;<Cost>&nbsp;{Math.round(budget)}</Cost>/-
              </Desc>

              <Desc>
                Start running your ad at just
                <br /> &#8377; {Math.round(budget)} and get {reach} reaches
              </Desc>
              <img src={AnnouncementImg} />
              <RunAd>
                <p>Run your ad</p>
                <TrendingFlatIcon
                  sx={{
                    margin: '0',
                    marginBottom: '-0.2rem',
                    fontSize: '1.4rem',
                    padding: '0',
                  }}
                />
              </RunAd>
            </Image>
          </GenImageWrapper>
        </Right>
      </Main>
      <Modal
        open={successModal}
        onClose={() => {
          set_reach_reward_status(false);
          setSuccessModal(false);
        }}
      >
        <ModalContent>
          <Lottie
            style={{
              width: '10rem',
            }}
            animationData={SuccessAnimation}
            loop={false}
          />
          <p>Reward Added</p>
          <Button
            variant="contained"
            onClick={() => {
              set_reach_reward_status(false);
              setSuccessModal(false);
            }}
          >
            Ok
          </Button>
        </ModalContent>
      </Modal>
    </Layout>
  );
};

export default ReachReward;
