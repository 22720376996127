import { combineReducers } from 'redux';
import snackbar from './snackbar/snackbarReducers';
import business from './business/businessReducers';
import login from './login/loginReducers';
import campaign from './campaign/campaignReducers';
import revenueReport from './revenueReport/revenueReportReducers';
import leadReward from './leadReward/leadRewardReducers';
import reachReward from './reachReward/reachRewardReducers';
import paymentReport from './payment/paymentReducer';
import rewardsStatus from "./rewardsStatus/rewardsStatusReducer";
import paymentLink from "./paymentLink/paymentLinkReducers"

export default combineReducers({
  login,
  business,
  campaign,
  leadReward,
  reachReward,
  revenueReport,
  paymentReport,
  rewardsStatus,
  paymentLink,
  snackbar,
});
