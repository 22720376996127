import {
  SET_REVENUE_REPORTS,
  SET_REVENUE_REPORT_LOADER,
  SET_SALES_REACH_REPORTS,
  SET_SALES_REACH_REPORT_LOADER,
  SET_SALES_LEADS_REPORTS,
  SET_SALES_LEADS_REPORT_LOADER,
} from '../../constants/revenueReport/revenueReportConstants';

const initial_state = {
  all_revenue_reports: [],
  revenue_report_loader: false,
};

export default function reducer(state = initial_state, action) {
  switch (action.type) {
    case SET_REVENUE_REPORTS:
      return (state = { ...state, all_revenue_reports: action.payload });
    case SET_REVENUE_REPORT_LOADER:
      return (state = { ...state, revenue_report_loader: action.payload });
    case SET_SALES_REACH_REPORTS:
      return (state = { ...state, all_revenue_reports: action.payload });
    case SET_SALES_REACH_REPORT_LOADER:
      return (state = { ...state, revenue_report_loader: action.payload });
    case SET_SALES_LEADS_REPORTS:
      return (state = { ...state, all_revenue_reports: action.payload });
    case SET_SALES_LEADS_REPORT_LOADER:
      return (state = { ...state, revenue_report_loader: action.payload });
    default:
      return state;
  }
}
