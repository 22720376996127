import {
  SET_REACH_REWARD,
  SET_REACH_REWARD_STATUS,
} from '../../constants/reachReward/reachRewardConst';

const initial_state = {
  add_reach_reward_status: false,
  add_reach_reward: '',
};

export default function reducer(state = initial_state, action) {
  switch (action.type) {
    case SET_REACH_REWARD_STATUS:
      return (state = { ...state, add_reach_reward_status: action.payload });
    case SET_REACH_REWARD:
      return (state = { ...state, add_reach_reward: action.payload });
    default:
      return state;
  }
}
