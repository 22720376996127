import UNIVERSAL from '../../config/config';
import { SET_REACH_REWARD_STATUS } from '../../constants/reachReward/reachRewardConst';
import { set_lead_reward_status } from '../leadReward/leadRewardActions';

export function add_reach_reward(reward) {
  return (dispatch) => {
    dispatch(set_reach_reward_status('loading'));
    return fetch(UNIVERSAL.BASEURL + '/sales/v2/add_reward', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': '6gZ2J0WfUx9iAmKIwvwG3NiX1783yn094tzrssHe',
      },
      body: JSON.stringify({
        business_id: `${reward.business_id}`,
        reward_type: 'BUSINESS_REACH',
        // is_fb_needed: reward.is_fb_needed,
        reward_sent_by: reward.reward_sent_by,
        reward_details: {
          reward_image: reward.reward_image,
          budget: reward.budget * 1,
          reach: reward.reach * 1,
        },subscription: {
          years: reward.subscription === '1 year'  ? 1 :  0,
          months: reward.subscription === '1 months' ? 1  :
          reward.subscription === '3 months' ? 3  :   
          reward.subscription === '4 months' ? 4:
          reward.subscription === '5 months' ? 5:
          reward.subscription === '6 months' ? 6:
          reward.subscription === '7 months' ? 7:
          reward.subscription === '8 months' ? 8:
          reward.subscription === '9 months' ? 9:
          reward.subscription === '10 months' ? 10:
          reward.subscription === '11 months' ? 11:
       
          0,
        },
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.success === true) {
          dispatch(set_reach_reward_status('success'));
          dispatch(set_lead_reward_status(true));
          //   dispatch(set_payment_link(responseJson.data.payment_link));
        } else {
          //   dispatch(
          //     set_login_snackbar({
          //       status: 'error',
          //       message: responseJson.message,
          //     })
          //   );
          set_reach_reward_status('error');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function set_reach_reward_status(payload) {
  return {
    type: SET_REACH_REWARD_STATUS,
    payload: payload,
  };
}
