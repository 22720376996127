import S3FileUpload from 'react-s3';
// import fs from 'fs';
import config from '../config/config';
const { BUCKET_ACCESS_KEY, BUCKET_SECRET_ACCESS_KEY, BUCKET_REGION } = config;

function S3Config(brand_name) {
  const config = {
    bucketName: 'zocket-lp-data',
    dirName: brand_name || 'test-brand' /* optional */,
    region: BUCKET_REGION,
    accessKeyId: BUCKET_ACCESS_KEY,
    secretAccessKey: BUCKET_SECRET_ACCESS_KEY,
  };
  return config;
}

function UploadFileToS3(file, brand_name) {
  return new Promise((resolve, reject) => {
    S3FileUpload.uploadFile(file, S3Config(brand_name))
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
}

export { UploadFileToS3 };
