import React from 'react';
import {
  set_reach_reward_status,
  add_reach_reward,
} from '../../actions/reachReward/reachRewardActions';
import {
  set_reload_login,
  logout,
  do_login,
} from '../../actions/login/loginActions';
import ReachReward from '../../pages/reachReward/ReachReward';
import { connect } from 'react-redux';

const ReachRewardContainer = (props) => {
  return <ReachReward {...props} />;
};

const mapStateToProps = (store) => {
  return {
    login: store.login,
    business: store.business,
    reachReward: store.reachReward,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => {
      dispatch(logout());
    },
    do_login: (number) => {
      dispatch(do_login(number));
    },
    set_reload_login: (user) => {
      dispatch(set_reload_login(user));
    },
    set_reach_reward_status: (status) => {
      dispatch(set_reach_reward_status(status));
    },
    add_reach_reward: (reward) => {
      dispatch(add_reach_reward(reward));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReachRewardContainer);
