import styled from 'styled-components';
import { Card } from '@mui/material';

export const Layout = styled.div`
  min-height: 90h;
  margin-top: 1rem;
  padding: 1rem;

  h3 {
    font-weight: 500;
    font-size: 1rem;
    color: #444;
    margin-bottom: 0.6rem;
  }
`;

export const Form = styled.form`
  width: 20rem;
`;

export const Label = styled.p`
  font-size: 1rem;
  margin-top: 1rem;
`;

export const Field = styled.div`
  margin-bottom: 1rem;
  width: 25rem;
  border-color: #c2c2c2;
  border-width: 2px;
  /* border-bottom: 1px solid #c1c1c1; */
`;

export const PhoneFlex = styled.div`
  display: flex;
  align-items: center;

  & > *:not(:last-child) {
    margin-right: 0.5rem;
  }
`;

export const SuccessModal = styled.div`
  width: 25rem;
  height: 18rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  a {
    font-size: 0.9rem;
    margin-left: 0.5rem;
    font-style: italic;
  }

  h5 {
    font-weight: 500;
    font-size:1rem
  }
`;

export const LinkFlex = styled.div`
  display: flex;
  align-items: center;

  p {
    margin-right: 0.5rem;
  }
`;

export const MobileInput = styled(Card)`
  margin-top: 1rem;
  margin-bottom: 1.6rem;
  padding: 1rem;
  width: ${(props) => props.width || 'max-content'};
  display: flex;
  align-items: center;
  justify-content: center;
  /* align-items: center; */
  box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%) !important;

  p {
    /* color: #74788d; */
    font-size: 0.85rem;
  }

  h4 {
    font-weight: 500;
    font-size: 1rem;
  }
`;

export const BusinessDetailWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const BusinessDetail = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 0.5rem;
  p {
    width: 10rem;
  }
`;

export const FormCard = styled(Card)`
width: 100% !important; 
margin-top: 1rem;
margin-bottom: 1.6rem;
padding: 2rem 4rem;
width: ${(props) => props.width || 'max-content'};
display: flex;
/* align-items: center; */
box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%) !important;

p {
  /* color: #74788d; */
  font-size: 0.85rem;
}

h4 {
  font-weight: 500;
  font-size: 1rem;
}

& > *:not(:last-child) {
  margin-bottom: 0.5rem;
}

`