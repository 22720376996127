import React, { useEffect, useState } from 'react';
import {
  Wrapper,
  CustomCard,
  Flex,
  FilterItem,
  TableContainer,
  ProductCell,
  ModalContent,
  EmptyCampaign,
  Detail,
  DetailHead,
  DetailHeadLeft,
  DetailHeadRight,
  Desc,
  AddCopyContainer,
  AddCopy,
  Product,
  ProductDetail,
  ProductDescription,
  StatusChip,
  BusinessDetail,
  Filters,
  CustomChip,
  TotalRows,
  RowCountChip,
  StatsFlex,
  StatsCard,
  SortIcon,
  TableHeadCell,
} from './NeedAttention.styles';
import {
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  IconButton,
  Select,
  MenuItem,
  TextField,
  Pagination,
  Link,
  Modal,
  CircularProgress,
  Skeleton,
  InputAdornment,
} from '@mui/material';

import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import SearchIcon from '@mui/icons-material/Search';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import Cake from '../../assets/cake.jpg';
import NoDataImg from '../../assets/noData.jpg';
import AddCopyImg from '../../assets/addCopy.png';
import SortImg from '../../assets/sort.png';
import { AUTHORIZED_USERS } from '../../config/accessPermission';

const NeedAttention = ({
  login,
  campaign,
  get_campaigns,
  set_reload_login,
}) => {
  const navigate = useNavigate();
  const [allCampaign, setAllCampaign] = useState([]);
  const [productModal, setProductModal] = useState(false);
  const [toDate, setToDate] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [expandedRow, setExpandedRow] = useState(null);
  const [campaignStatus, setCampaignStatus] = useState('ALL_STATUS');
  const [filtered, setFiltered] = useState([]);
  const [businessSearch, setBusinessSearch] = useState('');
  const [page, setPage] = useState(1);
  const [needAttentionCampaign, setNeedAttentionCampaign] = useState([]);
  const [sortCol, setSortCol] = useState('');
  const [sortType, setSortType] = useState(0);

  useEffect(() => {
    setAllCampaign(campaign.all_campaigns);
  }, [campaign]);

  useEffect(() => {
    if (!AUTHORIZED_USERS.includes(login.email)) {
      console.log('JJJJ');
      navigate('/unauthorized');
    }
  }, [login]);

  useEffect(() => {
    if (
      businessSearch === '' &&
      campaignStatus === 'ALL_STATUS' &&
      sortCol !== ''
    ) {
      let tmp1 = campaign.all_campaigns.filter((item) =>
        // (item.results !== 0 && item.spend / item.results > 150) ||
        // (item.results === 0 && item.spend > 300)
        {
          if (item.results === 0 && item.spend > 300) {
            return true;
          } else if (item.results !== 0 && item.spend / item.results > 150) {
            return true;
          }
        }
      );
      if (sortCol === 'leadsBought') {
        tmp1.sort((a, b) => b.promised_leads - a.promised_leads);
      } else if (sortCol === 'budget') {
        tmp1.sort((a, b) => b.budget - a.budget);
      } else if (sortCol === 'leadsReceived') {
        tmp1.sort((a, b) => b.results - a.results);
      } else if (sortCol === 'amountSpent') {
        tmp1.sort((a, b) => b.spend - a.spend);
      } else if (sortCol === 'costPerResult') {
        tmp1.sort((a, b) => b.spend / b.results - a.spend / a.results);

        let splitIndex = 0;

        tmp1.map((item, i) => {
          if (!item.results || item.results === 0) {
            splitIndex = i;
          }
        });

        let emptyVal = tmp1.slice(0, splitIndex);
        let actualVal = tmp1.slice(splitIndex);
        // tmp1.reverse();
        tmp1 = [...actualVal, ...emptyVal];
      } else if (sortCol === 'margin') {
        tmp1.sort(
          (a, b) =>
            (b.budget - b.spend) / b.budget - (a.budget - a.spend) / a.budget
        );

        let splitIndex = 0;

        tmp1.map((item, i) => {
          if (!item.spend || !item.budget) {
            splitIndex = i;
          }
        });

        let emptyVal = tmp1.slice(0, splitIndex);
        let actualVal = tmp1.slice(splitIndex);
        // tmp1.reverse();
        tmp1 = [...actualVal, ...emptyVal];
      } else if (sortCol === 'ctr') {
        tmp1.sort((a, b) => b.ctr - a.ctr);
      } else if (sortCol === 'convRate') {
        tmp1.sort((a, b) =>
          !b.clicks || !b.results || !a.results || !a.clicks
            ? 1
            : b.results / b.clicks - a.results / a.clicks
        );
      } else if (sortCol === 'createdAt') {
        tmp1.sort(
          (a, b) =>
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        );
      } else if (sortCol === 'endAt') {
        tmp1.sort(
          (a, b) =>
            new Date(b.campaign_end_date).getTime() -
            new Date(a.campaign_end_date).getTime()
        );
      }

      if (sortType === 1) {
        tmp1.reverse();
      }

      setNeedAttentionCampaign([...tmp1]);
    } else if (
      businessSearch !== '' ||
      (campaignStatus !== 'ALL_STATUS' && sortCol !== '')
    ) {
      let tmp2 = filtered;
      if (sortCol === 'leadsBought') {
        tmp2.sort((a, b) => b.promised_leads - a.promised_leads);
      } else if (sortCol === 'budget') {
        tmp2.sort((a, b) => b.budget - a.budget);
      } else if (sortCol === 'leadsReceived') {
        tmp2.sort((a, b) => b.results - a.results);
      } else if (sortCol === 'amountSpent') {
        tmp2.sort((a, b) => b.spend - a.spend);
      } else if (sortCol === 'costPerResult') {
        tmp2.sort((a, b) => b.spend / b.results - a.spend / a.results);

        let splitIndex = 0;

        tmp2.map((item, i) => {
          if (!item.results || item.results === 0) {
            splitIndex = i;
          }
        });

        let emptyVal = tmp2.slice(0, splitIndex);
        let actualVal = tmp2.slice(splitIndex + 1, -1);
        // tmp2.reverse();
        tmp2 = [...actualVal, ...emptyVal];
      } else if (sortCol === 'margin') {
        tmp2.sort(
          (a, b) =>
            (b.budget - b.spend) / b.budget - (a.budget - a.spend) / a.budget
        );

        let splitIndex = 0;

        tmp2.map((item, i) => {
          if (!item.spend || !item.budget) {
            splitIndex = i;
          }
        });

        let emptyVal = tmp2.slice(0, splitIndex);
        let actualVal = tmp2.slice(splitIndex + 1, -1);
        // tmp2.reverse();
        tmp2 = [...actualVal, ...emptyVal];
      } else if (sortCol === 'ctr') {
        tmp2.sort((a, b) => b.ctr - a.ctr);
      } else if (sortCol === 'convRate') {
        tmp2.sort((a, b) =>
          !b.clicks || !b.results || !a.results || !a.clicks
            ? 1
            : b.results / b.clicks - a.results / a.clicks
        );
      } else if (sortCol === 'createdAt') {
        tmp2.sort(
          (a, b) =>
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        );
      } else if (sortCol === 'endAt') {
        tmp2.sort(
          (a, b) =>
            new Date(b.campaign_end_date).getTime() -
            new Date(a.campaign_end_date).getTime()
        );
      }
      if (sortType === 1) {
        tmp2.reverse();
      }
      setFiltered([...tmp2]);
    }
  }, [sortCol, sortType]);

  useEffect(() => {
    var tmp = [];
    tmp = campaign.all_campaigns.filter((item) =>
      // (item.results !== 0 && item.spend / item.results > 150) ||
      // (item.results === 0 && item.spend > 300)
      {
        if (item.results === 0 && item.spend > 300) {
          return true;
        } else if (item.results !== 0 && item.spend / item.results > 150) {
          return true;
        }
      }
    );

    setNeedAttentionCampaign(tmp);
  }, [campaign]);

  useEffect(() => {
    var today = new Date();

    var to = `${today.getFullYear()}-${
      today.getMonth() + 1 < 10
        ? `0${today.getMonth() + 1}`
        : today.getMonth() + 1
    }-${today.getDate() < 10 ? `0${today.getDate()}` : today.getDate()}`;

    today.setDate(today.getDate() - 14);

    var from = `${today.getFullYear()}-${
      today.getMonth() + 1 < 10
        ? `0${today.getMonth() + 1}`
        : today.getMonth() + 1
    }-${today.getDate() < 10 ? `0${today.getDate()}` : today.getDate()}`;

    setFromDate(from);
    setToDate(to);

    get_campaigns(from, to);
  }, []);

  useEffect(() => {
    get_campaigns(fromDate, toDate);
  }, [toDate, fromDate]);

  useEffect(() => {
    if (businessSearch === '' && campaignStatus === 'ALL_STATUS') {
      setFiltered([]);
    } else {
      var newList = [];
      if (campaignStatus === 'ALL_STATUS' && businessSearch !== '') {
        newList = campaign.all_campaigns.filter((item) => {
          var tmp = item?.business_name?.toLowerCase();
          return tmp.includes(businessSearch.toLowerCase());
        });
      } else if (campaignStatus !== 'ALL_STATUS' && businessSearch === '') {
        newList = campaign.all_campaigns.filter((item) => {
          return item.status === campaignStatus;
        });
      } else if (campaignStatus !== 'ALL_STATUS' && businessSearch !== '') {
        newList = campaign.all_campaigns.filter((item) => {
          var tmp = item?.business_name?.toLowerCase();
          return (
            tmp.includes(businessSearch.toLowerCase()) &&
            item.status === campaignStatus
          );
        });
      }
      newList = newList.filter(
        (item) =>
          item.spend / item.results > 150 ||
          (item.results === 0 && item.spend > 300)
      );
      setFiltered(newList);
      setPage(1);
    }
  }, [businessSearch, campaignStatus]);

  // useEffect(() => {
  //   if (filtered.length !== 0) {
  //     var tmp = [];

  //     tmp = filtered.filter(
  //       (item) =>
  //         item.spend / item.results > 150 ||
  //         (item.results === 0 && item.spend > 300)
  //     );

  //     setFiltered(tmp);
  //   }
  // }, [filtered]);

  useEffect(() => {
    if (sessionStorage.getItem('zocket_token')) {
      const token = sessionStorage.getItem('zocket_token');
      const loginData = JSON.parse(sessionStorage?.getItem('zocket_login'));
      set_reload_login({ token, ...loginData });
    } else {
      navigate('/');
    }
  }, [sessionStorage.getItem('zocket_token')]);

  const get_add_copy = (media) =>
    media?.map((item, i) => {
      return (
        <AddCopy key={i}>
          {item.adImage && <img src={item.adImage} />}
          {item.adVideo && (
            <video width="144" height="96" controls>
              <source src={item.adVideo} type="video/mp4" />
            </video>
          )}
          <p>{item.adCaption}</p>
        </AddCopy>
      );
    });

  useEffect(() => {
    console.log(filtered, needAttentionCampaign);
  }, [filtered]);

  const handle_sort = (col) => {
    setSortCol(col);
    if (sortType === 1) {
      setSortType(0);
    } else if (sortType === 0) {
      setSortType(1);
      // setSortCol("")
    }
  };

  return (
    <Wrapper>
      <h3>Need Attention</h3>
      <StatsFlex>
        <StatsCard>
          <p>Need Attention</p>
          <h3>
            {filtered.length === 0
              ? !campaign.campaign_loader &&
                (businessSearch !== '' || campaignStatus !== 'ALL_STATUS') &&
                filtered.length === 0
                ? '0'
                : needAttentionCampaign.length
              : filtered.length}
          </h3>
        </StatsCard>
      </StatsFlex>
      <CustomCard width="100%">
        <Filters>
          <Flex>
            <FilterItem>
              <searchBox>
                <TextField
                  sx={{ width: '20rem' }}
                  size="small"
                  placeholder="Enter Business Name"
                  value={businessSearch}
                  onChange={(e) => setBusinessSearch(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon sx={{ fontSize: '1.3rem' }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </searchBox>
            </FilterItem>
          </Flex>
          <Flex>
            <FilterItem>
              <label>Campaign Status:</label>
              <Select
                size="small"
                style={{ width: '10rem' }}
                value={campaignStatus}
                onChange={(e) => setCampaignStatus(e.target.value)}
              >
                <MenuItem value="ALL_STATUS">All Status</MenuItem>
                <MenuItem value="LIVE_CAMPAIGNS">Live Now</MenuItem>
                <MenuItem value="STOPPED_CAMPAIGNS">Paused</MenuItem>
                <MenuItem value="COMPLETED_CAMPAIGNS">Completed</MenuItem>
                <MenuItem value="AWAITING_APPROVAL">Pending</MenuItem>
              </Select>
            </FilterItem>
            <FilterItem>
              <label>From:</label>
              <TextField
                size="small"
                style={{ width: '10rem' }}
                type="date"
                value={fromDate}
                onChange={(e) => setFromDate(e.target.value)}
              />
            </FilterItem>
            <FilterItem>
              <label>To:</label>
              <TextField
                size="small"
                style={{ width: '10rem' }}
                type="date"
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
              />
            </FilterItem>
          </Flex>
        </Filters>
        {/* <TotalRows>
          <RowCountChip>
            {' '}
            {filtered.length === 0
              ? !campaign.campaign_loader &&
                (businessSearch !== '' || campaignStatus !== 'ALL_STATUS') &&
                filtered.length === 0
                ? '0'
                : campaign.all_campaigns.length
              : filtered.length}
          </RowCountChip>
          <p>campaign needs attention</p>
        </TotalRows> */}
        <TableContainer>
          <Table sx={{ minWidth: 650, width: '100%' }}>
            <TableHead
              sx={{
                backgroundColor: '#F8F8F8',
                color: 'rgba(0, 0, 0, 0.5)',
                padding: '0',
                border: '1.5px solid #EAEAEA',
              }}
            >
              <TableRow
                sx={{
                  fontSize: '0.8rem',
                  padding: '0',
                }}
              >
                <TableCell
                  sx={{
                    color: 'rgba(0, 0, 0, 0.5)',
                    paddingTop: '0.5rem',
                    paddingBottom: '0.5rem',
                  }}
                  align="center"
                >
                  <p>Campaign ID</p>
                </TableCell>
                <TableCell
                  sx={{
                    color: 'rgba(0, 0, 0, 0.5)',
                    paddingTop: '0.5rem',
                    paddingBottom: '0.5rem',
                  }}
                  align="center"
                >
                  <p>Business ID</p>
                </TableCell>
                <TableCell
                  sx={{
                    color: 'rgba(0, 0, 0, 0.5)',
                    // borderRadius: '1rem 0 0 1rem',
                    width: '12rem',
                    paddingTop: '0.5rem',
                    paddingBottom: '0.5rem',
                  }}
                  align="center"
                >
                  <p>Business Name & Category</p>
                </TableCell>

                <TableCell
                  sx={{
                    color: 'rgba(0, 0, 0, 0.5)',
                    paddingTop: '0.5rem',
                    paddingBottom: '0.5rem',
                  }}
                  align="center"
                >
                  <p>Type of Purchase</p>
                </TableCell>
                <TableCell
                  sx={{
                    color: 'rgba(0, 0, 0, 0.5)',
                    paddingTop: '0.5rem',
                    paddingBottom: '0.5rem',
                    cursor: 'pointer',
                  }}
                  align="center"
                  onClick={() => handle_sort('leadsBought')}
                >
                  <TableHeadCell>
                    <p>Leads Bought</p>
                    <SortIcon>
                      {sortCol === 'leadsBought' && sortType === 1 && (
                        <ArrowDropUpIcon style={{ fontSize: '1.4rem' }} />
                      )}{' '}
                      {sortCol === 'leadsBought' && sortType === 0 && (
                        <ArrowDropDownIcon style={{ fontSize: '1.4rem' }} />
                      )}
                      {sortCol !== 'leadsBought' && <img src={SortImg} />}
                    </SortIcon>
                  </TableHeadCell>
                </TableCell>
                <TableCell
                  sx={{
                    color: 'rgba(0, 0, 0, 0.5)',
                    paddingTop: '0.5rem',
                    paddingBottom: '0.5rem',
                    cursor: 'pointer',
                  }}
                  align="center"
                  onClick={() => handle_sort('budget')}
                >
                  <TableHeadCell>
                    <p>Budget</p>
                    <SortIcon>
                      {sortCol === 'budget' && sortType === 1 && (
                        <ArrowDropUpIcon style={{ fontSize: '1.4rem' }} />
                      )}{' '}
                      {sortCol === 'budget' && sortType === 0 && (
                        <ArrowDropDownIcon style={{ fontSize: '1.4rem' }} />
                      )}
                      {sortCol !== 'budget' && <img src={SortImg} />}
                    </SortIcon>
                  </TableHeadCell>
                </TableCell>
                <TableCell
                  sx={{
                    color: 'rgba(0, 0, 0, 0.5)',
                    paddingTop: '0.5rem',
                    paddingBottom: '0.5rem',
                    cursor: 'pointer',
                  }}
                  align="center"
                  onClick={() => handle_sort('leadsReceived')}
                >
                  <TableHeadCell>
                    <p>Leads Received</p>
                    <SortIcon>
                      {sortCol === 'leadsReceived' && sortType === 1 && (
                        <ArrowDropUpIcon style={{ fontSize: '1.4rem' }} />
                      )}{' '}
                      {sortCol === 'leadsReceived' && sortType === 0 && (
                        <ArrowDropDownIcon style={{ fontSize: '1.4rem' }} />
                      )}
                      {sortCol !== 'leadsReceived' && <img src={SortImg} />}
                    </SortIcon>
                  </TableHeadCell>
                </TableCell>

                <TableCell
                  sx={{
                    color: 'rgba(0, 0, 0, 0.5)',
                    paddingTop: '0.5rem',
                    paddingBottom: '0.5rem',
                    cursor: 'pointer',
                  }}
                  align="center"
                  onClick={() => handle_sort('amountSpent')}
                >
                  <TableHeadCell>
                    <p>Amount Spent</p>
                    <SortIcon>
                      {sortCol === 'amountSpent' && sortType === 1 && (
                        <ArrowDropUpIcon style={{ fontSize: '1.4rem' }} />
                      )}{' '}
                      {sortCol === 'amountSpent' && sortType === 0 && (
                        <ArrowDropDownIcon style={{ fontSize: '1.4rem' }} />
                      )}
                      {sortCol !== 'amountSpent' && <img src={SortImg} />}
                    </SortIcon>
                  </TableHeadCell>
                </TableCell>
                <TableCell
                  sx={{
                    color: 'rgba(0, 0, 0, 0.5)',
                    paddingTop: '0.5rem',
                    paddingBottom: '0.5rem',
                    cursor: 'pointer',
                  }}
                  align="center"
                  onClick={() => handle_sort('costPerResult')}
                >
                  <TableHeadCell>
                    <p>Cost Per Result</p>
                    <SortIcon>
                      {sortCol === 'costPerResult' && sortType === 1 && (
                        <ArrowDropUpIcon style={{ fontSize: '1.4rem' }} />
                      )}{' '}
                      {sortCol === 'costPerResult' && sortType === 0 && (
                        <ArrowDropDownIcon style={{ fontSize: '1.4rem' }} />
                      )}
                      {sortCol !== 'costPerResult' && <img src={SortImg} />}
                    </SortIcon>
                  </TableHeadCell>
                </TableCell>
                <TableCell
                  sx={{
                    color: 'rgba(0, 0, 0, 0.5)',
                    paddingTop: '0.5rem',
                    paddingBottom: '0.5rem',
                    cursor: 'pointer',
                  }}
                  align="center"
                  onClick={() => handle_sort('margin')}
                >
                  <TableHeadCell>
                    <p>Margin</p>
                    <SortIcon>
                      {sortCol === 'margin' && sortType === 1 && (
                        <ArrowDropUpIcon style={{ fontSize: '1.4rem' }} />
                      )}{' '}
                      {sortCol === 'margin' && sortType === 0 && (
                        <ArrowDropDownIcon style={{ fontSize: '1.4rem' }} />
                      )}
                      {sortCol !== 'margin' && <img src={SortImg} />}
                    </SortIcon>
                  </TableHeadCell>
                </TableCell>
                <TableCell
                  sx={{
                    color: 'rgba(0, 0, 0, 0.5)',
                    paddingTop: '0.5rem',
                    paddingBottom: '0.5rem',
                    cursor: 'pointer',
                  }}
                  align="center"
                  onClick={() => handle_sort('ctr')}
                >
                  <TableHeadCell>
                    <p>CTR</p>
                    <SortIcon>
                      {sortCol === 'ctr' && sortType === 1 && (
                        <ArrowDropUpIcon style={{ fontSize: '1.4rem' }} />
                      )}{' '}
                      {sortCol === 'ctr' && sortType === 0 && (
                        <ArrowDropDownIcon style={{ fontSize: '1.4rem' }} />
                      )}
                      {sortCol !== 'ctr' && <img src={SortImg} />}
                    </SortIcon>
                  </TableHeadCell>
                </TableCell>
                <TableCell
                  sx={{
                    color: 'rgba(0, 0, 0, 0.5)',
                    paddingTop: '0.5rem',
                    paddingBottom: '0.5rem',
                    cursor: 'pointer',
                  }}
                  align="center"
                  onClick={() => handle_sort('convRate')}
                >
                  <TableHeadCell>
                    Conv Rate
                    <SortIcon>
                      {sortCol === 'convRate' && sortType === 1 && (
                        <ArrowDropUpIcon style={{ fontSize: '1.4rem' }} />
                      )}{' '}
                      {sortCol === 'convRate' && sortType === 0 && (
                        <ArrowDropDownIcon style={{ fontSize: '1.4rem' }} />
                      )}
                      {sortCol !== 'convRate' && <img src={SortImg} />}
                    </SortIcon>
                  </TableHeadCell>
                </TableCell>
                <TableCell
                  sx={{
                    color: 'rgba(0, 0, 0, 0.5)',
                    paddingTop: '0.5rem',
                    paddingBottom: '0.5rem',
                  }}
                  align="center"
                >
                  <p>Campaign Status</p>
                </TableCell>

                <TableCell
                  sx={{
                    color: 'rgba(0, 0, 0, 0.5)',
                    paddingTop: '0.5rem',
                    paddingBottom: '0.5rem',
                    cursor: 'pointer',
                  }}
                  onClick={() => handle_sort('createdAt')}
                  align="center"
                >
                  <TableHeadCell>
                    <p>Created At</p>
                    <SortIcon>
                      {sortCol === 'createdAt' && sortType === 1 && (
                        <ArrowDropUpIcon style={{ fontSize: '1.4rem' }} />
                      )}{' '}
                      {sortCol === 'createdAt' && sortType === 0 && (
                        <ArrowDropDownIcon style={{ fontSize: '1.4rem' }} />
                      )}
                      {sortCol !== 'createdAt' && <img src={SortImg} />}
                    </SortIcon>
                  </TableHeadCell>
                </TableCell>
                <TableCell
                  sx={{
                    color: 'rgba(0, 0, 0, 0.5)',
                    paddingTop: '0.5rem',
                    paddingBottom: '0.5rem',
                    cursor: 'pointer',
                  }}
                  align="center"
                  onClick={() => handle_sort('endAt')}
                >
                  <TableHeadCell>
                    <p>End Date</p>
                    <SortIcon>
                      {sortCol === 'endAt' && sortType === 1 && (
                        <ArrowDropUpIcon style={{ fontSize: '1.4rem' }} />
                      )}{' '}
                      {sortCol === 'endAt' && sortType === 0 && (
                        <ArrowDropDownIcon style={{ fontSize: '1.4rem' }} />
                      )}
                      {sortCol !== 'endAt' && <img src={SortImg} />}
                    </SortIcon>
                  </TableHeadCell>
                </TableCell>
                <TableCell
                  sx={{
                    color: 'rgba(0, 0, 0, 0.5)',
                    // borderRadius: ' 0 1rem 1rem 0',
                  }}
                />
              </TableRow>
            </TableHead>

            {needAttentionCampaign.length === 0 &&
              campaign.campaign_loader === false && (
                <TableBody>
                  <TableRow>
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell colSpan={6}>
                      <EmptyCampaign>
                        <p>No campaigns to show!!</p>
                        <img src={NoDataImg} />
                      </EmptyCampaign>
                    </TableCell>
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                  </TableRow>
                  {/* <EmptyCampaign>
                    <p>No campaigns to show!!</p>
                    <img src={NoDataImg} />
                  </EmptyCampaign> */}
                </TableBody>
              )}
            {campaign.campaign_loader && (
              <TableBody>
                {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((row, i) => {
                  return (
                    <>
                      <TableRow
                        key={i}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          <Skeleton
                            variant="rectangular"
                            width="100%"
                            height={10}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <Skeleton
                            variant="rounded"
                            width="100%"
                            height={15}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <Skeleton
                            variant="rounded"
                            width="100%"
                            height={15}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <Skeleton
                            variant="rounded"
                            width="100%"
                            height={15}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <Skeleton
                            variant="rounded"
                            width="100%"
                            height={15}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <Skeleton
                            variant="rounded"
                            width="100%"
                            height={15}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <Skeleton
                            variant="rounded"
                            width="100%"
                            height={15}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <Skeleton
                            variant="rounded"
                            width="100%"
                            height={15}
                          />
                        </TableCell>{' '}
                        <TableCell align="center">
                          <Skeleton
                            variant="rounded"
                            width="100%"
                            height={15}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <Skeleton
                            variant="rounded"
                            width="100%"
                            height={15}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <Skeleton
                            variant="rounded"
                            width="100%"
                            height={15}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <Skeleton
                            variant="rounded"
                            width="100%"
                            height={15}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <Skeleton
                            variant="rounded"
                            width="100%"
                            height={15}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <Skeleton
                            variant="rounded"
                            width="100%"
                            height={15}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <Skeleton
                            variant="rounded"
                            width="100%"
                            height={15}
                          />
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })}
              </TableBody>
            )}
            {!campaign.campaign_loader &&
              (businessSearch !== '' || campaignStatus !== 'ALL_STATUS') &&
              filtered.length === 0 && (
                <TableBody>
                  <TableRow>
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell colSpan={6}>
                      <EmptyCampaign>
                        <p>No campaigns to show!!</p>
                        <img src={NoDataImg} />
                      </EmptyCampaign>
                    </TableCell>
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                  </TableRow>
                </TableBody>
              )}
            {!campaign.campaign_loader && (
              <TableBody>
                {filtered.length !== 0 &&
                  filtered
                    .slice((page - 1) * 10, (page - 1) * 10 + 10)
                    .map((row, i) => (
                      <>
                        <TableRow
                          key={row.id}
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                          }}
                        >
                          <TableCell align="center">
                            {row.campaign_id}
                          </TableCell>
                          <TableCell align="center">
                            {row.business_id}
                          </TableCell>
                          <TableCell
                            sx={{ width: '12rem' }}
                            component="th"
                            scope="row"
                          >
                            <BusinessDetail>
                              <p>{row.business_name}</p>
                              <span>{row.business_category}</span>
                            </BusinessDetail>
                          </TableCell>

                          <TableCell sx={{ width: '9rem' }} align="center">
                            {row.campaign_objective.replaceAll('_', ' ')}
                          </TableCell>
                          <TableCell align="center">
                            {row.promised_leads}
                          </TableCell>
                          <TableCell sx={{ width: '6rem' }} align="center">
                            &#8377; {`${row.budget}`}
                          </TableCell>
                          <TableCell align="center">{row.results}</TableCell>
                          <TableCell align="center">
                            {' '}
                            &#8377; {`${row.spend}`}
                          </TableCell>
                          <TableCell align="center">
                            &#8377;{' '}
                            {row.results !== 0
                              ? parseFloat(row.spend / row.results).toFixed(2)
                              : 0}
                          </TableCell>
                          <TableCell align="center">
                            {row.spend === 0
                              ? '-'
                              : `${parseFloat(
                                  ((row.budget - row.spend) / row.budget) * 100
                                ).toFixed(2)} %`}
                          </TableCell>
                          <TableCell align="center">
                            {!row.ctr || row.ctr === 0
                              ? '-'
                              : `${parseFloat(row.ctr).toFixed(2)} %`}
                          </TableCell>
                          <TableCell align="center">
                            {row.results === null ||
                            row.clicks === null ||
                            row.clicks === 0
                              ? '-'
                              : `${parseFloat(
                                  (row.results / row.clicks) * 100
                                ).toFixed(2)} %`}
                          </TableCell>

                          <TableCell align="center">
                            <StatusChip status={row.status}>
                              {row.status === 'AWAITING_APPROVAL'
                                ? 'Pending'
                                : row.status === 'COMPLETED_CAMPAIGNS'
                                ? 'Completed'
                                : row.status === 'STOPPED_CAMPAIGNS'
                                ? 'Paused'
                                : 'Live Now'}
                            </StatusChip>
                          </TableCell>
                          {/* <TableCell align="center">{row.protein}</TableCell> */}

                          <TableCell sx={{ width: '7rem' }} align="center">
                            {moment(row.created_at).format('ll')}
                          </TableCell>
                          <TableCell sx={{ width: '7rem' }} align="center">
                            {moment(row.campaign_end_date).format('ll')}
                          </TableCell>
                          <TableCell>
                            <IconButton
                              onClick={() =>
                                expandedRow === null
                                  ? setExpandedRow(i)
                                  : expandedRow === i
                                  ? setExpandedRow(null)
                                  : setExpandedRow(i)
                              }
                            >
                              {expandedRow === i ? (
                                <KeyboardArrowUpIcon
                                  sx={{
                                    fontSize: '1rem',
                                    border: '1px solid #1977F3',
                                    borderRadius: '50%',
                                  }}
                                />
                              ) : (
                                <KeyboardArrowDownIcon
                                  sx={{
                                    fontSize: '1rem',
                                    border: '1px solid #1977F3',
                                    borderRadius: '50%',
                                  }}
                                />
                              )}
                            </IconButton>
                          </TableCell>
                        </TableRow>
                        {expandedRow === i && (
                          <TableRow>
                            <TableCell colSpan={16}>
                              <Detail>
                                <DetailHead>
                                  <DetailHeadLeft>
                                    <p>Ad Copies</p>
                                  </DetailHeadLeft>
                                  <DetailHeadRight
                                    style={{
                                      display:
                                        row.media === null ? 'none' : 'flex',
                                    }}
                                  >
                                    <p>Product</p>
                                    <p>Product Description</p>
                                  </DetailHeadRight>
                                </DetailHead>
                                <Desc>
                                  <AddCopyContainer>
                                    {get_add_copy(
                                      JSON.parse(row.campaign_platform_meta)
                                        .adPreview
                                    )}
                                  </AddCopyContainer>
                                  <Product
                                    style={{
                                      display:
                                        row.media === null ? 'none' : 'flex',
                                    }}
                                  >
                                    {JSON.parse(row.media) !== null && (
                                      <img
                                        src={JSON.parse(row.media)[0]?.url}
                                      />
                                    )}
                                    <ProductDetail
                                      style={{
                                        display:
                                          row.media === null ? 'none' : 'block',
                                      }}
                                    >
                                      <h4>{row.product_name}</h4>
                                      <p>Price : {row.selling_price}</p>
                                    </ProductDetail>
                                    <ProductDescription>
                                      {row.promotion_tags !== null &&
                                        JSON.parse(row?.promotion_tags)
                                          .length !== 0 &&
                                        JSON.parse(row?.promotion_tags)?.map(
                                          (item, i) => {
                                            return (
                                              <CustomChip key={i}>
                                                {item}
                                              </CustomChip>
                                            );
                                          }
                                        )}
                                      {row.promotion_tags !== null &&
                                        JSON.parse(row?.promotion_tags)
                                          .length === 0 && (
                                          <CustomChip>
                                            {row.product_description
                                              ? row.product_description
                                              : 'No Description'}
                                          </CustomChip>
                                        )}
                                    </ProductDescription>
                                  </Product>
                                </Desc>
                              </Detail>
                            </TableCell>
                          </TableRow>
                        )}
                      </>
                    ))}
              </TableBody>
            )}
            {!campaign.campaign_loader && (
              <TableBody>
                {filtered.length === 0 &&
                  businessSearch === '' &&
                  campaignStatus === 'ALL_STATUS' &&
                  needAttentionCampaign
                    .slice((page - 1) * 10, (page - 1) * 10 + 10)
                    ?.map((row, i) => (
                      <>
                        <TableRow
                          key={row.id}
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                          }}
                        >
                          <TableCell align="center">
                            {row.campaign_id}
                          </TableCell>
                          <TableCell align="center">
                            {row.business_id}
                          </TableCell>
                          <TableCell
                            sx={{ width: '12rem' }}
                            component="th"
                            scope="row"
                          >
                            <BusinessDetail>
                              <p>{row.business_name}</p>
                              <span>{row.business_category}</span>
                            </BusinessDetail>
                          </TableCell>

                          <TableCell sx={{ width: '9rem' }} align="center">
                            {row.campaign_objective.replaceAll('_', ' ')}
                          </TableCell>
                          <TableCell align="center">
                            {row.promised_leads}
                          </TableCell>
                          <TableCell sx={{ width: '6rem' }} align="center">
                            &#8377; {`${row.budget}`}
                          </TableCell>
                          <TableCell align="center">{row.results}</TableCell>
                          <TableCell align="center">
                            {' '}
                            &#8377; {`${row.spend}`}
                          </TableCell>
                          <TableCell align="center">
                            &#8377;{' '}
                            {row.results !== 0
                              ? parseFloat(row.spend / row.results).toFixed(2)
                              : 0}
                          </TableCell>
                          <TableCell align="center">
                            {row.spend === 0
                              ? '-'
                              : `${parseFloat(
                                  ((row.budget - row.spend) / row.budget) * 100
                                ).toFixed(2)} %`}
                          </TableCell>
                          <TableCell align="center">
                            {!row.ctr || row.ctr === 0
                              ? '-'
                              : `${parseFloat(row.ctr).toFixed(2)} %`}
                          </TableCell>
                          <TableCell align="center">
                            {row.results === null ||
                            row.clicks === null ||
                            row.clicks === 0
                              ? '-'
                              : `${parseFloat(
                                  (row.results / row.clicks) * 100
                                ).toFixed(2)} %`}
                          </TableCell>
                          <TableCell align="center">
                            {/* {row.campaign_status} */}
                            <StatusChip status={row.status}>
                              {row.status === 'AWAITING_APPROVAL'
                                ? 'Pending'
                                : row.status === 'COMPLETED_CAMPAIGNS'
                                ? 'Completed'
                                : row.status === 'STOPPED_CAMPAIGNS'
                                ? 'Paused'
                                : 'Live Now'}
                            </StatusChip>
                          </TableCell>

                          <TableCell sx={{ width: '7rem' }} align="center">
                            {moment(row.created_at).format('ll')}
                          </TableCell>
                          <TableCell sx={{ width: '7rem' }} align="center">
                            {moment(row.campaign_end_date).format('ll')}
                          </TableCell>
                          <TableCell>
                            <IconButton
                              onClick={() =>
                                expandedRow === null
                                  ? setExpandedRow(i)
                                  : expandedRow === i
                                  ? setExpandedRow(null)
                                  : setExpandedRow(i)
                              }
                            >
                              {expandedRow === i ? (
                                <KeyboardArrowUpIcon
                                  sx={{
                                    fontSize: '1rem',
                                    border: '1px solid #1977F3',
                                    borderRadius: '50%',
                                  }}
                                />
                              ) : (
                                <KeyboardArrowDownIcon
                                  sx={{
                                    fontSize: '1rem',
                                    border: '1px solid #1977F3',
                                    borderRadius: '50%',
                                  }}
                                />
                              )}
                            </IconButton>
                          </TableCell>
                        </TableRow>
                        {expandedRow === i && (
                          <TableRow>
                            <TableCell colSpan={16}>
                              <Detail>
                                <DetailHead>
                                  <DetailHeadLeft>
                                    <p>Ad Copies</p>
                                  </DetailHeadLeft>
                                  <DetailHeadRight
                                    style={{
                                      display:
                                        row.media === null ? 'none' : 'flex',
                                    }}
                                  >
                                    <p>Product</p>
                                    <p>Product Description</p>
                                  </DetailHeadRight>
                                </DetailHead>
                                <Desc>
                                  <AddCopyContainer>
                                    {get_add_copy(
                                      JSON.parse(row?.campaign_platform_meta)
                                        .adPreview
                                    )}
                                  </AddCopyContainer>
                                  <Product
                                    style={{
                                      display:
                                        row.media === null ? 'none' : 'flex',
                                    }}
                                  >
                                    {JSON.parse(row.media) !== null && (
                                      <img
                                        src={JSON.parse(row.media)[0]?.url}
                                      />
                                    )}
                                    <ProductDetail
                                      style={{
                                        display:
                                          row.media === null ? 'none' : 'block',
                                      }}
                                    >
                                      <h4>{row?.product_name}</h4>
                                      <p>Price : {row?.selling_price}</p>
                                    </ProductDetail>
                                    <ProductDescription>
                                      {row.promotion_tags !== null &&
                                        JSON.parse(row?.promotion_tags)?.map(
                                          (item, i) => {
                                            return (
                                              <CustomChip key={i}>
                                                {item}
                                              </CustomChip>
                                            );
                                          }
                                        )}
                                      {row.promotion_tags !== null &&
                                        JSON.parse(row.promotion_tags)
                                          .length === 0 && (
                                          <CustomChip>
                                            {row.product_description
                                              ? row.product_description
                                              : 'No Description'}
                                          </CustomChip>
                                        )}
                                    </ProductDescription>
                                  </Product>
                                </Desc>
                              </Detail>
                            </TableCell>
                          </TableRow>
                        )}
                      </>
                    ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>

        <Pagination
          sx={{
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: '2rem',
          }}
          count={
            filtered.length === 0
              ? Math.ceil(needAttentionCampaign.length / 10)
              : Math.ceil(filtered.length / 10)
          }
          page={page}
          onChange={(e, val) => setPage(val)}
          color="secondary"
        />
      </CustomCard>
      <Modal open={productModal} onClose={() => setProductModal(false)}>
        <ModalContent>
          <ProductCell>
            <img src={Cake} />
            <h5>Chocolate Truffle Cake</h5>
            <h4>Rs. 800</h4>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent
              consequat mauris
            </p>
          </ProductCell>
        </ModalContent>
      </Modal>
    </Wrapper>
  );
};

export default NeedAttention;
