import React from 'react';

import { connect } from 'react-redux';
import {
  do_login,
  set_reload_login,
  do_static_login,
  set_login,
} from '../../actions/login/loginActions';
import Login from '../../pages/login/Login';
// import {
//   reset_login_snackbar,
//   set_login_snackbar,
// } from '../../actions/snackbar/snackbarActions';

const LoginContainer = (props) => {
  return <Login {...props} />;
};

const mapStateToProps = (store) => {
  return {
    login: store.login,
    snackbar: store.snackbar,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    do_login: (user) => {
      dispatch(do_login(user));
    },
    do_static_login: (user) => {
      dispatch(do_static_login(user));
    },
    set_reload_login: (user) => {
      dispatch(set_reload_login(user));
    },
    set_login: (data) => {
      dispatch(set_login(data));
    },
    // reset_login_snackbar: () => {
    //   dispatch(reset_login_snackbar());
    // },
    // set_login_snackbar: (payload) => {
    //   dispatch(set_login_snackbar(payload));
    // },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);
