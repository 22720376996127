import { Edit } from '@mui/icons-material';
import {
  TextField,
  InputAdornment,
  FormControlLabel,
  Checkbox,
  IconButton,
  Button,
  Modal,
  Tooltip,
} from '@mui/material';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import Lottie from 'lottie-react';
import React, { useEffect, useState } from 'react';
import {
  Form,
  Label,
  Layout,
  LinkFlex,
  PhoneFlex,
  SuccessModal,
  MobileInput,
  BusinessDetailWrapper,
  BusinessDetail,
  FormCard
} from './PaymentLink.styles';
import SuccessAnimation from '../../assets/success.json';

const PaymentLink = ({
  login,
  business,
  paymentLink,
  generate_payment_link,
  set_payment_link_status,
  do_login,
}) => {
  const [sendSms, setSendSms] = useState(false);
  const [amount, setAmount] = useState('');
  const [amountTopay, setAmountToPay] = useState('');
  const [tax, setTax] = useState('');
  const [phone, setPhone] = useState('');
  const [editPhone, setEditPhone] = useState(true);
  const [copied, setCopied] = useState(false);
  const [mobileLogin, setMobileLogin] = useState(false);

  useEffect(() => {
    console.log(business, 'DETIALS');
    if (business?.details) {
      setMobileLogin(true);
    } else {
      setMobileLogin(false);
    }
  }, [business]);

  useEffect(() => {
    let tmp = 0;

    tmp = amount * 0.18;
    tmp = parseFloat(tmp).toFixed(2);
    setTax(tmp);
    tmp = tmp * 1 + amount * 1;
    setAmountToPay(tmp);
  }, [amount]);

  useEffect(() => {
    setPhone(business?.details?.mobile);
  }, [business]);

  if (!mobileLogin) {
    return (
      <Layout>
        <h3>Generate payment link</h3>
        <MobileInput width="100%">
          <TextField
            label="Phone no."
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
          <Button
            sx={{ marginLeft: '1rem', height: 'max-content' }}
            variant="contained"
            onClick={() => do_login(phone)}
          >
            Submit
          </Button>
        </MobileInput>
      </Layout>
    );
  }

  return (
    <Layout variant="bordered">
      <h3>Generate payment link</h3>
      <BusinessDetailWrapper>
        <div>
          <BusinessDetail>
            <p>Business name: </p>
            <TextField
              size="small"
              readOnly
              value={business?.details?.business_name}
            />
          </BusinessDetail>
          <BusinessDetail>
            <p>Business category: </p>
            <TextField
              size="small"
              readOnly
              value={business?.details?.business_category}
            />
          </BusinessDetail>
          <BusinessDetail>
            <p>Mobile No: </p>
            <TextField
              size="small"
              readOnly
              value={business?.details?.mobile}
            />
          </BusinessDetail>
          <BusinessDetail>
            <p>Address: </p>
            <TextField
              size="small"
              readOnly
              value={`${business?.details?.address_2},${business?.details?.city}`}
            />
          </BusinessDetail>
        </div>
        <Button variant="outlined" onClick={() => setMobileLogin(false)}>
          {' '}
          Change Business
        </Button>
      </BusinessDetailWrapper>
      <FormCard>
      <Form>
        <Label>Amount</Label>
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">Rs.</InputAdornment>
            ),
          }}
        />
        <Label>Tax 18%</Label>
        <TextField
          fullWidth
          disabled
          variant="outlined"
          size="small"
          value={tax}
          InputProps={{
            readOnly: true,
            startAdornment: (
              <InputAdornment position="start">Rs.</InputAdornment>
            ),
          }}
        />
        <Label>Total amount to pay</Label>
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          value={amountTopay}
          InputProps={{
            readOnly: true,
            startAdornment: (
              <InputAdornment position="start">Rs.</InputAdornment>
            ),
          }}
        />
        {/* <FormControlLabel
          style={{ margin: 0, marginTop: '0.5rem' }}
          control={
            <Checkbox
              style={{ margin: 0, marginRight: '0.5rem' }}
              checked={sendSms}
              onChange={(e) => setSendSms(e.target.checked)}
            />
          }
          label="Send link as SMS as well"
        /> */}

        <br />
        <br />
        <Button
          variant="contained"
          onClick={() =>
            generate_payment_link(business?.details?.business_id, amount, phone)
          }
        >
          Submit
        </Button>
      </Form>
      </FormCard>
      <Modal
        open={paymentLink?.payment_link_status === 'success'}
        onClose={() => {
          setCopied(false);
          set_payment_link_status(false);
        }}
      >
        <SuccessModal>
          <Lottie
            style={{
              width: '8rem',
            }}
            animationData={SuccessAnimation}
            loop={false}
          />
          <h5>Payment link generated</h5>
          <LinkFlex>
            <p>
              <a href={paymentLink?.payment_link}>{paymentLink?.payment_link}</a>
            </p>
            <Tooltip title={copied ? 'Copied!' : 'Copy to clipboard'}>
              <IconButton
                size="small"
                onClick={() => {
                  setCopied(true);
                  navigator.clipboard.writeText(paymentLink?.payment_link);
                }}
              >
                <ContentPasteIcon style={{ fontSize: '120%' }} />
              </IconButton>
            </Tooltip>
          </LinkFlex>
        </SuccessModal>
      </Modal>
    </Layout>
  );
};

export default PaymentLink;
