import React, { useEffect, useRef, useState } from 'react';
import {
  Wrapper,
  CustomCard,
  Flex,
  FilterItem,
  TableContainer,
  ProductCell,
  ModalContent,
  EmptyCampaign,
  Detail,
  DetailHead,
  DetailHeadLeft,
  DetailHeadRight,
  Desc,
  AddCopyContainer,
  AddCopy,
  Product,
  ProductDetail,
  ProductDescription,
  StatusChip,
  BusinessDetail,
  Filters,
  CustomChip,
  TotalRows,
  RowCountChip,
  StatsFlex,
  StatsCard,
  TableHeadCell,
  SortIcon,
  GraphWrapper,
  StatCardDetails,
  SelectedCampaignModal,
  AllocateCampaign,
  CampaignModalWrapper,
  AlloteeList,
  StatsCard2,
  TitleFlex,
  PdfSnapshot,
  PDF,
  PDFTitle,
  PDFFilters,
  PaymentHistory,
  Payment,
} from './SalesLeadsReport.styles';
import {
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  IconButton,
  Select,
  MenuItem,
  TextField,
  Pagination,
  Link,
  Modal,
  CircularProgress,
  Skeleton,
  InputAdornment,
  Checkbox,
  Button,
  Radio,
  Popover,
  Divider,
  Tooltip,
} from '@mui/material';

import { AUTHORIZED_USERS, SUPER_ADMIN } from '../../config/accessPermission';
import { useNavigate } from 'react-router-dom';
// import CanvasJSReact from '../../assets/canvasjs.react';
import { CategoryScale } from 'chart.js';
import Chart from 'chart.js/auto';
import moment from 'moment';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import SearchIcon from '@mui/icons-material/Search';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SaveIcon from '@mui/icons-material/Save';
import SouthIcon from '@mui/icons-material/South';
import NorthIcon from '@mui/icons-material/North';
import PaymentsIcon from '@mui/icons-material/Payments';
import { Line } from 'react-chartjs-2';

import Cake from '../../assets/cake.jpg';
import NoDataImg from '../../assets/noData.jpg';
import AddCopyImg from '../../assets/addCopy.png';
import SortImg from '../../assets/sort.png';
import logo from '../../assets/logo.png';

// import { html2pdf } from 'html2pdf.js';
// import PDF, { Html } from 'jspdf-react';
import jsPDF from 'jspdf';
import { CSVLink } from 'react-csv';

const SalesLeadsReport = ({
  login,
  campaign,
  business,
  get_campaigns,
  get_campaigns_by_role,
  allocate_campaign,
  get_marketing_consultants,
  set_allocte_campaign_status,
  set_reload_login,
  get_payment_history,
}) => {
  const navigate = useNavigate();
  const [allCampaign, setAllCampaign] = useState([]);
  const [productModal, setProductModal] = useState(false);
  const [toDate, setToDate] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [expandedRow, setExpandedRow] = useState(null);
  const [campaignStatus, setCampaignStatus] = useState('ALL_STATUS');
  const [sales, setSales] = useState('ALL_SALES');
  const [filtered, setFiltered] = useState([]);
  const [businessSearch, setBusinessSearch] = useState('');
  const [page, setPage] = useState(1);
  const [sortCol, setSortCol] = useState('');
  const [sortType, setSortType] = useState(0);
  const [allocateCampaignModal, setAllocateCampaignModal] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState([]);
  const [consultants, setConsultants] = useState([]);
  const [selectedAllotee, setSelectedAllotee] = useState('');
  const [alloteeSearch, setAlloteeSearch] = useState('');
  const [pdfModal, setPdfModal] = useState(false);
  const [paymentHistoryAnchor, setPaymentHistoryAnchor] = useState('');
  const [csv, setCsv] = useState();
  const [csvData, setCsvData] = useState();

  useEffect(() => {
    if (!AUTHORIZED_USERS.includes(login.email)) {
      console.log('JJJJ');
      navigate('/unauthorized');
    }
  }, [login]);

  useEffect(() => {
    get_marketing_consultants();
  }, []);

  useEffect(() => {
    set_allocte_campaign_status('');
  }, []);

  useEffect(() => {
    setConsultants(campaign.marketing_consultant);
  }, [campaign]);

  useEffect(() => {
    if (alloteeSearch === '') {
      setConsultants(campaign.marketing_consultant);
    } else {
      var filter_data = campaign.marketing_consultant.filter((item) =>
        item.consultant_name.toLowerCase().includes(alloteeSearch.toLowerCase())
          ? true
          : false
      );

      setConsultants(filter_data);
    }
  }, [alloteeSearch]);

  useEffect(() => {
    setAllCampaign(campaign.all_campaigns);
  }, [campaign]);

  useEffect(() => {
    var today = new Date();

    var to = `${today.getFullYear()}-${today.getMonth() + 1 < 10
      ? `0${today.getMonth() + 1}`
      : today.getMonth() + 1
      }-${today.getDate() < 10 ? `0${today.getDate()}` : today.getDate()}`;

    today.setDate(today.getDate() - 14);

    var from = `${today.getFullYear()}-${today.getMonth() + 1 < 10
      ? `0${today.getMonth() + 1}`
      : today.getMonth() + 1
      }-${today.getDate() < 10 ? `0${today.getDate()}` : today.getDate()}`;

    setFromDate(from);
    setToDate(to);

    if (SUPER_ADMIN.includes(login.email)) {
      get_campaigns(from, to);
    } else {
      console.log('CHECK 1');
      get_campaigns_by_role(login.email, from, to);
    }
  }, [login]);

  useEffect(() => {
    if (SUPER_ADMIN.includes(login.email)) {
      get_campaigns(fromDate, toDate);
    } else {
      get_campaigns_by_role(login.email, fromDate, toDate);
    }
  }, [toDate, fromDate]);

  console.log(campaign.all_campaigns);

  useEffect(() => {
    setSelectedCampaign([]);
    setSelectedAllotee('');
    if (businessSearch === '' && campaignStatus === 'ALL_STATUS' && sales === "ALL_SALES") {
      setFiltered([]);
    } else {
      var newList = [];
      // here
      if (campaignStatus === 'ALL_STATUS' && businessSearch !== '' && sales === "ALL_SALES") {
        newList = campaign.all_campaigns.filter((item) => {
          var tmp = item?.business_name?.toLowerCase();
          return tmp.includes(businessSearch.toLowerCase()) || item.mobile.includes(businessSearch);
        });
      } else if (campaignStatus !== 'ALL_STATUS' && businessSearch === '' && sales === "ALL_SALES") {
        newList = campaign.all_campaigns.filter((item) => {
          return item.status === campaignStatus;
        });
      } else if (campaignStatus === 'ALL_STATUS' && businessSearch === '' && sales !== 'ALL_SALES') {
        newList = campaign.all_campaigns.filter((item) => {
          return item.SalesType === sales
        });
      } else if (campaignStatus !== 'ALL_STATUS' && businessSearch !== '' && sales === "ALL_SALES") {
        newList = campaign.all_campaigns.filter((item) => {
          var tmp = item?.business_name?.toLowerCase();
          return (
            (tmp.includes(businessSearch.toLowerCase()) || item.mobile.includes(businessSearch)) &&
            item.status === campaignStatus
          );
        });
      } else if (campaignStatus !== 'ALL_STATUS' && businessSearch === '' && sales !== "ALL_SALES") {
        newList = campaign.all_campaigns.filter((item) => {
          return (
            item.status === campaignStatus && item.SalesType === sales
          );
        });
      } else if (campaignStatus === 'ALL_STATUS' && businessSearch !== '' && sales !== "ALL_SALES") {
        newList = campaign.all_campaigns.filter((item) => {
          var tmp = item?.business_name?.toLowerCase();
          return (
            (tmp.includes(businessSearch.toLowerCase()) || item.mobile.includes(businessSearch)) &&
            item.SalesType === sales
          );
        });
      } else if (campaignStatus !== 'ALL_STATUS' && businessSearch !== '' && sales !== "ALL_SALES") {
        newList = campaign.all_campaigns.filter((item) => {
          var tmp = item?.business_name?.toLowerCase();
          return (
            (tmp.includes(businessSearch.toLowerCase()) || item.mobile.includes(businessSearch)) &&
            item.status === campaignStatus && item.SalesType === sales
          );
        });
      }
      setFiltered(newList);
      setPage(1);
    }
  }, [businessSearch, campaignStatus, sales]);

  useEffect(() => {
    if (
      businessSearch === '' &&
      campaignStatus === 'ALL_STATUS' &&
      sales === 'ALL_SALES' &&
      sortCol !== ''
    ) {
      let tmp1 = allCampaign;
      if (sortCol === 'leadsBought') {
        tmp1.sort((a, b) => b.promised_leads - a.promised_leads);
      } else if (sortCol === 'budget') {
        tmp1.sort((a, b) => b.budget - a.budget);
      } else if (sortCol === 'leadsReceived') {
        tmp1.sort((a, b) => b.results - a.results);
      } else if (sortCol === 'amountSpent') {
        tmp1.sort((a, b) => b.spend - a.spend);
      } else if (sortCol === 'costPerResult') {
        tmp1.sort((a, b) => b.spend / b.results - a.spend / a.results);

        let splitIndex = 0;

        tmp1.map((item, i) => {
          if (!item.results || item.results === 0) {
            splitIndex = i;
          }
        });

        let emptyVal = tmp1.slice(0, splitIndex);
        let actualVal = tmp1.slice(splitIndex + 1, -1);
        // tmp1.reverse();
        tmp1 = [...actualVal, ...emptyVal];
      } else if (sortCol === 'margin') {
        tmp1.sort(
          (a, b) =>
            (b.budget - b.spend) / b.budget - (a.budget - a.spend) / a.budget
        );

        let splitIndex = 0;

        tmp1.map((item, i) => {
          if (!item.spend || !item.budget) {
            splitIndex = i;
          }
        });

        let emptyVal = tmp1.slice(0, splitIndex);
        let actualVal = tmp1.slice(splitIndex + 1, -1);
        // tmp1.reverse();
        tmp1 = [...actualVal, ...emptyVal];
      } else if (sortCol === 'ctr') {
        tmp1.sort((a, b) => b.ctr - a.ctr);
      } else if (sortCol === 'leadBalance') {
        tmp1.sort(
          (a, b) =>
            b.promised_leads - b.results - (a.promised_leads - a.results)
        );
      } else if (sortCol === 'convRate') {
        tmp1.sort((a, b) =>
          !b.clicks || !b.results || !a.results || !a.clicks
            ? 1
            : b.results / b.clicks - a.results / a.clicks
        );
      } else if (sortCol === 'createdAt') {
        tmp1.sort(
          (a, b) =>
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        );
      } else if (sortCol === 'endAt') {
        tmp1.sort(
          (a, b) =>
            new Date(b.campaign_end_date).getTime() -
            new Date(a.campaign_end_date).getTime()
        );
      } else if (sortCol === 'city') {
        tmp1.sort((a, b) =>
          b?.city?.toLowerCase().localeCompare(a?.city.toLowerCase())
        );
      } else if (sortCol === 'phone') {
        tmp1.sort((a, b) => b.mobile - a.mobile);
      }
      if (sortType === 1) {
        tmp1.reverse();
      }

      setAllCampaign([...tmp1]);
    } else if (
      businessSearch !== '' ||
      (campaignStatus !== 'ALL_STATUS' || sales !== 'ALL_SALES' && sortCol !== '')
    ) {
      let tmp2 = filtered;
      if (sortCol === 'leadsBought') {
        tmp2.sort((a, b) => b.promised_leads - a.promised_leads);
      } else if (sortCol === 'budget') {
        tmp2.sort((a, b) => b.budget - a.budget);
      } else if (sortCol === 'leadsReceived') {
        tmp2.sort((a, b) => b.results - a.results);
      } else if (sortCol === 'amountSpent') {
        tmp2.sort((a, b) => b.spend - a.spend);
      } else if (sortCol === 'costPerResult') {
        tmp2.sort((a, b) => b.spend / b.results - a.spend / a.results);

        let splitIndex = 0;

        tmp2.map((item, i) => {
          if (!item.results || item.results === 0) {
            splitIndex = i;
          }
        });

        let emptyVal = tmp2.slice(0, splitIndex);
        let actualVal = tmp2.slice(splitIndex + 1, -1);
        // tmp2.reverse();
        tmp2 = [...actualVal, ...emptyVal];
      } else if (sortCol === 'margin') {
        tmp2.sort(
          (a, b) =>
            (b.budget - b.spend) / b.budget - (a.budget - a.spend) / a.budget
        );

        let splitIndex = 0;

        tmp2.map((item, i) => {
          if (!item.spend || !item.budget) {
            splitIndex = i;
          }
        });

        let emptyVal = tmp2.slice(0, splitIndex);
        let actualVal = tmp2.slice(splitIndex + 1, -1);
        // tmp2.reverse();
        tmp2 = [...actualVal, ...emptyVal];
      } else if (sortCol === 'ctr') {
        tmp2.sort((a, b) => b.ctr - a.ctr);
      } else if (sortCol === 'leadBalance') {
        tmp2.sort(
          (a, b) =>
            b.promised_leads - b.results - (a.promised_leads - a.results)
        );
      } else if (sortCol === 'convRate') {
        tmp2.sort((a, b) =>
          !b.clicks || !b.results || !a.results || !a.clicks
            ? 1
            : b.results / b.clicks - a.results / a.clicks
        );
      } else if (sortCol === 'createdAt') {
        tmp2.sort(
          (a, b) =>
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        );
      } else if (sortCol === 'endAt') {
        tmp2.sort(
          (a, b) =>
            new Date(b.campaign_end_date).getTime() -
            new Date(a.campaign_end_date).getTime()
        );
      } else if (sortCol === 'city') {
        tmp2.sort((a, b) =>
          b?.city?.toLowerCase().localeCompare(a?.city.toLowerCase())
        );
      } else if (sortCol === 'phone') {
        tmp2.sort((a, b) => b.mobile - a.mobile);
      }
      if (sortType === 1) {
        tmp2.reverse();
      }
      setFiltered([...tmp2]);
    }
  }, [sortCol, sortType]);

  const handle_sort = (col) => {
    setSortCol(col);
    if (sortType === 1) {
      setSortType(0);
    } else if (sortType === 0) {
      setSortType(1);
      // setSortCol("")
    }
  };

  useEffect(() => {
    if (sessionStorage.getItem('zocket_token')) {
      const token = sessionStorage.getItem('zocket_token');
      const loginData = JSON.parse(sessionStorage?.getItem('zocket_login'));
      set_reload_login({ token, ...loginData });
    } else {
      navigate('/');
    }
  }, [sessionStorage.getItem('zocket_token')]);

  const get_add_copy = (media) =>
    media?.map((item, i) => {
      return (
        <AddCopy key={i}>
          {item.adImage && <img src={item.adImage} />}
          {item.adVideo && (
            <video width="144" height="96" controls>
              <source src={item.adVideo} type="video/mp4" />
            </video>
          )}
          <p>{item.adCaption}</p>
        </AddCopy>
      );
    });

  const add_to_selected_campaign_list = (row) => {
    console.log(row, 'ROW');
    setSelectedCampaign([...selectedCampaign, row]);
  };

  const remove_from_selected_campaign_list = (id) => {
    var new_list = selectedCampaign.filter((item) => {
      if (item.campaign_id === id) {
        return false;
      } else {
        return true;
      }
    });
    setSelectedCampaign(new_list);
  };

  const handle_allocate_consultant = () => {
    var allocations = [];
    selectedCampaign.map((item) => {
      allocations.push({
        campaign_id: String(item.campaign_id),
        consultant_id: String(selectedAllotee),
      });
    });

    console.log(allocations, selectedCampaign, 'ALLOCATION DATA');

    allocate_campaign({ allocations: allocations }, fromDate, toDate);
  };

  useEffect(() => {
    if (campaign.allocate_campaign_status === 'success') {
      setAllocateCampaignModal(false);
      setSelectedCampaign([]);
      setSelectedAllotee('');
    }
  }, [campaign]);

  const pdfPage = useRef();

  const save_pdf = () => {
    const doc = new jsPDF({
      orientation: 'portrait',
      format: 'a4',
      unit: 'px',
    });

    // Adding the fonts
    doc.setFont('Inter-Regular', 'normal');

    doc.html(pdfPage.current, {
      async callback(doc) {
        await doc.save(`campaign-report`);
      },
      margin: 10,
      autoPaging: 'text',
      html2canvas: {
        scale: 0.39,
      },
    });
  };

  const show_payment_history = (e, campaign) => {
    setPaymentHistoryAnchor(e.currentTarget);
    get_payment_history(campaign.business_id);
  };

  useEffect(() => {
    console.log(business, 'PAYMENT HISTORY');
  }, [business]);

  useEffect(() => {
    if (allCampaign) {
      if (filtered.length === 0 &&
        businessSearch === '' &&
        campaignStatus === 'ALL_STATUS' && sales === 'ALL_SALES') {
          setCsv(allCampaign);
      }
      else if (filtered.length !== 0) {
        setCsv(filtered);
      } else if(filtered.length === 0 &&
        businessSearch === '' &&
        campaignStatus === 'ALL_STATUS' && sales !== 'ALL_SALES'){
          setCsv(filtered);
        }
    }
  }, [filtered, businessSearch, campaignStatus, sales, allCampaign]);

  const headers = [
    { label: "Campaign ID", key: "campaignId" },
    { label: "Business ID", key: "businessId" },
    { label: "Business Name & Category", key: "businessNameCategory" },
    { label: "Type of Purchase", key: "typeOfPurchase" },
    { label: "Leads bought", key: "leadsBought" },
    { label: "Budget", key: "budget" },
    { label: "Leads Received", key: "leadsReceived" },
    { label: "Lead balance", key: "leadBalance" },
    { label: "City", key: "city" },
    { label: "Phone", key: "phone" },
    { label: "Campaign Status", key: "campaignStatus" },
    { label: "Type of Sales", key: "typeOfSales" },
    { label: "Created At", key: "createdAt" },
  ];

  useEffect(() => {
    let data = [];
    if (csv) {
      for (let i = 0; i < csv.length; i++) {
        let status;
        if (csv[i].status === "LIVE_CAMPAIGNS") {
          status = 'Live Now'
        } else if (csv[i].status === "STOPPED_CAMPAIGNS") {
          status = 'Paused'
        } else if (csv[i].status === "COMPLETED_CAMPAIGNS") {
          status = 'Completed'
        } else if (csv[i].status === "AWAITING_APPROVAL") {
          status = 'Pending'
        }
        data.push({ campaignId: csv[i].campaign_id, businessId: csv[i].business_id, businessNameCategory: (csv[i].business_name + " & " + csv[i].business_category), typeOfPurchase: csv[i].campaign_objective.replaceAll('_', ' '), leadsBought: csv[i].promised_leads, budget: ("Rs. " + csv[i].budget), leadsReceived: csv[i].results, leadBalance: (Number(csv[i].promised_leads) - Number(csv[i].results)), city: (csv[i].city ? csv[i].city : '-'), phone: (csv[i].mobile ? `=""${csv[i].mobile}""` : '-'), campaignStatus: status, typeOfSales:csv[i].SalesType, createdAt: moment(csv[i].created_at).format('ll') })
      }
      setCsvData(data);
    }
  }, [csv])

  const todayDate = new Date().toDateString();

  return (
    <Wrapper>
      <TitleFlex>
        <h3>Lead CAMPAIGNS</h3>
        <div style={{ display: "flex", gap: "1rem" }}>
          <Button
            onClick={() => setPdfModal(true)}
            variant="contained"
            color="info"
          >
            Generate PDF
          </Button>
          {csvData && <Button
            variant="contained"
            color="success"
          ><CSVLink data={csvData} headers={headers} filename={`LeadReport - ${todayDate}.csv`} style={{ color: "White", textDecoration: "none" }}>
              Generate CSV
            </CSVLink></Button>}
        </div>

      </TitleFlex>
      <StatsFlex></StatsFlex>
      {selectedCampaign.length !== 0 && SUPER_ADMIN.includes(login.email) && (
        <AllocateCampaign>
          <p>{selectedCampaign.length} campagins selected</p>
          <Button
            onClick={(e) => setAllocateCampaignModal(true)}
            variant="contained"
          >
            Allocate these campaign
          </Button>
        </AllocateCampaign>
      )}

      <CustomCard width="100%">
        <Filters>
          <Flex>
            <FilterItem>
              <searchBox>
                <TextField
                  sx={{ width: '20rem' }}
                  size="small"
                  placeholder="Enter Business Name"
                  value={businessSearch}
                  onChange={(e) => setBusinessSearch(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon sx={{ fontSize: '1.3rem' }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </searchBox>
            </FilterItem>
          </Flex>
          <Flex>
            <FilterItem>
              <label>Type Of Sales:</label>
              <Select
                size="small"
                style={{ width: '10rem' }}
                value={sales}
                onChange={(e) => setSales(e.target.value)}
              >
                <MenuItem value="ALL_SALES">ALL Sales</MenuItem>
                <MenuItem value="InsideSales">Inside Sales</MenuItem>
                <MenuItem value="FieldSales">Field Sales</MenuItem>
              </Select>
            </FilterItem>
            <FilterItem>
              <label>Campaign Status:</label>
              <Select
                size="small"
                style={{ width: '10rem' }}
                value={campaignStatus}
                onChange={(e) => setCampaignStatus(e.target.value)}
              >
                <MenuItem value="ALL_STATUS">All Status</MenuItem>
                <MenuItem value="LIVE_CAMPAIGNS">Live Now</MenuItem>
                <MenuItem value="STOPPED_CAMPAIGNS">Paused</MenuItem>
                <MenuItem value="COMPLETED_CAMPAIGNS">Completed</MenuItem>
                <MenuItem value="AWAITING_APPROVAL">Pending</MenuItem>
              </Select>
            </FilterItem>
            <FilterItem>
              <label>From:</label>
              <TextField
                size="small"
                style={{ width: '10rem' }}
                type="date"
                value={fromDate}
                onChange={(e) => setFromDate(e.target.value)}
              />
            </FilterItem>
            <FilterItem>
              <label>To:</label>
              <TextField
                size="small"
                style={{ width: '10rem' }}
                type="date"
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
              />
            </FilterItem>
          </Flex>
        </Filters>
        {/* <TotalRows>
          <RowCountChip>
            {' '}
            {filtered.length === 0
              ? !campaign.campaign_loader &&
                (businessSearch !== '' || campaignStatus !== 'ALL_STATUS') &&
                filtered.length === 0
                ? '0'
                : campaign.all_campaigns.length
              : filtered.length}
          </RowCountChip>
          <p>campaigns</p>
        </TotalRows> */}
        <TableContainer>
          <Table sx={{ minWidth: 650, width: '100%' }}>
            <TableHead
              sx={{
                backgroundColor: '#F8F8F8',
                color: 'rgba(0, 0, 0, 0.5)',
                padding: '0',
                border: '1.5px solid #EAEAEA',
              }}
            >
              <TableRow
                sx={{
                  fontSize: '0.8rem',
                  padding: '0',
                }}
              >
                {SUPER_ADMIN.includes(login.email) && (
                  <TableCell
                    sx={{
                      color: 'rgba(0, 0, 0, 0.5)',
                      padding: '0.5rem',
                      border: '2px solid #EAEAEA',
                    }}
                    align="center"
                  />
                )}

                <TableCell
                  sx={{
                    color: 'rgba(0, 0, 0, 0.5)',
                    padding: '0.5rem',
                    border: '2px solid #EAEAEA',
                  }}
                  align="center"
                >
                  <p>Campaign ID</p>
                </TableCell>
                <TableCell
                  sx={{
                    color: 'rgba(0, 0, 0, 0.5)',
                    padding: '0.5rem',
                    border: '2px solid #EAEAEA',
                  }}
                  align="center"
                >
                  <p>Business ID</p>
                </TableCell>
                <TableCell
                  sx={{
                    color: 'rgba(0, 0, 0, 0.5)',
                    width: '12rem',
                    padding: '0.5rem',
                    border: '2px solid #EAEAEA',
                  }}
                  align="center"
                >
                  <p>Business Name & Category</p>
                </TableCell>

                <TableCell
                  sx={{
                    color: 'rgba(0, 0, 0, 0.5)',
                    padding: '0.5rem',
                    border: '2px solid #EAEAEA',
                  }}
                  align="center"
                >
                  <p>Type of Purchase</p>
                </TableCell>
                <TableCell
                  sx={{
                    color:
                      sortCol === 'leadsBought'
                        ? '#5D4037'
                        : 'rgba(0, 0, 0, 0.5)',
                    padding: '0.5rem',
                    cursor: 'pointer',
                    border: '2px solid #EAEAEA',
                  }}
                  align="center"
                  onClick={() => handle_sort('leadsBought')}
                >
                  <TableHeadCell>
                    <p>Leads bought</p>
                    <SortIcon>
                      {sortCol === 'leadsBought' && sortType === 1 && (
                        <NorthIcon
                          style={{
                            fontSize: '1.4rem',
                            marginLeft: '0.5rem',
                            color:
                              sortCol === 'leadsBought' ? '#5D4037' : '#9E9E9E',
                          }}
                        />
                      )}{' '}
                      {sortCol === 'leadsBought' && sortType === 0 && (
                        <SouthIcon
                          style={{
                            fontSize: '1.4rem',
                            marginLeft: '0.5rem',
                            color:
                              sortCol === 'leadsBought' ? '#5D4037' : '#9E9E9E',
                          }}
                        />
                      )}
                      {/* {sortCol !== 'leadsBought' && (
                        <ImportExportIcon
                          style={{
                            fontSize: '1.4rem',
                            marginLeft: '0.5rem',
                            color:
                              sortCol === 'leadsBought' ? '#5D4037' : '#9E9E9E',
                          }}
                        />
                      )} */}
                    </SortIcon>
                  </TableHeadCell>
                </TableCell>
                <TableCell
                  sx={{
                    color:
                      sortCol === 'budget' ? '#5D4037' : 'rgba(0, 0, 0, 0.5)',
                    padding: '0.5rem',
                    cursor: 'pointer',
                    border: '2px solid #EAEAEA',
                  }}
                  align="center"
                  onClick={() => handle_sort('budget')}
                >
                  <TableHeadCell>
                    <p>Budget</p>
                    <SortIcon>
                      {sortCol === 'budget' && sortType === 1 && (
                        <NorthIcon
                          style={{
                            fontSize: '1.4rem',
                            marginLeft: '0.5rem',
                            color: sortCol === 'budget' ? '#5D4037' : '#9E9E9E',
                          }}
                        />
                      )}{' '}
                      {sortCol === 'budget' && sortType === 0 && (
                        <SouthIcon
                          style={{
                            fontSize: '1.4rem',
                            marginLeft: '0.5rem',
                            color: sortCol === 'budget' ? '#5D4037' : '#9E9E9E',
                          }}
                        />
                      )}
                      {/* {sortCol !== 'budget' && (
                        <ImportExportIcon
                          style={{
                            fontSize: '1.4rem',
                            marginLeft: '0.5rem',
                            color: sortCol === 'budget' ? '#5D4037' : '#9E9E9E',
                          }}
                        />
                      )} */}
                    </SortIcon>
                  </TableHeadCell>
                </TableCell>
                <TableCell
                  sx={{
                    color:
                      sortCol === 'leadsReceived'
                        ? '#5D4037'
                        : 'rgba(0, 0, 0, 0.5)',
                    padding: '0.5rem',
                    cursor: 'pointer',
                    border: '2px solid #EAEAEA',
                  }}
                  align="center"
                  onClick={() => handle_sort('leadsReceived')}
                >
                  <TableHeadCell>
                    <p>Leads Received</p>
                    <SortIcon>
                      {sortCol === 'leadsReceived' && sortType === 1 && (
                        <NorthIcon
                          style={{
                            fontSize: '1.4rem',
                            marginLeft: '0.5rem',
                            color:
                              sortCol === 'leadsReceived'
                                ? '#5D4037'
                                : '#9E9E9E',
                          }}
                        />
                      )}{' '}
                      {sortCol === 'leadsReceived' && sortType === 0 && (
                        <SouthIcon
                          style={{
                            fontSize: '1.4rem',
                            marginLeft: '0.5rem',
                            color:
                              sortCol === 'leadsReceived'
                                ? '#5D4037'
                                : '#9E9E9E',
                          }}
                        />
                      )}
                      {/* {sortCol !== 'leadsReceived' && (
                        <ImportExportIcon
                          style={{
                            fontSize: '1.4rem',
                            marginLeft: '0.5rem',
                            color:
                              sortCol === 'leadsReceived' ? '#5D4037' : '#9E9E9E',
                          }}
                        />
                      )} */}
                    </SortIcon>
                  </TableHeadCell>
                </TableCell>
                <TableCell
                  sx={{
                    color:
                      sortCol === 'leadBalance'
                        ? '#5D4037'
                        : 'rgba(0, 0, 0, 0.5)',
                    padding: '0.5rem',
                    cursor: 'pointer',
                    border: '2px solid #EAEAEA',
                  }}
                  align="center"
                  onClick={() => handle_sort('leadBalance')}
                >
                  <TableHeadCell>
                    <p>Lead balance</p>
                    <SortIcon>
                      {sortCol === 'leadBalance' && sortType === 1 && (
                        <NorthIcon
                          style={{
                            fontSize: '1.4rem',
                            marginLeft: '0.5rem',
                            color:
                              sortCol === 'leadBalance' ? '#5D4037' : '#9E9E9E',
                          }}
                        />
                      )}{' '}
                      {sortCol === 'leadBalance' && sortType === 0 && (
                        <SouthIcon
                          style={{
                            fontSize: '1.4rem',
                            marginLeft: '0.5rem',
                            color:
                              sortCol === 'leadBalance' ? '#5D4037' : '#9E9E9E',
                          }}
                        />
                      )}
                      {/* {sortCol !== 'leadBalance' && (
                        <ImportExportIcon
                          style={{
                            fontSize: '1.4rem',
                            marginLeft: '0.5rem',
                            color:
                              sortCol === 'leadBalance' ? '#5D4037' : '#9E9E9E',
                          }}
                        />
                      )} */}
                    </SortIcon>
                  </TableHeadCell>
                </TableCell>
                <TableCell
                  sx={{
                    color:
                      sortCol === 'city' ? '#5D4037' : 'rgba(0, 0, 0, 0.5)',
                    padding: '0.5rem',
                    cursor: 'pointer',
                    border: '2px solid #EAEAEA',
                  }}
                  align="center"
                  onClick={() => handle_sort('city')}
                >
                  <TableHeadCell>
                    <p>City</p>
                    <SortIcon>
                      {sortCol === 'city' && sortType === 1 && (
                        <NorthIcon
                          style={{
                            fontSize: '1.4rem',
                            marginLeft: '0.5rem',
                            color: sortCol === 'city' ? '#5D4037' : '#9E9E9E',
                          }}
                        />
                      )}{' '}
                      {sortCol === 'city' && sortType === 0 && (
                        <SouthIcon
                          style={{
                            fontSize: '1.4rem',
                            marginLeft: '0.5rem',
                            color: sortCol === 'city' ? '#5D4037' : '#9E9E9E',
                          }}
                        />
                      )}
                      {/* {sortCol !== 'leadsReceived' && (
                        <ImportExportIcon
                          style={{
                            fontSize: '1.4rem',
                            marginLeft: '0.5rem',
                            color:
                              sortCol === 'leadsReceived' ? '#5D4037' : '#9E9E9E',
                          }}
                        />
                      )} */}
                    </SortIcon>
                  </TableHeadCell>
                </TableCell>
                <TableCell
                  sx={{
                    color:
                      sortCol === 'phone' ? '#5D4037' : 'rgba(0, 0, 0, 0.5)',
                    padding: '0.5rem',
                    cursor: 'pointer',
                    border: '2px solid #EAEAEA',
                  }}
                  align="center"
                  onClick={() => handle_sort('phone')}
                >
                  <TableHeadCell>
                    <p>Phone</p>
                    <SortIcon>
                      {sortCol === 'phone' && sortType === 1 && (
                        <NorthIcon
                          style={{
                            fontSize: '1.4rem',
                            marginLeft: '0.5rem',
                            color: sortCol === 'phone' ? '#5D4037' : '#9E9E9E',
                          }}
                        />
                      )}{' '}
                      {sortCol === 'phone' && sortType === 0 && (
                        <SouthIcon
                          style={{
                            fontSize: '1.4rem',
                            marginLeft: '0.5rem',
                            color: sortCol === 'phone' ? '#5D4037' : '#9E9E9E',
                          }}
                        />
                      )}
                      {/* {sortCol !== 'leadsReceived' && (
                        <ImportExportIcon
                          style={{
                            fontSize: '1.4rem',
                            marginLeft: '0.5rem',
                            color:
                              sortCol === 'leadsReceived' ? '#5D4037' : '#9E9E9E',
                          }}
                        />
                      )} */}
                    </SortIcon>
                  </TableHeadCell>
                </TableCell>
                <TableCell
                  sx={{
                    color: 'rgba(0, 0, 0, 0.5)',
                    padding: '0.5rem',
                    border: '2px solid #EAEAEA',
                  }}
                  align="center"
                >
                  <p>Campaign Status</p>
                </TableCell>
                <TableCell
                  sx={{
                    color: 'rgba(0, 0, 0, 0.5)',
                    padding: '0.5rem',
                    border: '2px solid #EAEAEA',
                  }}
                  align="center"
                >
                  <p>Type of Sales</p>
                </TableCell>
                <TableCell
                  sx={{
                    color: 'rgba(0, 0, 0, 0.5)',
                    padding: '0.5rem',
                    border: '2px solid #EAEAEA',
                  }}
                  align="center"
                >
                  <p>Payment history</p>
                </TableCell>

                <TableCell
                  sx={{
                    color:
                      sortCol === 'createdAt'
                        ? '#5D4037'
                        : 'rgba(0, 0, 0, 0.5)',
                    padding: '0.5rem',
                    cursor: 'pointer',
                    border: '2px solid #EAEAEA',
                  }}
                  onClick={() => handle_sort('createdAt')}
                  align="center"
                >
                  <TableHeadCell>
                    <p>Created At</p>
                    <SortIcon>
                      {sortCol === 'createdAt' && sortType === 1 && (
                        <NorthIcon
                          style={{
                            fontSize: '1.4rem',
                            marginLeft: '0.5rem',
                            color:
                              sortCol === 'createdAt' ? '#5D4037' : '#9E9E9E',
                          }}
                        />
                      )}{' '}
                      {sortCol === 'createdAt' && sortType === 0 && (
                        <SouthIcon
                          style={{
                            fontSize: '1.4rem',
                            marginLeft: '0.5rem',
                            color:
                              sortCol === 'createdAt' ? '#5D4037' : '#9E9E9E',
                          }}
                        />
                      )}
                      {/* {sortCol !== 'createdAt' && (
                        <ImportExportIcon
                          style={{
                            fontSize: '1.4rem',
                            marginLeft: '0.5rem',
                            color: sortCol === 'createdAt' ? '#5D4037' : '#9E9E9E',
                          }}
                        />
                      )} */}
                    </SortIcon>
                  </TableHeadCell>
                </TableCell>
              </TableRow>
            </TableHead>
            {campaign.all_campaigns.length === 0 &&
              campaign.campaign_loader === false && (
                <TableBody>
                  <TableRow>
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />

                    <TableCell colSpan={3}>
                      <EmptyCampaign>
                        <p>No campaigns to show!!</p>
                        <img src={NoDataImg} />
                      </EmptyCampaign>
                    </TableCell>

                    <TableCell />
                    <TableCell />
                    <TableCell />
                  </TableRow>
                  {/* <EmptyCampaign>
                    <p>No campaigns to show!!</p>
                    <img src={NoDataImg} />
                  </EmptyCampaign> */}
                </TableBody>
              )}
            {campaign.campaign_loader && (
              <TableBody>
                {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((row, i) => {
                  return (
                    <>
                      <TableRow
                        key={i}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          <Skeleton
                            variant="rectangular"
                            width="100%"
                            height={10}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <Skeleton
                            variant="rounded"
                            width="100%"
                            height={15}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <Skeleton
                            variant="rounded"
                            width="100%"
                            height={15}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <Skeleton
                            variant="rounded"
                            width="100%"
                            height={15}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <Skeleton
                            variant="rounded"
                            width="100%"
                            height={15}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <Skeleton
                            variant="rounded"
                            width="100%"
                            height={15}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <Skeleton
                            variant="rounded"
                            width="100%"
                            height={15}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <Skeleton
                            variant="rounded"
                            width="100%"
                            height={15}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <Skeleton
                            variant="rounded"
                            width="100%"
                            height={15}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <Skeleton
                            variant="rounded"
                            width="100%"
                            height={15}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <Skeleton
                            variant="rounded"
                            width="100%"
                            height={15}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <Skeleton
                            variant="rounded"
                            width="100%"
                            height={15}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <Skeleton
                            variant="rounded"
                            width="100%"
                            height={15}
                          />
                        </TableCell>

                        <TableCell align="center">
                          <Skeleton
                            variant="rounded"
                            width="100%"
                            height={15}
                          />
                        </TableCell>

                        {SUPER_ADMIN.includes(login.email) && (
                          <TableCell align="center">
                            <Skeleton
                              variant="rounded"
                              width="100%"
                              height={15}
                            />
                          </TableCell>
                        )}
                      </TableRow>
                    </>
                  );
                })}
              </TableBody>
            )}
            {!campaign.campaign_loader &&
              (businessSearch !== '' || campaignStatus !== 'ALL_STATUS' || sales !== 'ALL_SALES') &&
              filtered.length === 0 && (
                <TableBody>
                  <TableRow>
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />

                    <TableCell colSpan={3}>
                      <EmptyCampaign>
                        <p>No campaigns to show!!</p>
                        <img src={NoDataImg} />
                      </EmptyCampaign>
                    </TableCell>

                    <TableCell />
                    <TableCell />
                    <TableCell />
                  </TableRow>
                </TableBody>
              )}
            {!campaign.campaign_loader && (
              <TableBody>
                {filtered.length !== 0 &&
                  filtered
                    ?.slice((page - 1) * 10, (page - 1) * 10 + 10)
                    ?.map((row, i) => {
                      let statusErrorMessage;
                      if (row) {
                        if (row.status === 'AWAITING_APPROVAL') {
                          if (row.is_fb_linked === false) {
                            statusErrorMessage = "Facebook page is not linked";
                          } else if (row.is_user_restricted === true) {
                            statusErrorMessage = "User restricted from advertising";
                          } else if (row.is_page_restricted === true) {
                            statusErrorMessage = "Page restricted from advertising";
                          } else if (row.is_package_added === false) {
                            statusErrorMessage = "Package is not added";
                          } else if (row.is_token_valid === false) {
                            statusErrorMessage = "Token is invalid";
                          }
                        }
                      }
                      return (
                        <>
                          <TableRow key={row.id}>
                            {SUPER_ADMIN.includes(login.email) && (
                              <TableCell
                                align="center"
                                sx={{
                                  border: '1px solid #EAEAEA',
                                  padding: '0.5rem',
                                }}
                              >
                                <Checkbox
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      add_to_selected_campaign_list(row);
                                    } else {
                                      remove_from_selected_campaign_list(
                                        row.campaign_id
                                      );
                                    }
                                  }}
                                  sx={{ fontSize: '150%' }}
                                />
                              </TableCell>
                            )}
                            <TableCell
                              align="center"
                              sx={{
                                border: '1px solid #EAEAEA',
                                padding: '0.5rem',
                              }}
                            >
                              {row.campaign_id}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                border: '1px solid #EAEAEA',
                                padding: '0.5rem',
                              }}
                            >
                              {row.business_id}
                            </TableCell>
                            <TableCell
                              sx={{
                                width: '12rem',
                                border: '1px solid #eaeaea',
                                padding: '0.5rem',
                              }}
                              component="th"
                              scope="row"
                            >
                              <BusinessDetail>
                                <p>{row.business_name}</p>
                                <span>{row.business_category}</span>
                              </BusinessDetail>
                            </TableCell>
                            <TableCell
                              sx={{
                                width: '9rem',
                                border: '1px solid #eaeaea',
                                padding: '0.5rem',
                              }}
                              align="center"
                            >
                              {row.campaign_objective.replaceAll('_', ' ')}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                border: '1px solid #EAEAEA',
                                padding: '0.5rem',
                              }}
                            >
                              {row.promised_leads}
                            </TableCell>
                            <TableCell
                              sx={{
                                width: '6rem',
                                border: '1px solid #EAEAEA',
                                padding: '0.5rem',
                              }}
                              align="center"
                            >
                              &#8377; {`${row.budget}`}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                border: '1px solid #EAEAEA',
                                padding: '0.5rem',
                              }}
                            >
                              {row.results}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                border: '1px solid #EAEAEA',
                                padding: '0.5rem',
                              }}
                            >
                              {row.promised_leads - row.results}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                border: '1px solid #EAEAEA',
                                padding: '0.5rem',
                              }}
                            >
                              {row?.city ? row?.city : '-'}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                border: '1px solid #EAEAEA',
                                padding: '0.5rem',
                              }}
                            >
                              {row?.mobile ? row?.mobile : '-'}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                border: '1px solid #EAEAEA',
                                padding: '0.5rem',
                              }}
                            >
                              <StatusChip status={row.status}>
                                {row.status === 'AWAITING_APPROVAL'
                                  ? 'Pending'
                                  : row.status === 'COMPLETED_CAMPAIGNS'
                                    ? 'Completed'
                                    : row.status === 'STOPPED_CAMPAIGNS'
                                      ? 'Paused'
                                      : 'Live Now'}
                              </StatusChip>
                              {statusErrorMessage && <Tooltip title={statusErrorMessage} placement="left" arrow>
                                <Button>{statusErrorMessage && <p style={{ fontSize: "10px" }}>{statusErrorMessage && statusErrorMessage?.substring(0, 12)}...</p>}</Button>
                              </Tooltip>}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                border: '1px solid #EAEAEA',
                                padding: '0.5rem',
                              }}
                            >
                              {row?.SalesType}
                            </TableCell>
                            <TableCell
                              sx={{
                                width: '7rem',
                                border: '1px solid #EAEAEA',
                                padding: '0.5rem',
                              }}
                              align="center"
                            >
                              <IconButton
                                onClick={(e) => show_payment_history(e, row)}
                                size="large"
                              >
                                <PaymentsIcon sx={{ fontSize: '150%' }} />
                              </IconButton>
                            </TableCell>
                            {/* <TableCell align="center">{row.protein}</TableCell> */}
                            <TableCell
                              sx={{
                                width: '7rem',
                                border: '1px solid #EAEAEA',
                                padding: '0.5rem',
                              }}
                              align="center"
                            >
                              {moment(row.created_at).format('ll')}
                            </TableCell>
                            <TableCell
                              sx={{
                                border: '1px solid #EAEAEA',
                                padding: '0.1rem',
                              }}
                            >
                              <IconButton
                                onClick={() =>
                                  expandedRow === null
                                    ? setExpandedRow(i)
                                    : expandedRow === i
                                      ? setExpandedRow(null)
                                      : setExpandedRow(i)
                                }
                              >
                                {expandedRow === i ? (
                                  <KeyboardArrowUpIcon
                                    sx={{
                                      fontSize: '1rem',
                                      border: '1px solid #1977F3',
                                      borderRadius: '50%',
                                    }}
                                  />
                                ) : (
                                  <KeyboardArrowDownIcon
                                    sx={{
                                      fontSize: '1rem',
                                      border: '1px solid #1977F3',
                                      borderRadius: '50%',
                                    }}
                                  />
                                )}
                              </IconButton>
                            </TableCell>
                          </TableRow>
                          {expandedRow === i && (
                            <TableRow>
                              <TableCell
                                colSpan={
                                  SUPER_ADMIN.includes(login.email) ? 18 : 17
                                }
                              >
                                <Detail>
                                  <DetailHead>
                                    <DetailHeadLeft>
                                      <p>Ad Copies</p>
                                    </DetailHeadLeft>
                                    <DetailHeadRight
                                      style={{
                                        display:
                                          row.media === null ? 'none' : 'flex',
                                      }}
                                    >
                                      <p>Product</p>
                                      <p>Product Description</p>
                                    </DetailHeadRight>
                                  </DetailHead>
                                  <Desc>
                                    <AddCopyContainer>
                                      {get_add_copy(
                                        JSON.parse(row.campaign_platform_meta)
                                          .adPreview
                                      )}
                                    </AddCopyContainer>
                                    <Product
                                      style={{
                                        display:
                                          row.media === null ? 'none' : 'flex',
                                      }}
                                    >
                                      {JSON.parse(row.media) !== null && (
                                        <img
                                          src={JSON.parse(row.media)[0]?.url}
                                        />
                                      )}
                                      <ProductDetail
                                        style={{
                                          display:
                                            row.media === null ? 'none' : 'block',
                                        }}
                                      >
                                        <h4>{row.product_name}</h4>
                                        <p>Price : {row.selling_price}</p>
                                      </ProductDetail>
                                      <ProductDescription>
                                        {row.promotion_tags !== null &&
                                          JSON.parse(row?.promotion_tags)
                                            .length !== 0 &&
                                          JSON.parse(row?.promotion_tags)?.map(
                                            (item, i) => {
                                              return (
                                                <CustomChip key={i}>
                                                  {item}
                                                </CustomChip>
                                              );
                                            }
                                          )}
                                        {row.promotion_tags !== null &&
                                          JSON.parse(row?.promotion_tags)
                                            .length === 0 && (
                                            <CustomChip>
                                              {row.product_description
                                                ? row.product_description
                                                : 'No Description'}
                                            </CustomChip>
                                          )}
                                      </ProductDescription>
                                    </Product>
                                  </Desc>
                                </Detail>
                              </TableCell>
                            </TableRow>
                          )}
                        </>
                      )
                    })}
              </TableBody>
            )}
            {!campaign.campaign_loader && (
              <TableBody>
                {filtered.length === 0 &&
                  businessSearch === '' &&
                  campaignStatus === 'ALL_STATUS' &&
                  sales === 'ALL_SALES' &&
                  allCampaign
                    .slice((page - 1) * 10, (page - 1) * 10 + 10)
                    ?.map((row, i) => {
                      let statusErrorMessage;
                      if (row) {
                        if (row.status === 'AWAITING_APPROVAL') {
                          if (row.is_fb_linked === false) {
                            statusErrorMessage = "Facebook page is not linked";
                          } else if (row.is_user_restricted === true) {
                            statusErrorMessage = "User restricted from advertising";
                          } else if (row.is_page_restricted === true) {
                            statusErrorMessage = "Page restricted from advertising";
                          } else if (row.is_package_added === false) {
                            statusErrorMessage = "Package is not added";
                          } else if (row.is_token_valid === false) {
                            statusErrorMessage = "Token is invalid";
                          }
                        }
                      }
                      return (
                        <>
                          <TableRow key={row.id}>
                            {SUPER_ADMIN.includes(login.email) && (
                              <TableCell
                                align="center"
                                sx={{
                                  border: '1px solid #EAEAEA',
                                  padding: '0.5rem',
                                }}
                              >
                                <Checkbox
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      add_to_selected_campaign_list(row);
                                    } else {
                                      remove_from_selected_campaign_list(
                                        row.campaign_id
                                      );
                                    }
                                  }}
                                  sx={{ fontSize: '150%' }}
                                />
                              </TableCell>
                            )}
                            <TableCell
                              align="center"
                              sx={{
                                border: '1px solid #EAEAEA',
                                padding: '0.5rem',
                              }}
                            >
                              {row.campaign_id}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                border: '1px solid #EAEAEA',
                                padding: '0.5rem',
                              }}
                            >
                              {row.business_id}
                            </TableCell>

                            <TableCell
                              sx={{
                                width: '12rem',
                                border: '1px solid #EAEAEA',
                                padding: '0.5rem',
                              }}
                              component="th"
                              scope="row"
                            >
                              <BusinessDetail>
                                <p>{row.business_name}</p>
                                <span>{row.business_category}</span>
                              </BusinessDetail>
                            </TableCell>

                            <TableCell
                              sx={{
                                width: '9rem',
                                border: '1px solid #EAEAEA',
                                padding: '0.5rem',
                              }}
                              align="center"
                            >
                              {row.campaign_objective.replaceAll('_', ' ')}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                border: '1px solid #EAEAEA',
                                padding: '0.5rem',
                              }}
                            >
                              {row.promised_leads}
                            </TableCell>
                            <TableCell
                              sx={{
                                width: '6rem',
                                border: '1px solid #EAEAEA',
                                padding: '0.5rem',
                              }}
                              align="center"
                            >
                              &#8377; {`${row.budget}`}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                border: '1px solid #EAEAEA',
                                padding: '0.5rem',
                              }}
                            >
                              {row.results}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                border: '1px solid #EAEAEA',
                                padding: '0.5rem',
                              }}
                            >
                              {row.promised_leads - row.results}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                border: '1px solid #EAEAEA',
                                padding: '0.5rem',
                              }}
                            >
                              {row.city ? row.city : '-'}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                border: '1px solid #EAEAEA',
                                padding: '0.5rem',
                              }}
                            >
                              {row?.mobile ? row?.mobile : '-'}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                border: '1px solid #EAEAEA',
                                padding: '0.5rem',
                              }}
                            >
                              <StatusChip status={row.status}>
                                {row.status === 'AWAITING_APPROVAL'
                                  ? 'Pending'
                                  : row.status === 'COMPLETED_CAMPAIGNS'
                                    ? 'Completed'
                                    : row.status === 'STOPPED_CAMPAIGNS'
                                      ? 'Paused'
                                      : 'Live Now'}
                              </StatusChip>
                              {statusErrorMessage && <Tooltip title={statusErrorMessage} placement="left" arrow>
                                <Button>{statusErrorMessage && <p style={{ fontSize: "10px" }}>{statusErrorMessage && statusErrorMessage?.substring(0, 12)}...</p>}</Button>
                              </Tooltip>}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                border: '1px solid #EAEAEA',
                                padding: '0.5rem',
                              }}
                            >
                              {row?.SalesType}
                            </TableCell>
                            <TableCell
                              sx={{
                                width: '7rem',
                                border: '1px solid #EAEAEA',
                                padding: '0.5rem',
                              }}
                              align="center"
                            >
                              <IconButton
                                onClick={(e) => show_payment_history(e, row)}
                                size="large"
                              >
                                <PaymentsIcon sx={{ fontSize: '150%' }} />
                              </IconButton>
                            </TableCell>
                            <TableCell
                              sx={{
                                width: '7rem',
                                border: '1px solid #EAEAEA',
                                padding: '0.5rem',
                              }}
                              align="center"
                            >
                              {row.created_at
                                ? moment(row.created_at).format('ll')
                                : '-'}
                            </TableCell>
                            <TableCell
                              sx={{
                                border: '1px solid #EAEAEA',
                                padding: '0.1rem',
                              }}
                            >
                              <IconButton
                                onClick={() =>
                                  expandedRow === null
                                    ? setExpandedRow(i)
                                    : expandedRow === i
                                      ? setExpandedRow(null)
                                      : setExpandedRow(i)
                                }
                              >
                                {expandedRow === i ? (
                                  <KeyboardArrowUpIcon
                                    sx={{
                                      fontSize: '1rem',
                                      border: '1px solid #1977F3',
                                      borderRadius: '50%',
                                    }}
                                  />
                                ) : (
                                  <KeyboardArrowDownIcon
                                    sx={{
                                      fontSize: '1rem',
                                      border: '1px solid #1977F3',
                                      borderRadius: '50%',
                                    }}
                                  />
                                )}
                              </IconButton>
                            </TableCell>
                          </TableRow>
                          {expandedRow === i && (
                            <TableRow>
                              <TableCell
                                colSpan={
                                  SUPER_ADMIN.includes(login.email) ? 18 : 17
                                }
                              >
                                <Detail>
                                  <DetailHead>
                                    <DetailHeadLeft>
                                      <p>Ad Copies</p>
                                    </DetailHeadLeft>
                                    <DetailHeadRight
                                      style={{
                                        display:
                                          row.media === null ? 'none' : 'flex',
                                      }}
                                    >
                                      <p>Product</p>
                                      <p>Product Description</p>
                                    </DetailHeadRight>
                                  </DetailHead>
                                  <Desc>
                                    <AddCopyContainer>
                                      {get_add_copy(
                                        JSON.parse(row?.campaign_platform_meta)
                                          .adPreview
                                      )}
                                    </AddCopyContainer>
                                    <Product
                                      style={{
                                        display:
                                          row.media === null ? 'none' : 'flex',
                                      }}
                                    >
                                      {JSON.parse(row.media) !== null && (
                                        <img
                                          src={JSON.parse(row.media)[0]?.url}
                                        />
                                      )}
                                      <ProductDetail
                                        style={{
                                          display:
                                            row.media === null ? 'none' : 'block',
                                        }}
                                      >
                                        <h4>{row?.product_name}</h4>
                                        <p>Price : {row?.selling_price}</p>
                                      </ProductDetail>
                                      <ProductDescription>
                                        {row.promotion_tags !== null &&
                                          JSON.parse(row?.promotion_tags)?.map(
                                            (item, i) => {
                                              return (
                                                <CustomChip key={i}>
                                                  {item}
                                                </CustomChip>
                                              );
                                            }
                                          )}
                                        {row.promotion_tags !== null &&
                                          JSON.parse(row.promotion_tags)
                                            .length === 0 && (
                                            <CustomChip>
                                              {row.product_description
                                                ? row.product_description
                                                : 'No Description'}
                                            </CustomChip>
                                          )}
                                      </ProductDescription>
                                    </Product>
                                  </Desc>
                                </Detail>
                              </TableCell>
                            </TableRow>
                          )}
                        </>
                      )
                    })}
              </TableBody>
            )}
          </Table>
        </TableContainer>

        <Pagination
          sx={{
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: '2rem',
          }}
          count={
            filtered.length === 0
              ? Math.ceil(campaign.all_campaigns.length / 10)
              : Math.ceil(filtered.length / 10)
          }
          page={page}
          onChange={(e, val) => setPage(val)}
          color="secondary"
        />
      </CustomCard>
      <Modal
        open={allocateCampaignModal}
        onClose={() => {
          setAllocateCampaignModal(false);
          set_allocte_campaign_status('');
        }}
      >
        <ModalContent>
          <CampaignModalWrapper>
            <h3>Allocate Campaign</h3>
            <p>Select the person you want to allocate</p>
            <TextField
              size="small"
              sx={{ marginTop: '1rem' }}
              fullWidth
              placeholder="Search for the person"
              value={alloteeSearch}
              onChange={(e) => setAlloteeSearch(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ fontSize: '1.3rem' }} />
                  </InputAdornment>
                ),
              }}
            />
            <AlloteeList>
              {consultants.length &&
                consultants.map((item) => {
                  return (
                    <li>
                      <p>{item?.consultant_name}</p>
                      <Radio
                        checked={item?.consultant_id === selectedAllotee}
                        onChange={(e) =>
                          setSelectedAllotee(item?.consultant_id)
                        }
                        sx={{ fontSize: '150%' }}
                      />
                    </li>
                  );
                })}
            </AlloteeList>
            <Button
              variant="contained"
              fullWidth
              onClick={() => handle_allocate_consultant()}
            >
              {campaign.allocate_campaign_status === 'loading' ? (
                <CircularProgress
                  size={25}
                  sx={{
                    color: '#fff',
                  }}
                />
              ) : (
                'Confirm'
              )}
            </Button>
          </CampaignModalWrapper>
        </ModalContent>
      </Modal>
      <Modal open={productModal} onClose={() => setProductModal(false)}>
        <ModalContent>
          <ProductCell>
            <img src={Cake} />
            <h5>Chocolate Truffle Cake</h5>
            <h4>Rs. 800</h4>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent
              consequat mauris
            </p>
          </ProductCell>
        </ModalContent>
      </Modal>
      <Modal open={pdfModal} onClose={() => setPdfModal(false)}>
        <PdfSnapshot>
          <Button
            variant="contained"
            color="info"
            style={{ alignSelf: 'end', marginBottom: '1rem' }}
            startIcon={<SaveIcon />}
            onClick={() => save_pdf()}
          >
            Print
          </Button>
          <PDF ref={pdfPage}>
            <PDFTitle>
              <img
                // src="https://zocket.s3.ap-south-1.amazonaws.com/zocket_logo/ZocketLogo.svg"
                src={logo}
              />
              <h4>
                Zocket | <p> Campaign Report</p>
              </h4>
            </PDFTitle>
            <PDFFilters>
              <p>
                Status: <span>{campaignStatus}</span>
              </p>
              <p>
                Date range:{' '}
                <span>
                  {`${moment(new Date(fromDate)).format('LL')}`} -
                  {`${moment(toDate).format('LL')}`}
                </span>
              </p>
            </PDFFilters>
            <Table sx={{ minWidth: 650, width: '100%' }}>
              <TableHead
                sx={{
                  backgroundColor: '#F8F8F8',
                  color: 'rgba(0, 0, 0, 0.5)',
                  padding: '0',
                  border: '1.5px solid #EAEAEA',
                }}
              >
                <TableRow
                  sx={{
                    fontSize: '0.8rem',
                    padding: '0',
                  }}
                >
                  {SUPER_ADMIN.includes(login.email) && (
                    <TableCell
                      sx={{
                        color: 'rgba(0, 0, 0, 0.5)',
                        padding: '0.5rem',
                        border: '2px solid #EAEAEA',
                      }}
                      align="center"
                    />
                  )}

                  <TableCell
                    sx={{
                      color: 'rgba(0, 0, 0, 0.5)',
                      padding: '0.5rem',
                      border: '2px solid #EAEAEA',
                    }}
                    align="center"
                  >
                    <p>Campaign ID</p>
                  </TableCell>
                  <TableCell
                    sx={{
                      color: 'rgba(0, 0, 0, 0.5)',
                      padding: '0.5rem',
                      border: '2px solid #EAEAEA',
                    }}
                    align="center"
                  >
                    <p>Business ID</p>
                  </TableCell>
                  <TableCell
                    sx={{
                      color: 'rgba(0, 0, 0, 0.5)',
                      width: '12rem',
                      padding: '0.5rem',
                      border: '2px solid #EAEAEA',
                    }}
                    align="center"
                  >
                    <p>Business Name & Category</p>
                  </TableCell>

                  <TableCell
                    sx={{
                      color: 'rgba(0, 0, 0, 0.5)',
                      padding: '0.5rem',
                      border: '2px solid #EAEAEA',
                    }}
                    align="center"
                  >
                    <p>Type of Purchase</p>
                  </TableCell>
                  <TableCell
                    sx={{
                      color:
                        sortCol === 'leadsBought'
                          ? '#5D4037'
                          : 'rgba(0, 0, 0, 0.5)',
                      padding: '0.5rem',
                      cursor: 'pointer',
                      border: '2px solid #EAEAEA',
                    }}
                    align="center"
                  >
                    <TableHeadCell>
                      <p>Leads bought</p>
                    </TableHeadCell>
                  </TableCell>
                  <TableCell
                    sx={{
                      color:
                        sortCol === 'budget' ? '#5D4037' : 'rgba(0, 0, 0, 0.5)',
                      padding: '0.5rem',
                      cursor: 'pointer',
                      border: '2px solid #EAEAEA',
                    }}
                    align="center"
                  >
                    <TableHeadCell>
                      <p>Budget</p>
                    </TableHeadCell>
                  </TableCell>
                  <TableCell
                    sx={{
                      color:
                        sortCol === 'leadsReceived'
                          ? '#5D4037'
                          : 'rgba(0, 0, 0, 0.5)',
                      padding: '0.5rem',
                      cursor: 'pointer',
                      border: '2px solid #EAEAEA',
                    }}
                    align="center"
                  >
                    <TableHeadCell>
                      <p>Leads Received</p>
                    </TableHeadCell>
                  </TableCell>
                  <TableCell
                    sx={{
                      color:
                        sortCol === 'leadBalance'
                          ? '#5D4037'
                          : 'rgba(0, 0, 0, 0.5)',
                      padding: '0.5rem',
                      cursor: 'pointer',
                      border: '2px solid #EAEAEA',
                    }}
                    align="center"
                  >
                    <TableHeadCell>
                      <p>Lead balance</p>
                    </TableHeadCell>
                  </TableCell>
                  <TableCell
                    sx={{
                      color:
                        sortCol === 'city' ? '#5D4037' : 'rgba(0, 0, 0, 0.5)',
                      padding: '0.5rem',
                      cursor: 'pointer',
                      border: '2px solid #EAEAEA',
                    }}
                    align="center"
                  >
                    <TableHeadCell>
                      <p>City</p>
                    </TableHeadCell>
                  </TableCell>
                  <TableCell
                    sx={{
                      color:
                        sortCol === 'phone' ? '#5D4037' : 'rgba(0, 0, 0, 0.5)',
                      padding: '0.5rem',
                      cursor: 'pointer',
                      border: '2px solid #EAEAEA',
                    }}
                    align="center"
                  >
                    <TableHeadCell>
                      <p>Phone</p>
                    </TableHeadCell>
                  </TableCell>
                  <TableCell
                    sx={{
                      color: 'rgba(0, 0, 0, 0.5)',
                      padding: '0.5rem',
                      border: '2px solid #EAEAEA',
                    }}
                    align="center"
                  >
                    <p>Campaign Status</p>
                  </TableCell>
                  <TableCell
                    sx={{
                      color: 'rgba(0, 0, 0, 0.5)',
                      padding: '0.5rem',
                      border: '2px solid #EAEAEA',
                    }}
                    align="center"
                  >
                    <p>Type of Sales</p>
                  </TableCell>
                  <TableCell
                    sx={{
                      color:
                        sortCol === 'createdAt'
                          ? '#5D4037'
                          : 'rgba(0, 0, 0, 0.5)',
                      padding: '0.5rem',
                      cursor: 'pointer',
                      border: '2px solid #EAEAEA',
                    }}
                    align="center"
                  >
                    <TableHeadCell>
                      <p>Created At</p>
                      <SortIcon></SortIcon>
                    </TableHeadCell>
                  </TableCell>
                </TableRow>
              </TableHead>
              {!campaign.campaign_loader && (
                <TableBody>
                  {filtered.length !== 0 &&
                    filtered?.map((row, i) => {

                      return (
                        <>
                          <TableRow key={row.id}>
                            {SUPER_ADMIN.includes(login.email) && (
                              <TableCell
                                align="center"
                                sx={{
                                  border: '1px solid #EAEAEA',
                                  padding: '0.5rem',
                                }}
                              >
                                <Checkbox
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      add_to_selected_campaign_list(row);
                                    } else {
                                      remove_from_selected_campaign_list(
                                        row.campaign_id
                                      );
                                    }
                                  }}
                                  sx={{ fontSize: '150%' }}
                                />
                              </TableCell>
                            )}
                            <TableCell
                              align="center"
                              sx={{
                                border: '1px solid #EAEAEA',
                                padding: '0.5rem',
                              }}
                            >
                              {row.campaign_id}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                border: '1px solid #EAEAEA',
                                padding: '0.5rem',
                              }}
                            >
                              {row.business_id}
                            </TableCell>
                            <TableCell
                              sx={{
                                width: '12rem',
                                border: '1px solid #eaeaea',
                                padding: '0.5rem',
                              }}
                              component="th"
                              scope="row"
                            >
                              <BusinessDetail>
                                <p>{row.business_name}</p>
                                <p
                                  style={{
                                    marginTop: '0.2rem',
                                    lineHeight: '0.9rem',
                                    fontSize: '0.7rem',
                                    color: 'grey',
                                  }}
                                >
                                  {row.business_category}
                                </p>
                              </BusinessDetail>
                            </TableCell>
                            <TableCell
                              sx={{
                                width: '9rem',
                                border: '1px solid #eaeaea',
                                padding: '0.5rem',
                              }}
                              align="center"
                            >
                              {row.campaign_objective.replaceAll('_', ' ')}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                border: '1px solid #EAEAEA',
                                padding: '0.5rem',
                              }}
                            >
                              {row.promised_leads}
                            </TableCell>
                            <TableCell
                              sx={{
                                width: '6rem',
                                border: '1px solid #EAEAEA',
                                padding: '0.5rem',
                              }}
                              align="center"
                            >
                              Rs. {`${row.budget}`}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                border: '1px solid #EAEAEA',
                                padding: '0.5rem',
                              }}
                            >
                              {row.results}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                border: '1px solid #EAEAEA',
                                padding: '0.5rem',
                              }}
                            >
                              {row.promised_leads - row.results}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                border: '1px solid #EAEAEA',
                                padding: '0.5rem',
                              }}
                            >
                              {row?.city ? row?.city : '-'}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                border: '1px solid #EAEAEA',
                                padding: '0.5rem',
                              }}
                            >
                              {row?.mobile ? row?.mobile : '-'}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                border: '1px solid #EAEAEA',
                                padding: '0.5rem',
                              }}
                            >
                              <StatusChip status={row.status}>
                                {row.status === 'AWAITING_APPROVAL'
                                  ? 'Pending'
                                  : row.status === 'COMPLETED_CAMPAIGNS'
                                    ? 'Completed'
                                    : row.status === 'STOPPED_CAMPAIGNS'
                                      ? 'Paused'
                                      : 'Live Now'}
                              </StatusChip>
                            </TableCell>
                            <TableCell
                              sx={{
                                width: '7rem',
                                border: '1px solid #EAEAEA',
                                padding: '0.5rem',
                              }}
                              align="center"
                            >
                              {row.SalesType}
                            </TableCell>
                            {/* <TableCell align="center">{row.protein}</TableCell> */}
                            <TableCell
                              sx={{
                                width: '7rem',
                                border: '1px solid #EAEAEA',
                                padding: '0.5rem',
                              }}
                              align="center"
                            >
                              {moment(row.created_at).format('ll')}
                            </TableCell>
                          </TableRow>
                        </>
                      )
                    })}
                </TableBody>
              )}
              {!campaign.campaign_loader && (
                <TableBody>
                  {filtered.length === 0 &&
                    businessSearch === '' &&
                    campaignStatus === 'ALL_STATUS' &&
                    sales === 'ALL_SALES' &&
                    allCampaign?.map((row, i) => {

                      return (
                        <>
                          <TableRow key={row.id}>
                            {SUPER_ADMIN.includes(login.email) && (
                              <TableCell
                                align="center"
                                sx={{
                                  border: '1px solid #EAEAEA',
                                  padding: '0.5rem',
                                }}
                              >
                                <Checkbox
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      add_to_selected_campaign_list(row);
                                    } else {
                                      remove_from_selected_campaign_list(
                                        row.campaign_id
                                      );
                                    }
                                  }}
                                  sx={{ fontSize: '150%' }}
                                />
                              </TableCell>
                            )}
                            <TableCell
                              align="center"
                              sx={{
                                border: '1px solid #EAEAEA',
                                padding: '0.5rem',
                              }}
                            >
                              {row.campaign_id}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                border: '1px solid #EAEAEA',
                                padding: '0.5rem',
                              }}
                            >
                              {row.business_id}
                            </TableCell>

                            <TableCell
                              sx={{
                                width: '12rem',
                                border: '1px solid #EAEAEA',
                                padding: '0.5rem',
                              }}
                              component="th"
                              scope="row"
                            >
                              <BusinessDetail>
                                <p>{row.business_name}</p>
                                <p
                                  style={{
                                    marginTop: '0.2rem',
                                    lineHeight: '0.9rem',
                                    fontSize: '0.7rem',
                                    color: 'grey',
                                  }}
                                >
                                  {row.business_category}
                                </p>
                              </BusinessDetail>
                            </TableCell>

                            <TableCell
                              sx={{
                                width: '9rem',
                                border: '1px solid #EAEAEA',
                                padding: '0.5rem',
                              }}
                              align="center"
                            >
                              {row.campaign_objective.replaceAll('_', ' ')}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                border: '1px solid #EAEAEA',
                                padding: '0.5rem',
                              }}
                            >
                              {row.promised_leads}
                            </TableCell>
                            <TableCell
                              sx={{
                                width: '6rem',
                                border: '1px solid #EAEAEA',
                                padding: '0.5rem',
                              }}
                              align="center"
                            >
                              Rs. {`${row.budget}`}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                border: '1px solid #EAEAEA',
                                padding: '0.5rem',
                              }}
                            >
                              {row.results}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                border: '1px solid #EAEAEA',
                                padding: '0.5rem',
                              }}
                            >
                              {row.promised_leads - row.results}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                border: '1px solid #EAEAEA',
                                padding: '0.5rem',
                              }}
                            >
                              {row.city ? row.city : '-'}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                border: '1px solid #EAEAEA',
                                padding: '0.5rem',
                              }}
                            >
                              {row?.mobile ? row?.mobile : '-'}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                border: '1px solid #EAEAEA',
                                padding: '0.5rem',
                              }}
                            >
                              <StatusChip status={row.status}>
                                {row.status === 'AWAITING_APPROVAL'
                                  ? 'Pending'
                                  : row.status === 'COMPLETED_CAMPAIGNS'
                                    ? 'Completed'
                                    : row.status === 'STOPPED_CAMPAIGNS'
                                      ? 'Paused'
                                      : 'Live Now'}
                              </StatusChip>
                            </TableCell>
                            <TableCell
                              sx={{
                                width: '7rem',
                                border: '1px solid #EAEAEA',
                                padding: '0.5rem',
                              }}
                              align="center"
                            >
                              {row.SalesType}
                            </TableCell>
                            <TableCell
                              sx={{
                                width: '7rem',
                                border: '1px solid #EAEAEA',
                                padding: '0.5rem',
                              }}
                              align="center"
                            >
                              {row.created_at
                                ? moment(row.created_at).format('ll')
                                : '-'}
                            </TableCell>
                          </TableRow>
                        </>
                      )
                    }
                    )}
                </TableBody>
              )}
            </Table>
          </PDF>
        </PdfSnapshot>
      </Modal>
      <Popover
        open={Boolean(paymentHistoryAnchor)}
        anchorEl={paymentHistoryAnchor}
        onClose={() => setPaymentHistoryAnchor('')}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <PaymentHistory>
          {/* {business?.payment_history} */}
          {business?.payment_history?.length !== 0 &&
            business?.payment_history?.map((row) => {
              return (
                <Payment>
                  <h4>
                    {row?.label !== 'Yesterday' && row?.label !== 'Today'
                      ? moment(row?.label).format('LL')
                      : row?.label}
                  </h4>
                  {row?.data?.map((row2) => {
                    return (
                      <>
                        <p>-------</p>
                        <p>
                          Transaction date:{' '}
                          {moment(row2?.transaction_ended_at).format('LL')}
                        </p>
                        <p>Amount with Tax : ₹ {row2?.amount_with_tax}</p>
                        <p>Business name : {row2?.business_name}</p>
                        <p>
                          Campaign target :{' '}
                          {row2?.campaign_details?.campaign_target}
                        </p>
                        <p style={{ marginBottom: '0.5rem' }}>
                          Campaign platform :{' '}
                          {row2?.campaign_details?.campaign_platform}
                        </p>
                      </>
                    );
                  })}
                </Payment>
              );
            })}
        </PaymentHistory>
      </Popover>
    </Wrapper>
  );
};

export default SalesLeadsReport;