import React from 'react';
import LeadReward from '../../pages/leadReward/LeadReward';
import {
  set_reload_login,
  logout,
  do_login,
} from '../../actions/login/loginActions';
import {
  lead_reward,
  set_lead_reward_status,
} from '../../actions/leadReward/leadRewardActions';
import {
  set_reach_reward_status,
  add_reach_reward,
} from '../../actions/reachReward/reachRewardActions';
import { connect } from 'react-redux/';

const LeadRewardContainer = (props) => {
  return <LeadReward {...props} />;
};

const mapStateToProps = (store) => {
  return {
    login: store.login,
    business: store.business,
    reward: store.leadReward,
    reachReward: store.reachReward,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => {
      dispatch(logout());
    },
    do_login: (number) => {
      dispatch(do_login(number));
    },
    lead_reward: (reward) => {
      dispatch(lead_reward(reward));
    },
    set_reload_login: (user) => {
      dispatch(set_reload_login(user));
    },
    set_lead_reward_status: (status) => {
      dispatch(set_lead_reward_status(status));
    },
    set_reach_reward_status: (status) => {
      dispatch(set_reach_reward_status(status));
    },
    add_reach_reward: (reward) => {
      dispatch(add_reach_reward(reward));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LeadRewardContainer);
