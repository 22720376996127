import React from 'react';
import Layout from './layout/Layout';
// import GlobalStyles from '@mui/material/GlobalStyles';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import LoginContainer from './container/login/LoginContainer';

const theme = createTheme({
  typography: {
    fontFamily: ['Poppins', 'sans-serif'].join(','),
    fontSize: '0.9rem',
  },
});

function App() {
  // const navigate = useNavigate();

  // useEffect(() => {
  //   navigate('/create-campaign');
  // }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LoginContainer />} />
          <Route
            path="/create-campaign"
            element={<Layout type="create-campaign" />}
          />
          <Route
            path="/all-campaigns"
            element={<Layout type="all-campaigns" />}
          />
          <Route
            path="/need-attention"
            element={<Layout type="need-attention" />}
          />
          <Route
            path="/revenue-report"
            element={<Layout type="revenue-report" />}
          />
          <Route path="/add-reward" element={<Layout type="add-reward" />} />
          <Route
            path="/reach-reward"
            element={<Layout type="reach-reward" />}
          />
          <Route
            path="/reach-report"
            element={<Layout type="reach-report" />}
          />
          <Route path="/lead-report" element={<Layout type="lead-report" />} />
          <Route
            path="/all-business"
            element={<Layout type="all-business" />}
          />
          <Route
            path="/unauthorized"
            element={<Layout type="unauthorized" />}
          />
          <Route
            path="/payment-report"
            element={<Layout type="payment-report" />}
          />
          <Route
            path="/payment-link"
            element={<Layout type="payment-link" />}
          />
          <Route
            path="/rewards-status"
            element={<Layout type="rewards-status" />}
          />
          <Route
            path="/business-vise-report"
            element={<Layout type="business-vise-report" />}
          />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

// #001738

export default App;
