import styled from 'styled-components';

export const Wrapper = styled.main`
  width: 100%;
  /* max-height: 100vh; */
  /* overflow: hidden; */
  display: grid;
  grid-template-columns: 4rem 1fr;
  grid-template-rows: 4rem max-content;
`;

export const SideBar = styled.div`
  grid-column: 1/2;
  grid-row: 1/3;
  position: fixed;
  left: 0;
  top: 0;
  background-color: #001738;
  width: 4rem;
  height: 100vh;
  z-index: 3;
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%);
  transition: all 0.3s ease-out;

  &:hover {
    width: 12rem;
  }
`;

export const Topbar = styled.header`
  grid-column: 2/3;
  grid-row: 1/2;
  background-color: #fff;
  box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%);
  position: fixed;
  width: 100%;
  height: 4rem;
  z-index: 2;
  padding: 0 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Main = styled.section`
  grid-column: 2/3;
  grid-row-start: 2;
  min-height: 90vh;
  /* min-height: ${(props) => `${window.innerHeight - 6 * 16}px`}; */
`;

// ----------SIDEBAR------------

export const List = styled.ul`
  list-style: none;
  margin: 0;
  margin-top: 3rem;
  width: max-content;
  overflow: none;
`;

export const ListItem = styled.li`
  color: ${(props) => (props.active === true ? '#fff' : '#a6b0cf')};
  transition: all 0.2s;
  cursor: pointer;
  padding: 0.7rem 0;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;

  & > *:not(:last-child) {
    margin-right: 0.8rem;
  }

  &:hover {
    color: #fff;
  }
`;

export const LogoCont = styled.div`
  display: flex;
  align-items: center;

  img {
    width: 4rem;
  }

  h2 {
    color: #fff;
    font-weight: 500;
    margin-left: 0.5rem;
  }
`;

// -----------------------------

// ----------TOPBAR------------

export const TopbarLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TopbarRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const AvatartCont = styled.div`
  display: flex;
  align-items: center;

  & > *:not(:last-child) {
    margin-right: 0.8rem;
  }
`;

export const AvatarMenu = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  p {
    margin-right: 0.1rem;
    font-size: 0.85rem;
    color: #74788d;
  }
`;

// ----------------------------
