import UNIVERSAL from '../../config/config';
import {
  SET_BUSINESS_DETAILS,
  RESET_BUSINESS,
  SET_PAYMENT_HISTORY,
} from '../../constants/business/businessConstants';
import { set_snackbar } from '../snackbar/snackbarActions';

export function get_business(number) {
  return (dispatch) => {
    return fetch(UNIVERSAL.BASEURL + '/biz/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': '6gZ2J0WfUx9iAmKIwvwG3NiX1783yn094tzrssHe',
      },
      body: JSON.stringify({
        mobile: number,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        if (responseJson.message === 'Business details') {
          dispatch(set_business_details(responseJson.data));

          //   dispatch(get_business_profile(responseJson.data));
        } else {
          dispatch(
            set_snackbar({
              status: 'error',
              message: responseJson.message,
            })
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function get_payment_history(business_id) {
  return (dispatch) => {
    return fetch(UNIVERSAL.BASEURL + '/paymentv2/v2/transactions', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': '6gZ2J0WfUx9iAmKIwvwG3NiX1783yn094tzrssHe',
      },
      body: JSON.stringify({
        business_id: business_id,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        if (responseJson.success) {
          dispatch(set_payment_history(responseJson.data));

          //   dispatch(get_business_profile(responseJson.data));
        } else {
          dispatch(
            set_snackbar({
              status: 'error',
              message: responseJson.message,
            })
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function set_payment_history(payload) {
  return {
    type: SET_PAYMENT_HISTORY,
    payload: payload,
  };
}

export function set_business_details(payload) {
  return {
    type: SET_BUSINESS_DETAILS,
    payload: payload,
  };
}

export function reset_business() {
  return {
    type: RESET_BUSINESS,
  };
}
