import {
  SET_PAYMENT_LINK,
  SET_PAYMENT_LINK_STATUS,
} from '../../constants/paymentLink/paymentLinkConst';

const initial_state = {
  payment_link_status: false,
  payment_link: '',
};

export default function reducer(state = initial_state, action) {
  switch (action.type) {
    case SET_PAYMENT_LINK_STATUS:
      return (state = { ...state, payment_link_status: action.payload });
    case SET_PAYMENT_LINK:
      return (state = { ...state, payment_link: action.payload });
    default:
      return state;
  }
}
