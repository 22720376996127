import React from 'react';
import PaymentLink from '../../pages/paymentLink/PaymentLink';
import { set_reload_login, logout, do_login } from '../../actions/login/loginActions';
import {
  generate_payment_link,
  set_payment_link_status,
} from '../../actions/paymentLink/paymentLinkActions';
import { connect } from 'react-redux/';

const PaymentLinkContainer = (props) => {
  return <PaymentLink {...props} />;
};

const mapStateToProps = (store) => {
  return {
    login: store.login,
    business: store.business,
    paymentLink: store.paymentLink,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => {
      dispatch(logout());
    },
    do_login: (number) => {
      dispatch(do_login(number));
    },
    set_reload_login: (user) => {
      dispatch(set_reload_login(user));
    },
    set_payment_link_status: (status) => {
      dispatch(set_payment_link_status(status));
    },
    generate_payment_link: (businessId, amountToPay, phone) => {
      dispatch(generate_payment_link(businessId, amountToPay, phone));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentLinkContainer);
