const UNIVERSAL = {
  BASEURL: 'https://api.zocket.com',
  BASEURL2:'https://x.zocket.com/prod',
  BUCKET_REGION: 'ap-south-1',
  BUCKET_ACCESS_KEY: 'AKIA3F6R353BVBCBXGSC',
  BUCKET_SECRET_ACCESS_KEY: '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',

  LOGIN_EMAIL: 'narendran@zocket.com',
  LOGIN_PASSWORD: 'user1234',

  GOOGLE_LOGIN_CLIENT_ID:
    '36792389211-uv3cp6ud9p6ucvf8tgm345qg80eh7k9k.apps.googleusercontent.com',
};

export default UNIVERSAL;
