import React from 'react';
import { connect } from 'react-redux';
import {
  get_business,
  reset_business,
  get_payment_history,
} from '../../actions/business/businessActions';
import {
  get_campaigns_payment_report,
  allocate_campaign_payment_report,
  // get_marketing_consultants,
  set_allocte_campaign_payment_report_status,
  get_campaigns_payment_report_by_role,
} from '../../actions/payment/paymentActions';
import { set_reload_login } from '../../actions/login/loginActions';
import PaymentReport from '../../pages/PaymentReport/PaymentReport';

const PaymentReportContainer = (props) => {
  return <PaymentReport {...props} />;
};

const mapStateToProps = (store) => {
  return {
    login: store.login,
    snackbar: store.snackbar,
    business: store.business,
    campaign: store.campaign,
    payment: store.paymentReport,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    get_business: (phone) => {
      dispatch(get_business(phone));
    },
    get_campaigns_payment_report: (startDate, endDate) => {
      console.log(startDate, 'testing...');
      dispatch(get_campaigns_payment_report(startDate, endDate));
    },
    get_campaigns_payment_report_by_role: (email, startDate, endDate) => {
      console.log('CHECK 2');
      dispatch(
        get_campaigns_payment_report_by_role(email, startDate, endDate, 'leads')
      );
    },
    // get_marketing_consultants: () => {
    //   dispatch(get_marketing_consultants());
    // },
    get_payment_history: (business_id) => {
      dispatch(get_payment_history(business_id));
    },
    allocate_campaign_payment_report: (data, fromDate, toDate) => {
      dispatch(allocate_campaign_payment_report(data, fromDate, toDate));
    },
    set_allocte_campaign_payment_report_status: (status) => {
      dispatch(set_allocte_campaign_payment_report_status(status));
    },
    reset_business: () => {
      dispatch(reset_business());
    },
    set_reload_login: (login) => {
      dispatch(set_reload_login(login));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentReportContainer);
