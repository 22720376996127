import React, { useEffect, useState, useRef } from 'react';
import {
  Flex,
  Header,
  Layout,
  EmptyCampaign,
  Left,
  Main,
  Right,
  Label,
  Field,
  CostDetail,
  ModalContent,
  FileUploadLabel,
  FileUploadInput,
  ImgCont,
  Form,
  GenImage,
  GenImageWrapper,
  Image,
  RunAd,
  Cost,
  Desc,
  MobileInput,
  CustomCard,
  BusinessDetailWrapper,
  BusinessDetail,
  Wrapper,
} from './BusinessViseReport.styles';
import Logo from './../../assets/logo.png';
import {
  Button,
  Select,
  MenuItem,
  TextField,
  FormControlLabel,
  Checkbox,
  Divider,
  Modal,
  InputAdornment,
  Icon,
  TableContainer,
  TableBody,
  TableRow,
  Table,
  TableHead,
  TableCell,
} from '@mui/material';
import Buffer from 'buffer';
window.Buffer = window.Buffer || Buffer.Buffer;
import { useNavigate } from 'react-router-dom';
import Lottie from 'lottie-react';
import SuccessAnimation from '../../assets/success.json';

import AnnouncementImg from '../../assets/announcement.png';
import { UploadFileToS3 } from '../../util/UploadFileToS3';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import html2canvas from 'html2canvas';
import NoDataImg from '../../assets/noData.jpg';
import UNIVERSAL from '../../config/config';

const BusinessViseReport = () => {
  const [mobileLogin, setMobileLogin] = useState(false);
  const [phone, setPhone] = useState('');
  const [businessDetails, setBusinessDetails] = useState();
  const [isPhnInputValid, setIsPhnInputValid] = useState(false);
  const [isPhnValid, setIsPhnValid] = useState(true);

  function handlePhone(event) {
    if (event.target.value.length <= 10) {
      setPhone(event.target.value);
    }
    if (event.target.value.length > 9) {
      setIsPhnInputValid(true);
    } else {
      setIsPhnInputValid(false);
    }
  }

  function handleSetMobileLogin() {
    setIsPhnValid(true);
    setMobileLogin(false);
  }

  function handleMobileLogin() {
    return fetch(UNIVERSAL.BASEURL2 + `/customer/user/api/v1/business/business-campaign-details?mobile_number=${phone}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "X-Auth-Token": "nCHuFvhSxtj!ecQ-PS8!aDBMkbC==3fd=Pal=j0FRD7yD3EhyMx",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson) {
          if (responseJson.data) {
            setBusinessDetails(responseJson.data);
            setMobileLogin(true);
          } else if (responseJson.data === null) {
            console.log(responseJson);
            setIsPhnValid(false);
          }
        } else {
          // error
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  if (!mobileLogin) {
    return (
      <Layout>
        <h3>Business Vise Report</h3>
        <MobileInput width="100%">
          <TextField
            label="Phone no."
            value={phone}
            onChange={handlePhone}
          />
          <Button
            sx={{ marginLeft: '1rem', height: 'max-content' }}
            variant="contained"
            style={{ backgroundColor: !isPhnInputValid && 'lightblue' }}
            onClick={handleMobileLogin}
            disabled={!isPhnInputValid}
          >
            Submit
          </Button>
        </MobileInput>
        {!isPhnValid && <p style={{ color: 'red', textAlign: 'center' }}>No data found, please check the entered phone number.</p>}
      </Layout>
    );
  }

  
  return (
    <>
      <Layout variant="bordered">
        <h3>Business Vise Report</h3>
        <BusinessDetailWrapper style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ display: 'contents', alignItems: '' }}>
            <BusinessDetail>
              <p style={{display:'contents'}}>Business name: <span style={{ fontWeight: "500", fontSize: "1rem", color: 'black' }}>{businessDetails.business_details.name}</span></p>
            </BusinessDetail>
            <BusinessDetail>
              <p style={{display:'contents'}}>Business category: <span style={{ fontWeight: "500", fontSize: "1rem", color: 'black' }}>{businessDetails.business_details.category}</span></p>
            </BusinessDetail>
            <BusinessDetail>
              <p style={{display:'contents'}}>Mobile No: <span style={{ fontWeight: "500", fontSize: "1rem", color: 'black' }}>{businessDetails.business_details.mobile_number}</span></p>
            </BusinessDetail>
          </div>
          <Button variant="outlined" onClick={handleSetMobileLogin}>
            {' '}
            Change Business
          </Button>
        </BusinessDetailWrapper>
      </Layout>
      <Wrapper>
        <CustomCard width="100%">
          <TableContainer>
            <Table sx={{ minWidth: 650, width: '100%' }}>
              <TableHead
                sx={{
                  backgroundColor: '#F8F8F8',
                  color: 'rgba(0, 0, 0, 0.5)',
                  padding: '0',
                  border: '1.5px solid #EAEAEA',
                }}
              >
                <TableRow
                  sx={{
                    fontSize: '0.8rem',
                    padding: '0',
                  }}
                >
                  <TableCell
                    sx={{
                      color: 'rgba(0, 0, 0, 0.5)',
                      padding: '0.5rem',
                      border: '2px solid #EAEAEA',
                    }}
                    align="center"
                  >
                    <p>Campaign ID</p>
                  </TableCell>
                  <TableCell
                    sx={{
                      color: 'rgba(0, 0, 0, 0.5)',
                      padding: '0.5rem',
                      border: '2px solid #EAEAEA',
                    }}
                    align="center"
                  >
                    <p>Campaign Objective</p>
                  </TableCell>
                  <TableCell
                    sx={{
                      color: 'rgba(0, 0, 0, 0.5)',
                      padding: '0.5rem',
                      border: '2px solid #EAEAEA',
                    }}
                    align="center"
                  >
                    <p>Promised Leads</p>
                  </TableCell>
                  <TableCell
                    sx={{
                      color: 'rgba(0, 0, 0, 0.5)',
                      padding: '0.5rem',
                      border: '2px solid #EAEAEA',
                    }}
                    align="center"
                  >
                    <p>Campaign Target</p>
                  </TableCell>
                  <TableCell
                    sx={{
                      color: 'rgba(0, 0, 0, 0.5)',
                      padding: '0.5rem',
                      border: '2px solid #EAEAEA',
                    }}
                    align="center"
                  >
                    <p>Results</p>
                  </TableCell>
                </TableRow>
              </TableHead>
              {!businessDetails.campaigns && <TableRow>
                <TableCell />
                <TableCell />
                <TableCell colSpan={3}>
                  <EmptyCampaign>
                    <p>No campaigns to show!!</p>
                    <img src={NoDataImg} />
                  </EmptyCampaign>
                </TableCell>
              </TableRow>}
              {businessDetails.campaigns && businessDetails.campaigns.map(data => (
                <TableBody key={data.id}>
                  <TableCell
                    align="center"
                    sx={{
                      border: '1px solid #EAEAEA',
                      padding: '0.5rem',
                    }}
                  >
                    {data.id}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      border: '1px solid #EAEAEA',
                      padding: '0.5rem',
                    }}
                  >
                    {data.campaign_objective}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      border: '1px solid #EAEAEA',
                      padding: '0.5rem',
                    }}
                  >
                    {data.promised_leads}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      border: '1px solid #EAEAEA',
                      padding: '0.5rem',
                    }}
                  >
                    {data.campaign_target}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      border: '1px solid #EAEAEA',
                      padding: '0.5rem',
                    }}
                  >
                    {data.results}
                  </TableCell>
                </TableBody>
              ))}
            </Table>
          </TableContainer>
        </CustomCard>
      </Wrapper>
    </>
  );
};

export default BusinessViseReport;
