import React, { useEffect, useState } from 'react';
import {
  Wrapper,
  CustomCard,
  Flex,
  FilterItem,
  TableContainer,
  ProductCell,
  ModalContent,
  EmptyCampaign,
  Detail,
  DetailHead,
  DetailHeadLeft,
  DetailHeadRight,
  Desc,
  AddCopyContainer,
  AddCopy,
  Product,
  ProductDetail,
  ProductDescription,
  StatusChip,
  BusinessDetail,
  Filters,
  CustomChip,
  TotalRows,
  RowCountChip,
  Analytics,
  StatsFlex,
  StatsCard,
  SortIcon,
  MenuItemFlex,
  TableHeadCell,
} from './RevenueReport.styles';
import {
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  IconButton,
  Select,
  MenuItem,
  TextField,
  Pagination,
  Link,
  Modal,
  CircularProgress,
  Skeleton,
  InputAdornment,
} from '@mui/material';

import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import SearchIcon from '@mui/icons-material/Search';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Menu from '@mui/material/Menu';

import Cake from '../../assets/cake.jpg';
import NoDataImg from '../../assets/noData.jpg';
import AddCopyImg from '../../assets/addCopy.png';
import SortImg from '../../assets/sort.png';
import { AUTHORIZED_USERS } from '../../config/accessPermission';

const RevenueReport = ({
  login,
  revenueReport,
  get_revenue_reports,
  set_reload_login,
}) => {
  const navigate = useNavigate();
  const [allReport, setAllReport] = useState([]);
  const [productModal, setProductModal] = useState(false);
  const [toDate, setToDate] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [expandedRow, setExpandedRow] = useState(null);
  const [revenueStatus, setRevenueStatus] = useState('ALL_STATUS');
  const [filtered, setFiltered] = useState([]);
  const [businessSearch, setBusinessSearch] = useState('');
  const [page, setPage] = useState(1);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [totalLeadSent, setTotalLeadSent] = useState(0);
  const [allReportLeadSent, setAllReportLeadSent] = useState(0);
  const [allReportRevenue, setAllReportRevenue] = useState(0);
  const [uniqueBusiness, setUniqueBusiness] = useState([]);
  const [sortCol, setSortCol] = useState('');
  const [sortType, setSortType] = useState(0);

  // useEffect(() => {
  //   var today = new Date();

  //   var str = `${today.getFullYear()}-${
  //     today.getMonth() + 1
  //   }-${today.getDate()}`;

  //   console.log(str);
  // });

  useEffect(() => {
    if (!AUTHORIZED_USERS.includes(login.email)) {
      console.log('JJJJ');
      navigate('/unauthorized');
    }
  }, [login]);

  useEffect(() => {
    setAllReport(revenueReport.all_revenue_reports);
  }, [revenueReport]);

  useEffect(() => {
    let revenueSum = 0;
    let leadSentSum = 0;
    revenueReport.all_revenue_reports.map((row) => {
      revenueSum = revenueSum + row.ActualLeadSent * row.LeadPrice;
      leadSentSum = leadSentSum + row.ActualLeadSent;
    });
    setAllReportRevenue(revenueSum);
    setAllReportLeadSent(leadSentSum);
  }, [revenueReport]);

  useEffect(() => {
    var today = new Date();

    var to = `${today.getFullYear()}-${
      today.getMonth() + 1 < 10
        ? `0${today.getMonth() + 1}`
        : today.getMonth() + 1
    }-${today.getDate() < 10 ? `0${today.getDate()}` : today.getDate()}`;

    //today.setDate(today.getDate() - 14);

    var from = `${today.getFullYear()}-${
      today.getMonth() + 1 < 10
        ? `0${today.getMonth() + 1}`
        : today.getMonth() + 1
    }-${today.getDate() < 10 ? `0${today.getDate()}` : today.getDate()}`;

    setFromDate(from);
    setToDate(to);

    get_revenue_reports(from, to);
  }, []);

  useEffect(() => {
    get_revenue_reports(fromDate, toDate);
  }, [toDate, fromDate]);

  useEffect(() => {
    if (businessSearch === '' && revenueStatus === 'ALL_STATUS') {
      setFiltered([]);
    } else {
      var newList = [];
      if (revenueStatus === 'ALL_STATUS' && businessSearch !== '') {
        newList = revenueReport.all_revenue_reports.filter((item) => {
          var tmp = item?.business_name?.toLowerCase();
          return tmp.includes(businessSearch.toLowerCase());
        });
      } else if (revenueStatus !== 'ALL_STATUS' && businessSearch === '') {
        newList = revenueReport.all_revenue_reports.filter((item) => {
          return item.status === revenueStatus;
        });
      } else if (revenueStatus !== 'ALL_STATUS' && businessSearch !== '') {
        newList = revenueReport.all_revenue_reports.filter((item) => {
          var tmp = item?.business_name?.toLowerCase();
          return (
            tmp.includes(businessSearch.toLowerCase()) &&
            item.status === revenueStatus
          );
        });
      }
      setFiltered(newList);
      setPage(1);
    }
  }, [businessSearch, revenueStatus]);

  useEffect(() => {
    if (
      filtered.length === 0 &&
      !revenueReport.revenue_report_loader &&
      (businessSearch !== '' || revenueStatus !== 'ALL_STATUS')
    ) {
      setTotalRevenue(0);
      setTotalLeadSent(0);
      setUniqueBusiness(0);
    } else if (
      filtered.length === 0 &&
      !(businessSearch !== '' || revenueStatus !== 'ALL_STATUS')
    ) {
      let revenueSum = 0;
      let leadSentSum = 0;
      let uniqueBusiness = [];
      revenueReport.all_revenue_reports.map((row) => {
        revenueSum = revenueSum + row.ActualLeadSent * row.LeadPrice;
        leadSentSum = leadSentSum + row.ActualLeadSent;
        uniqueBusiness.push(row.business_name);
      });
      setTotalLeadSent(leadSentSum);
      setTotalRevenue(revenueSum);
      setUniqueBusiness([...new Set(uniqueBusiness)]);
    } else {
      let revenueSum = 0;
      let leadSentSum = 0;
      let uniqueBusiness = [];
      filtered.map((row) => {
        revenueSum = revenueSum + row.ActualLeadSent * row.LeadPrice;
        leadSentSum = leadSentSum + row.ActualLeadSent;
        uniqueBusiness.push(row.business_name);
      });

      setTotalLeadSent(leadSentSum);
      setTotalRevenue(revenueSum);
      setUniqueBusiness([...new Set(uniqueBusiness)]);
    }
  }, [filtered, revenueReport]);

  // useEffect(() => {
  //   let tmp = [];
  //   revenueReport.all_revenue_reports.map((row) => {
  //     tmp.push(row.business_name);
  //   });

  //   tmp = [...new Set(tmp)];
  //   setUniqueBusiness(tmp);
  // }, [revenueReport]);

  useEffect(() => {
    let tmp = [];
    if (
      businessSearch === '' &&
      revenueStatus === 'ALL_STATUS' &&
      sortCol !== ''
    ) {
      let tmp1 = allReport;
      if (sortCol === 'promisedLead') {
        tmp1.sort((a, b) => b.promised_leads - a.promised_leads);
      } else if (sortCol === 'leadSent') {
        tmp1.sort((a, b) => b.ActualLeadSent - a.ActualLeadSent);
      } else if (sortCol === 'leadPrice') {
        tmp1.sort((a, b) => b.LeadPrice - a.LeadPrice);
      } else if (sortCol === 'leadBalance') {
        tmp1.sort((a, b) => b.lead_balance - a.lead_balance);
      } else if (sortCol === 'revenue') {
        tmp1.sort(
          (a, b) =>
            b.ActualLeadSent * b.ActualLeadSent - a.ActualLeadSent * a.LeadPrice
        );
      }

      if (sortType === 1) {
        tmp1.reverse();
      }

      setAllReport([...tmp1]);
    } else if (
      businessSearch !== '' ||
      (revenueStatus !== 'ALL_STATUS' && sortCol !== '')
    ) {
      let tmp2 = filtered;
      if (sortCol === 'promisedLead') {
        tmp2.sort((a, b) => b.promised_leads - a.promised_leads);
      } else if (sortCol === 'leadSent') {
        tmp2.sort((a, b) => b.ActualLeadSent - a.ActualLeadSent);
      } else if (sortCol === 'leadPrice') {
        tmp2.sort((a, b) => b.LeadPrice - a.LeadPrice);
      } else if (sortCol === 'leadBalance') {
        tmp2.sort((a, b) => b.lead_balance - a.lead_balance);
      } else if (sortCol === 'revenue') {
        tmp2.sort(
          (a, b) =>
            b.ActualLeadSent * b.ActualLeadSent - a.ActualLeadSent * a.LeadPrice
        );
      }

      if (sortType === 1) {
        tmp2.reverse();
      }
      setFiltered([...tmp2]);
    }
  }, [sortType, sortCol]);

  useEffect(() => {
    console.log(sortCol, allReport, filtered);
  }, [sortCol, allReport, filtered]);

  useEffect(() => {
    if (sessionStorage.getItem('zocket_token')) {
      const token = sessionStorage.getItem('zocket_token');
      const loginData = JSON.parse(sessionStorage?.getItem('zocket_login'));
      set_reload_login({ token, ...loginData });
    } else {
      navigate('/');
    }
  }, [sessionStorage.getItem('zocket_token')]);

  const get_add_copy = (media) =>
    media?.map((item, i) => {
      return (
        <AddCopy key={i}>
          {item.adImage && <img src={item.adImage} />}
          {item.adVideo && (
            <video width="144" height="96" controls>
              <source src={item.adVideo} type="video/mp4" />
            </video>
          )}
          <p>{item.adCaption}</p>
        </AddCopy>
      );
    });

  const handle_sort = (col) => {
    setSortCol(col);
    if (sortType === 1) {
      setSortType(0);
    } else if (sortType === 0) {
      setSortType(1);
      // setSortCol("")
    }
  };

  return (
    <Wrapper>
      <h3>REVENUE REPORT</h3>
      <StatsFlex>
        <StatsCard>
          <p>Leads Sent</p>
          <h3>{totalLeadSent.toLocaleString()}</h3>
        </StatsCard>
        <StatsCard>
          <p>Revenue</p>
          <h3>&#8377; {totalRevenue.toLocaleString()}</h3>
        </StatsCard>
        <StatsCard>
          <p>Unique Business</p>
          <h3>{uniqueBusiness.length || '0'}</h3>
        </StatsCard>
      </StatsFlex>
      <CustomCard width="100%">
        <Filters>
          <Flex>
            <FilterItem>
              <searchBox>
                <TextField
                  sx={{ width: '20rem' }}
                  size="small"
                  placeholder="Enter Business Name"
                  value={businessSearch}
                  onChange={(e) => setBusinessSearch(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon sx={{ fontSize: '1.3rem' }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </searchBox>
            </FilterItem>
          </Flex>
          <Flex>
            <FilterItem>
              <label>Revenue Status:</label>
              <Select
                size="small"
                style={{ width: '10rem' }}
                value={revenueStatus}
                onChange={(e) => setRevenueStatus(e.target.value)}
              >
                <MenuItem value="ALL_STATUS">All Status</MenuItem>
                <MenuItem value="LIVE_CAMPAIGNS">Live Now</MenuItem>
                <MenuItem value="STOPPED_CAMPAIGNS">Paused</MenuItem>
                <MenuItem value="COMPLETED_CAMPAIGNS">Completed</MenuItem>
                <MenuItem value="AWAITING_APPROVAL">Pending</MenuItem>
              </Select>
            </FilterItem>
            <FilterItem>
              <label>From:</label>
              <TextField
                size="small"
                style={{ width: '10rem' }}
                type="date"
                value={fromDate}
                onChange={(e) => setFromDate(e.target.value)}
              />
            </FilterItem>
            <FilterItem>
              <label>To:</label>
              <TextField
                size="small"
                style={{ width: '10rem' }}
                type="date"
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
              />
            </FilterItem>
          </Flex>
        </Filters>
        {/* {businessSearch !== '' || revenueStatus !== 'ALL_STATUS' ? (
          <TotalRows>
            <Analytics>
              <RowCountChip>
                {filtered.length === 0
                  ? !revenueReport.revenue_report_loader &&
                    (businessSearch !== '' || revenueStatus !== 'ALL_STATUS') &&
                    filtered.length === 0
                    ? '0'
                    : revenueReport.all_revenue_reports.length
                  : filtered.length}
              </RowCountChip>
              <p>revenue reports</p>
            </Analytics>
            <Analytics>
              <RowCountChip>{totalLeadSent.toLocaleString()}</RowCountChip>
              <p>leads sent</p>
            </Analytics>
            <Analytics>
              <RowCountChip>
                &#8377; {totalRevenue.toLocaleString()}
              </RowCountChip>
              <p>total revenue</p>
            </Analytics>
          </TotalRows>
        ) : (
          <></>
        )} */}
        {/* <Menu
        id="basic-menu"
        anchorEl={sortMenuAnchorEl}
        open={sortMenu}
        onClose={close_sort_menu}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <MenuItem onClick={() => {setSortType 1) ;close_sort_menu()   } }>
          <MenuItemFlex>
          <ArrowUpwardIcon />
          <p>Ascending</p>
          </MenuItemFlex>
        </MenuItem>
        <MenuItem onClick={() => {setSortType(0) ; close_sort_menu() } }>
        <MenuItemFlex>
          <ArrowDownwardIcon />
          <p>Descending</p>
          </MenuItemFlex>
        </MenuItem>
       
      </Menu> */}
        <TableContainer>
          <Table sx={{ minWidth: 650, width: '100%' }}>
            <TableHead
              sx={{
                backgroundColor: '#F8F8F8',
                color: 'rgba(0, 0, 0, 0.5)',
                padding: '0',
                border: '1.5px solid #EAEAEA',
              }}
            >
              <TableRow
                sx={{
                  fontSize: '0.8rem',
                  padding: '0',
                }}
              >
                <TableCell
                  sx={{
                    color: 'rgba(0, 0, 0, 0.5)',
                    // borderRadius: '1rem 0 0 1rem',
                    width: '12rem',
                    paddingTop: '0.5rem',
                    paddingBottom: '0.5rem',
                  }}
                  align="center"
                >
                  <p>Business Name & Category</p>
                </TableCell>

                <TableCell
                  sx={{
                    color: 'rgba(0, 0, 0, 0.5)',
                    paddingTop: '0.5rem',
                    paddingBottom: '0.5rem',
                    cursor: 'pointer',
                  }}
                  align="center"
                  onClick={(e) => handle_sort('promisedLead')}
                >
                  <TableHeadCell>
                    <p>Promised Leads</p>

                    <SortIcon>
                      {sortCol === 'promisedLead' && sortType === 1 && (
                        <ArrowDropUpIcon style={{ fontSize: '1.4rem' }} />
                      )}{' '}
                      {sortCol === 'promisedLead' && sortType === 0 && (
                        <ArrowDropDownIcon style={{ fontSize: '1.4rem' }} />
                      )}
                      {sortCol !== 'promisedLead' && <img src={SortImg} />}
                    </SortIcon>
                  </TableHeadCell>
                </TableCell>
                <TableCell
                  sx={{
                    color: 'rgba(0, 0, 0, 0.5)',
                    paddingTop: '0.5rem',
                    paddingBottom: '0.5rem',
                    cursor: 'pointer',
                  }}
                  align="center"
                  onClick={(e) => handle_sort('leadSent')}
                >
                  <TableHeadCell>
                    <p>Lead Sent</p>

                    <SortIcon>
                      {sortCol === 'leadSent' && sortType === 1 && (
                        <ArrowDropUpIcon style={{ fontSize: '1.4rem' }} />
                      )}{' '}
                      {sortCol === 'leadSent' && sortType === 0 && (
                        <ArrowDropDownIcon style={{ fontSize: '1.4rem' }} />
                      )}
                      {sortCol !== 'leadSent' && <img src={SortImg} />}
                    </SortIcon>
                  </TableHeadCell>
                </TableCell>
                <TableCell
                  sx={{
                    color: 'rgba(0, 0, 0, 0.5)',
                    paddingTop: '0.5rem',
                    paddingBottom: '0.5rem',
                    cursor: 'pointer',
                  }}
                  align="center"
                  onClick={(e) => handle_sort('leadPrice')}
                >
                  <TableHeadCell>
                    <p>Lead Price</p>

                    <SortIcon>
                      {sortCol === 'leadPrice' && sortType === 1 && (
                        <ArrowDropUpIcon style={{ fontSize: '1.4rem' }} />
                      )}{' '}
                      {sortCol === 'leadPrice' && sortType === 0 && (
                        <ArrowDropDownIcon style={{ fontSize: '1.4rem' }} />
                      )}
                      {sortCol !== 'leadPrice' && <img src={SortImg} />}
                    </SortIcon>
                  </TableHeadCell>
                </TableCell>
                <TableCell
                  sx={{
                    color: 'rgba(0, 0, 0, 0.5)',
                    paddingTop: '0.5rem',
                    paddingBottom: '0.5rem',
                    cursor: 'pointer',
                  }}
                  align="center"
                  onClick={(e) => handle_sort('leadBalance')}
                >
                  <TableHeadCell>
                    <p>Lead Balance</p>

                    <SortIcon>
                      {sortCol === 'leadBalance' && sortType === 1 && (
                        <ArrowDropUpIcon style={{ fontSize: '1.4rem' }} />
                      )}{' '}
                      {sortCol === 'leadBalance' && sortType === 0 && (
                        <ArrowDropDownIcon style={{ fontSize: '1.4rem' }} />
                      )}
                      {sortCol !== 'leadBalance' && <img src={SortImg} />}
                    </SortIcon>
                  </TableHeadCell>
                </TableCell>

                <TableCell
                  sx={{
                    color: 'rgba(0, 0, 0, 0.5)',
                    paddingTop: '0.5rem',
                    paddingBottom: '0.5rem',
                    cursor: 'pointer',
                  }}
                  align="center"
                  onClick={(e) => handle_sort('revenue')}
                >
                  <TableHeadCell>
                    <p>Revenue</p>
                    <SortIcon>
                      {sortCol === 'revenue' && sortType === 1 && (
                        <ArrowDropUpIcon style={{ fontSize: '1.4rem' }} />
                      )}{' '}
                      {sortCol === 'revenue' && sortType === 0 && (
                        <ArrowDropDownIcon style={{ fontSize: '1.4rem' }} />
                      )}
                      {sortCol !== 'revenue' && <img src={SortImg} />}
                    </SortIcon>
                  </TableHeadCell>
                </TableCell>
                <TableCell
                  sx={{
                    color: 'rgba(0, 0, 0, 0.5)',
                    paddingTop: '0.5rem',
                    paddingBottom: '0.5rem',
                  }}
                  align="center"
                >
                  <p>Campaign Status</p>
                </TableCell>

                <TableCell
                  sx={{
                    color: 'rgba(0, 0, 0, 0.5)',
                    paddingTop: '0.5rem',
                    paddingBottom: '0.5rem',
                    cursor: 'pointer',
                  }}
                  align="center"
                  onClick={(e) => handle_sort('createdAt')}
                >
                  <TableHeadCell>
                    <p>Created At</p>

                    <SortIcon>
                      {sortCol === 'createdAt' && sortType === 1 && (
                        <ArrowDropUpIcon style={{ fontSize: '1.4rem' }} />
                      )}{' '}
                      {sortCol === 'createdAt' && sortType === 0 && (
                        <ArrowDropDownIcon style={{ fontSize: '1.4rem' }} />
                      )}
                      {sortCol !== 'createdAt' && <img src={SortImg} />}
                    </SortIcon>
                  </TableHeadCell>
                </TableCell>
              </TableRow>
            </TableHead>
            {revenueReport.all_revenue_reports.length === 0 &&
              revenueReport.revenue_report_loader === false && (
                <TableBody>
                  <TableRow>
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell colSpan={4}>
                      <EmptyCampaign>
                        <p>No revenue reports to show!!</p>
                        <img src={NoDataImg} />
                      </EmptyCampaign>
                    </TableCell>
                    <TableCell />
                    <TableCell />
                    <TableCell />
                  </TableRow>
                  {/* <EmptyCampaign>
                    <p>No campaigns to show!!</p>
                    <img src={NoDataImg} />
                  </EmptyCampaign> */}
                </TableBody>
              )}
            {revenueReport.revenue_report_loader && (
              <TableBody>
                {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((row, i) => {
                  return (
                    <>
                      <TableRow
                        key={i}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          <Skeleton
                            variant="rectangular"
                            width="100%"
                            height={10}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <Skeleton
                            variant="rounded"
                            width="100%"
                            height={15}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <Skeleton
                            variant="rounded"
                            width="100%"
                            height={15}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <Skeleton
                            variant="rounded"
                            width="100%"
                            height={15}
                          />
                        </TableCell>

                        <TableCell align="center">
                          <Skeleton
                            variant="rounded"
                            width="100%"
                            height={15}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <Skeleton
                            variant="rounded"
                            width="100%"
                            height={15}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <Skeleton
                            variant="rounded"
                            width="100%"
                            height={15}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <Skeleton
                            variant="rounded"
                            width="100%"
                            height={15}
                          />
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })}
              </TableBody>
            )}
            {!revenueReport.revenue_report_loader &&
              (businessSearch !== '' || revenueStatus !== 'ALL_STATUS') &&
              filtered.length === 0 && (
                <TableBody>
                  <TableRow>
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell colSpan={4}>
                      <EmptyCampaign>
                        <p>No revenue report to show!!</p>
                        <img src={NoDataImg} />
                      </EmptyCampaign>
                    </TableCell>
                    <TableCell />
                    <TableCell />
                    <TableCell />
                  </TableRow>
                </TableBody>
              )}
            {!revenueReport.revenue_report_loader && (
              <TableBody>
                {filtered.length !== 0 &&
                  filtered
                    ?.slice((page - 1) * 10, (page - 1) * 10 + 10)
                    ?.map((row, i) => (
                      <>
                        <TableRow
                          key={row.id}
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                          }}
                        >
                          <TableCell
                            sx={{ width: '12rem' }}
                            component="th"
                            scope="row"
                          >
                            <BusinessDetail>
                              <p>{row.business_name}</p>
                              <span>{row.business_category}</span>
                            </BusinessDetail>
                          </TableCell>

                          <TableCell align="center">
                            {row.promised_leads}
                          </TableCell>
                          <TableCell align="center">
                            {row.ActualLeadSent}
                          </TableCell>
                          <TableCell align="center">
                            &#8377; {`${row.LeadPrice}`}
                          </TableCell>

                          <TableCell align="center">
                            {' '}
                            {`${row.lead_balance}`}
                          </TableCell>
                          <TableCell align="center">
                            {' '}
                            &#8377; {`${row.ActualLeadSent * row.LeadPrice}`}
                          </TableCell>

                          <TableCell align="center">
                            <StatusChip status={row.status}>
                              {row.status === 'AWAITING_APPROVAL'
                                ? 'Pending'
                                : row.status === 'COMPLETED_CAMPAIGNS'
                                ? 'Completed'
                                : row.status === 'STOPPED_CAMPAIGNS'
                                ? 'Paused'
                                : 'Live Now'}
                            </StatusChip>
                          </TableCell>
                          {/* <TableCell align="center">{row.protein}</TableCell> */}

                          <TableCell sx={{ width: '7rem' }} align="center">
                            {moment(row.created_at).format('ll')}
                          </TableCell>
                        </TableRow>
                      </>
                    ))}
              </TableBody>
            )}
            {!revenueReport.revenue_report_loader && (
              <TableBody>
                {filtered.length === 0 &&
                  businessSearch === '' &&
                  revenueStatus === 'ALL_STATUS' &&
                  allReport
                    .slice((page - 1) * 10, (page - 1) * 10 + 10)
                    ?.map((row, i) => (
                      <>
                        <TableRow
                          key={row.id}
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                          }}
                        >
                          <TableCell
                            sx={{ width: '12rem' }}
                            component="th"
                            scope="row"
                          >
                            <BusinessDetail>
                              <p>{row.business_name}</p>
                              <span>{row.business_category}</span>
                            </BusinessDetail>
                          </TableCell>

                          <TableCell align="center">
                            {row.promised_leads}
                          </TableCell>
                          <TableCell align="center">
                            {row.ActualLeadSent}
                          </TableCell>
                          <TableCell align="center">
                            &#8377; {`${row.LeadPrice}`}
                          </TableCell>

                          <TableCell align="center">
                            {' '}
                            {`${row.lead_balance}`}
                          </TableCell>
                          <TableCell align="center">
                            &#8377; {`${row.ActualLeadSent * row.LeadPrice}`}
                          </TableCell>

                          <TableCell align="center">
                            <StatusChip status={row.status}>
                              {row.status === 'AWAITING_APPROVAL'
                                ? 'Pending'
                                : row.status === 'COMPLETED_CAMPAIGNS'
                                ? 'Completed'
                                : row.status === 'STOPPED_CAMPAIGNS'
                                ? 'Paused'
                                : 'Live Now'}
                            </StatusChip>
                          </TableCell>
                          {/* <TableCell align="center">{row.protein}</TableCell> */}

                          <TableCell sx={{ width: '7rem' }} align="center">
                            {moment(row.created_at).format('ll')}
                          </TableCell>
                          {/* <TableCell>
                            <IconButton
                              onClick={() =>
                                expandedRow === null
                                  ? setExpandedRow(i)
                                  : expandedRow === i
                                  ? setExpandedRow(null)
                                  : setExpandedRow(i)
                              }
                            >
                              {expandedRow === i ? (
                                <KeyboardArrowUpIcon
                                  sx={{
                                    fontSize: '1rem',
                                    border: '1px solid #1977F3',
                                    borderRadius: '50%',
                                  }}
                                />
                              ) : (
                                <KeyboardArrowDownIcon
                                  sx={{
                                    fontSize: '1rem',
                                    border: '1px solid #1977F3',
                                    borderRadius: '50%',
                                  }}
                                />
                              )}
                            </IconButton>
                          </TableCell> */}
                        </TableRow>
                        {/* {expandedRow === i && (
                          <TableRow>
                            <TableCell colSpan={13}>
                              <Detail>
                                <DetailHead>
                                  <DetailHeadLeft>
                                    <p>Ad Copies</p>
                                  </DetailHeadLeft>
                                  <DetailHeadRight
                                    style={{
                                      display:
                                        row.media === null ? 'none' : 'flex',
                                    }}
                                  >
                                    <p>Product</p>
                                    <p>Product Description</p>
                                  </DetailHeadRight>
                                </DetailHead>
                                <Desc>
                                  <AddCopyContainer>
                                    {get_add_copy(
                                      JSON.parse(row?.campaign_platform_meta)
                                        .adPreview
                                    )}
                                  </AddCopyContainer>
                                  <Product
                                    style={{
                                      display:
                                        row.media === null ? 'none' : 'flex',
                                    }}
                                  >
                                    {JSON.parse(row.media) !== null && (
                                      <img
                                        src={JSON.parse(row.media)[0]?.url}
                                      />
                                    )}
                                    <ProductDetail
                                      style={{
                                        display:
                                          row.media === null ? 'none' : 'block',
                                      }}
                                    >
                                      <h4>{row?.product_name}</h4>
                                      <p>Price : {row?.selling_price}</p>
                                    </ProductDetail>
                                    <ProductDescription>
                                      {row.promotion_tags !== null &&
                                        JSON.parse(row?.promotion_tags)?.map(
                                          (item, i) => {
                                            return (
                                              <CustomChip key={i}>
                                                {item}
                                              </CustomChip>
                                            );
                                          }
                                        )}
                                      {row.promotion_tags !== null &&
                                        JSON.parse(row.promotion_tags)
                                          .length === 0 && (
                                          <CustomChip>
                                            {row.product_description
                                              ? row.product_description
                                              : 'No Description'}
                                          </CustomChip>
                                        )}
                                    </ProductDescription>
                                  </Product>
                                </Desc>
                              </Detail>
                            </TableCell>
                          </TableRow>
                        )} */}
                      </>
                    ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>

        <Pagination
          sx={{
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: '2rem',
          }}
          count={
            filtered.length === 0
              ? Math.ceil(revenueReport.all_revenue_reports.length / 10)
              : Math.ceil(filtered.length / 10)
          }
          page={page}
          onChange={(e, val) => setPage(val)}
          color="secondary"
        />
      </CustomCard>
      <Modal open={productModal} onClose={() => setProductModal(false)}>
        <ModalContent>
          <ProductCell>
            <img src={Cake} />
            <h5>Chocolate Truffle Cake</h5>
            <h4>Rs. 800</h4>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent
              consequat mauris
            </p>
          </ProductCell>
        </ModalContent>
      </Modal>
    </Wrapper>
  );
};

export default RevenueReport;
