import React, { useEffect, useState, useRef } from 'react';
import {
  Layout,
  Left,
  Main,
  Right,
  Label,
  Field,
  CostDetail,
  ModalContent,
  GenImageWrapper,
  Image,
  RunAd,
  Cost,
  Desc,
  MobileInput,
  BusinessDetailWrapper,
  BusinessDetail,
} from './LeadReward.styles';
import Logo from './../../assets/logo.png';
import {
  Button,
  Select,
  MenuItem,
  TextField,
  FormControlLabel,
  Checkbox,
  Divider,
  Modal,
  InputAdornment,
  Icon,
} from '@mui/material';
import Buffer from 'buffer';
window.Buffer = window.Buffer || Buffer.Buffer;
import { useNavigate } from 'react-router-dom';
import Lottie from 'lottie-react';
import SuccessAnimation from '../../assets/success.json';

import AnnouncementImg from '../../assets/announcement.png';
import { UploadFileToS3 } from '../../util/UploadFileToS3';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import html2canvas from 'html2canvas';
import { useSelector } from 'react-redux';
import { SALES_TEAM_ADMIN, SALES_TEAM_USER } from '../../config/accessPermission';

const LeadReward = ({
  login,
  business,
  reward,
  lead_reward,
  set_lead_reward_status,
  add_reach_reward,
  add_reach_reward_status,
  do_login,
}) => {
  const navigate = useNavigate();
  const [campaignBudget, setCampaignBudget] = useState('');
  const [campaignDiscount, setCampaignDiscount] = useState('');
  const [isTax, setIsTax] = useState(true);
  const [amountToPay, setAmountToPay] = useState('');
  const [successModal, setSuccessModal] = useState(false);
  const [leads, setLeads] = useState('');
  const [leadPrice, setLeadPrice] = useState('');
  const bannerEl = useRef(null);
  const [mobileLogin, setMobileLogin] = useState(false);
  const [phone, setPhone] = useState('');
  const [rewardType, setRewardType] = useState('lead_reward');
  const [subscription, setSubscription] = useState('6 months');
  const [budget, setBudget] = useState('');
  const [reach, setReach] = useState('');
  const [fbNeeded, setFbNeeded] = useState(false);

  const user = useSelector(state=>state.login);

  useEffect(() => {
    if (business?.details) {
      setMobileLogin(true);
    } else {
      setMobileLogin(false);
    }
  }, [business]);

  useEffect(() => {
    if (reward.add_reward_status) {
      setSuccessModal(true);
    }
  }, [reward]);

  const downloadImage = async (el, imageFileName) => {
    const canvas = await html2canvas(el);
    console.log(canvas);
    const blob = canvas.toDataURL('image/png', 1.0);

    const fakeLink = window.document.createElement('a');
    fakeLink.download = 'reward.png';

    fakeLink.href = blob;

    fetch(fakeLink)
      .then((res) => res.blob())
      .then((blob) => uploadTos3(blob));

    // fakeLink.click();
  };

  const uploadTos3 = (blob) => {

    let file = new File([blob], `${Date.now()}.png`);

    UploadFileToS3(file, 'reward-banner-test')
      .then((data) => {
        console.log(data.location);
        if (rewardType === 'lead_reward') {
          lead_reward({
            business_id: business?.details?.business_id,
            reward_image: data.location,
            total_leads: leads,
            cost_per_lead: leadPrice,
            subscription: subscription,
            // is_fb_needed: fbNeeded,
            reward_sent_by: login?.email,
          });
        }
        if (rewardType === 'reach_reward') {
          add_reach_reward({
            business_id: business?.details?.business_id,
            reward_image: data.location,
            budget: budget,
            reach: reach,
            subscription: subscription,
            // is_fb_needed: fbNeeded,
            reward_sent_by: login?.email,
          });
        }
      })
      .catch((err) => {
        console.log(err, 'ERROR');
      });
  };

  // useEffect(() => {
  //   if (business === '') {
  //     navigate('/');
  //   }
  // }, [business]);

  const handle_add_reward = (el) => {
    downloadImage(el);
    // setSuccessModal(true);
  };

  useEffect(() => {
    if (isTax) {
      let tmp = leads * leadPrice * 0.18;

      setAmountToPay(Math.round(tmp + leads * leadPrice));
    } else {
      setAmountToPay(leads * leadPrice);
    }
  }, [campaignBudget, campaignDiscount, isTax, leadPrice, leads]);

  const getHelperText = () => {
    if (isNaN(budget)) return "Number only" ;
    if(user.email === "shenaz@zocket.com"){
      if(subscription === "1 months" && budget < 5000 * 1) return `min Rs. ${5000 *1}` ;
    }
    else if(SALES_TEAM_ADMIN.indexOf(user.email)!==-1){
      if(subscription === "1 months" && budget < 7500 *  1) return `min Rs. ${7500 *1}` ;
    }
    if (subscription === "1 months" && budget < 3000 * 1) return `min Rs. ${3000 *1}` ;
   // if (subscription === "2 months" && budget < 3000 * 2) return `min Rs. ${3000 *2}` ;
    if (subscription === "3 months" && budget < 3000 * 3) return `min Rs. ${3000 *3}` ;
    if (subscription === "4 months" && budget < 3000 * 4) return `min Rs. ${3000 *4}` ;
    if (subscription === "5 months" && budget < 3000 *5) return `min Rs. ${3000*5}` ;
    if (subscription === "6 months" && budget < 1000 *6) return `min Rs. ${1000*6}` ;
    if (subscription === "7 months" && budget < 3000 *7) return `min Rs. ${3000*7}` ;
    if (subscription === "8 months" && budget < 3000 *8) return `min Rs. ${3000*8}` ;
    if (subscription === "9 months" && budget < 3000 *9) return `min Rs. ${3000*9}` ;
    if (subscription === "10 months" && budget < 3000 *10) return `min Rs. ${3000*10}` ;
    if (subscription === "11 months" && budget < 3000 *11) return `min Rs. ${3000*11}` ;
    if (subscription === "1 year" && budget < 36000) return "min Rs.36000" ;
    return false
  }


  const getReachHelperText = () => {
    if (isNaN(reach)) return "Number only" ;
    // if (subscription === "3 months" && budget < 3000 * 3) return `min Rs. ${3000 *3}` ;
    // if (subscription === "4 months" && budget < 3000 * 4) return `min Rs. ${3000 *4}` ;
    // if (subscription === "5 months" && budget < 3000 *5) return `min Rs. ${3000*5}` ;
    // if (subscription === "6 months" && budget < 3000 *6) return `min Rs. ${3000*6}` ;
    // if (subscription === "7 months" && budget < 3000 *7) return `min Rs. ${3000*7}` ;
    // if (subscription === "8 months" && budget < 3000 *8) return `min Rs. ${3000*8}` ;
    // if (subscription === "9 months" && budget < 3000 *9) return `min Rs. ${3000*9}` ;
    // if (subscription === "10 months" && budget < 3000 *10) return `min Rs. ${3000*10}` ;
    // if (subscription === "11 months" && budget < 3000 *11) return `min Rs. ${3000*11}` ;
    // if (subscription === "1 year" && budget < 36000) return "min Rs.36000" ;
    if (reach < budget * 10 || reach > budget *30) return `min ${budget*10} and max ${budget*30}`
    return false
  }

  if (!mobileLogin) {
    return (
      <Layout>
        <h3>Add reach reward</h3>
        <MobileInput width="100%">
          <TextField
            label="Phone no."
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
          <Button
            sx={{ marginLeft: '1rem', height: 'max-content' }}
            variant="contained"
            onClick={() => do_login(phone)}
          >
            Submit
          </Button>
        </MobileInput>
      </Layout>
    );
  }

  return (
    <Layout variant="bordered">
      <h3>Add lead reward</h3>
      <BusinessDetailWrapper>
        <div>
          <BusinessDetail>
            <p>Business name: </p>
            <TextField
              size="small"
              readOnly
              value={business?.details?.business_name}
            />
          </BusinessDetail>
          <BusinessDetail>
            <p>Business category: </p>
            <TextField
              size="small"
              readOnly
              value={business?.details?.business_category}
            />
          </BusinessDetail>
          <BusinessDetail>
            <p>Mobile No: </p>
            <TextField
              size="small"
              readOnly
              value={business?.details?.mobile}
            />
          </BusinessDetail>
          <BusinessDetail>
            <p>Address: </p>
            <TextField
              size="small"
              readOnly
              value={`${business?.details?.address_2},${business?.details?.city}`}
            />
          </BusinessDetail>
        </div>
        <Button variant="outlined" onClick={() => setMobileLogin(false)}>
          {' '}
          Change Business
        </Button>
      </BusinessDetailWrapper>
      <Main width="100%">
        <Left>
          {/* <Form> */}
          <Field>
            <Label>Select reward type</Label>
            <TextField
              size="small"
              fullWidth
              select
              value={rewardType}
              onChange={(e) => setRewardType(e.target.value)}
            >
              {/* <MenuItem value="lead_reward">Lead reward</MenuItem> */}
              <MenuItem value="reach_reward">Reach reward</MenuItem>
            </TextField>
          </Field>
          {rewardType === 'lead_reward' && (
            <>
              <Field>
                <Label>No. of leads</Label>
                <TextField
                  error={isNaN(leads)}
                  helperText={isNaN(leads) ? 'Number only' : ''}
                  size="small"
                  fullWidth
                  value={leads}
                  onChange={(e) => setLeads(e.target.value)}
                />
              </Field>
              <Field>
                <Label>Lead price</Label>
                <TextField
                  error={isNaN(leadPrice)}
                  helperText={isNaN(leadPrice) ? 'Number only' : ''}
                  size="small"
                  fullWidth
                  value={leadPrice}
                  onChange={(e) => setLeadPrice(e.target.value)}
                />
              </Field>
              {/* <Field>
            <Label>Subscription</Label>
            <TextField
              size="small"
              select
              fullWidth
              value={subscription}
              onChange={(e) => setSubscription(e.target.value)}
            >
              <MenuItem value="6 months">6 months</MenuItem>
              <MenuItem value="1 year">1 year</MenuItem>
            </TextField>
          </Field> */}
              <Field>
                <CostDetail>
                  <Label>18% Tax</Label>
                  <p>Rs. {Math.round(leadPrice * leads * 0.18)}</p>
                </CostDetail>
              </Field>
              <Field
                style={{
                  borderStyle: 'dashed',
                  borderLeft: 'none',
                  borderRight: 'none',
                  borderBottom: 'none',
                  paddingTop: '1rem',
                }}
              >
                <CostDetail>
                  <Label>Amount to pay</Label>
                  <h5>
                    {leadPrice !== '' && leads !== ''
                      ? `Rs ${amountToPay}`
                      : '...'}
                  </h5>
                </CostDetail>
              </Field>
              {/* {business?.details?.page_id === null && (
                <Field>
                  <Flex>
                    <Checkbox
                      checked={fbNeeded}
                      onChange={(e) => setFbNeeded(e.target.checked)}
                      sx={{
                        padding: 0,
                        marginRight: '0.4rem',
                        fontSize: '150%',
                      }}
                    />
                    <p>Is facebook needed</p>
                  </Flex>
                </Field>
              )} */}
              <Field>
                <Button
                  disabled={leads === '' || leadPrice === ''}
                  variant="contained"
                  onClick={() =>
                    handle_add_reward(bannerEl.current, `${Date.now()}`)
                  }
                >
                  Submit
                </Button>
              </Field>
            </>
          )}
          {rewardType === 'reach_reward' && (
            <>
              <Field>
                <Label>Budget</Label>
                <TextField
                  error={isNaN(budget)}
                  helperText={getHelperText()}
                  size="small"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">Rs.</InputAdornment>
                    ),
                  }}
                  value={budget}
                  onChange={(e) => setBudget(e.target.value)}
                />
              </Field>
              <Field>
                <Label>Reach</Label>
                <TextField
                  error={isNaN(reach)}
                  helperText={getReachHelperText()}
                  size="small"
                  fullWidth
                  value={reach}
                  onChange={(e) => setReach(e.target.value)}
                />
              </Field>
              <Field>
                <Label>Subscription</Label>
                <TextField
                  size="small"
                  select
                  fullWidth
                  value={subscription}
                  onChange={(e) => setSubscription(e.target.value)}
                >
                  {SALES_TEAM_USER.indexOf(user.email)!==-1?<MenuItem value="1 months">1 months</MenuItem>:null}
                  {/* <MenuItem value="2 months">2 months</MenuItem> */}
                  <MenuItem value="3 months">3 months</MenuItem>
                  <MenuItem value="4 months">4 months</MenuItem>
                  <MenuItem value="5 months">5 months</MenuItem>
                  <MenuItem value="6 months">6 months</MenuItem>
                  <MenuItem value="7 months">7 months</MenuItem>
                  <MenuItem value="8 months">8 months</MenuItem>
                  <MenuItem value="8 months">8 months</MenuItem>
                  <MenuItem value="9 months">9 months</MenuItem>
                  <MenuItem value="10 months">10 months</MenuItem>
                  <MenuItem value="11 months">11 months</MenuItem>
                  <MenuItem value="1 year">1 year</MenuItem>
                </TextField>
              </Field>
              {/* {business?.details?.page_id === null && (
                <Field>
                  <Flex>
                    <Checkbox
                      checked={fbNeeded}
                      onChange={(e) => setFbNeeded(e.target.checked)}
                      sx={{
                        padding: 0,
                        marginRight: '0.4rem',
                        fontSize: '150%',
                      }}
                    />
                    <p>Is facebook needed</p>
                  </Flex>
                </Field>
              )} */}

              <Field>
                <Button
                  disabled={reach === '' || budget === '' || getHelperText() || getReachHelperText()}
                  variant="contained"
                  onClick={() =>
                    handle_add_reward(bannerEl.current, `${Date.now()}`)
                  }
                >
                  Submit
                </Button>
              </Field>
            </>
          )}

          {/* </Form> */}
        </Left>
        <Right>
          <GenImageWrapper>
            <h4>Image Banner</h4>
            <Image ref={bannerEl}>
              {rewardType === 'lead_reward' && (
                <>
                  <Desc style={{ margin: '0.5rem 0' }}>
                    &#8377;<Cost>&nbsp;{Math.round(leads * leadPrice)}</Cost>/-
                  </Desc>

                  <Desc>
                    Start running your ad at just
                    <br /> &#8377; {Math.round(leads * leadPrice)} and get{' '}
                    {leads} leads
                  </Desc>
                  <img src={AnnouncementImg} />
                  <RunAd>
                    <p>Run your ad</p>
                    <TrendingFlatIcon
                      sx={{
                        margin: '0',
                        marginBottom: '-0.2rem',
                        fontSize: '1.4rem',
                        padding: '0',
                      }}
                    />
                  </RunAd>
                </>
              )}
              {rewardType === 'reach_reward' && (
                <>
                  <Desc style={{ margin: '0.5rem 0' }}>
                    &#8377;<Cost>&nbsp;{Math.round(budget)}</Cost>/-
                  </Desc>

                  <Desc>
                    Start running your ad at just
                    <br /> &#8377; {Math.round(budget)} and get {reach} reaches
                  </Desc>
                  <img src={AnnouncementImg} />
                  <RunAd>
                    <p>Run your ad</p>
                    <TrendingFlatIcon
                      sx={{
                        margin: '0',
                        marginBottom: '-0.2rem',
                        fontSize: '1.4rem',
                        padding: '0',
                      }}
                    />
                  </RunAd>
                </>
              )}
            </Image>
          </GenImageWrapper>
        </Right>
      </Main>
      <Modal
        open={successModal}
        onClose={() => {
          set_lead_reward_status(false);
          setSuccessModal(false);
        }}
      >
        <ModalContent>
          <Lottie
            style={{
              width: '10rem',
            }}
            animationData={SuccessAnimation}
            loop={false}
          />
          <p>Reward Added</p>
          <Button
            variant="contained"
            onClick={() => {
              set_lead_reward_status(false);
              setSuccessModal(false);
            }}
          >
            Ok
          </Button>
        </ModalContent>
      </Modal>
    </Layout>
  );
};

export default LeadReward;
