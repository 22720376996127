import UNIVERSAL from '../../config/config';
import {
  SET_REVENUE_REPORTS,
  SET_REVENUE_REPORT_LOADER,
} from '../../constants/revenueReport/revenueReportConstants';

import { set_snackbar } from '../snackbar/snackbarActions';

export function get_revenue_reports(fromDate, toDate) {
  return (dispatch) => {
    dispatch(set_revenue_report_loader(true));
    return fetch(
      UNIVERSAL.BASEURL +
        `/sales/campaigns/revenue_daily_report?type=date&startDate=${fromDate}&endDate=${toDate}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': '6gZ2J0WfUx9iAmKIwvwG3NiX1783yn094tzrssHe',
        },
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        if (responseJson.success) {
          dispatch(set_revenue_report(responseJson.data));
          dispatch(set_revenue_report_loader(false));
          //   dispatch(get_business_profile(responseJson.data));
        } else {
          dispatch(set_revenue_report_loader(false));
          dispatch(
            set_snackbar({
              status: 'error',
              message: responseJson.message,
            })
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function set_revenue_report(payload) {
  return {
    type: SET_REVENUE_REPORTS,
    payload: payload,
  };
}

export function set_revenue_report_loader(payload) {
  return {
    type: SET_REVENUE_REPORT_LOADER,
    payload: payload,
  };
}
