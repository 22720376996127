export const KNOWN_USERS = [
  'alay@zocket.com',
  'mukund@zocket.com',
  'sudhir@zocket.com',
  'abhinav@zocket.com',
  'sandeep@zocket.com',
  'madhan@zocket.com',
  'yogesh@zocket.com',
  'jaysinh@zocket.com',
  'rajesh.singh@zocket.com',
  'harish@zocket.com',
  'narendran@zocket.com',
  'nandhu@zocket.com',
  'karthik.sudhakar@zocket.com',
  'mukund@zocket.com',
  'sundar@zocket.com',
  'karthik@zocket.com',
  'karunakar@zocket.com',
  'bhavana@zocket.com',
  'parveen@zocket.com',
  'rithwik@zocket.com',
  'amarnath@zocket.com',
  'vidhyaa@zocket.com',
  'dharshini@zocket.com',
  'sneha@zocket.com',
  'shenaz@zocket.com',
  'fauzia@zocket.com',
  'roopa@zocket.com',
];

export const AUTHORIZED_USERS = [
  'mukund@zocket.com',
  'dharani@zocket.com',
  'abhinaya@zocket.com',
  'ramkishan@zocket.com',
  'venkataprasath@zocket.com',
  'sundar@zocket.com',
  'karthik.sudhakar@zocket.com',
  'kamal@zocket.com',
  'alay@zocket.com',
  'narendran@zocket.com',
  'sudhir@zocket.com',
  'nandhu@zocket.com',
  'madhan@zocket.com',
  'mukund@zocket.com',
  'sundar@zocket.com',
  'karthik@zocket.com',
  'sandeep@zocket.com',
  'yogesh@zocket.com',
  'jaysinh@zocket.com',
  'rajesh.singh@zocket.com',
  'roopa@zocket.com',
  'karunakar@zocket.com',
  'harish@zocket.com',
  'bhavana@zocket.com',
  'parveen@zocket.com',
  'rithwik@zocket.com',
  'amarnath@zocket.com',
  'sneha@zocket.com',
  'vidhyaa@zocket.com',
  'dharshini@zocket.com',
  'shenaz@zocket.com',
  'fauzia@zocket.com',
  'abhinav@zocket.com',
];

export const SUPER_ADMIN = ['praveen@zocket.com'];
export const SALES_TEAM_USER = ['sudhir@zocket.com','sandeep@zocket.com','madhan@zocket.com','roopa@zocket.com','shenaz@zocket.com','abhinav@zocket.com'];
export const SALES_TEAM_ADMIN = ['sudhir@zocket.com','sandeep@zocket.com','madhan@zocket.com','abhinav@zocket.com'];

export const SALES_TEAM = [
  'sudhir@zocket.com',
  'narendran@zocket.com',
  'abhinav@zocket.com',
  'sandeep@zocket.com',
  'madhan@zocket.com',
  'yogesh@zocket.com',
  'jaysinh@zocket.com',
  'rajesh.singh@zocket.com',
  'harish@zocket.com',
  'nandhu@zocket.com',
  'karthik.sudhakar@zocket.com',
  'mukund@zocket.com',
  'sundar@zocket.com',
  'roopa@zocket.com',
  'bhavana@zocket.com',
  'karunakar@zocket.com',
  'parveen@zocket.com',
  'rithwik@zocket.com',
  'amarnath@zocket.com',
  'sneha@zocket.com',
  'vidhyaa@zocket.com',
  'dharshini@zocket.com',
  'shenaz@zocket.com',
  'fauzia@zocket.com',
  'karthik@zocket.com',
];

export const PERMISSION = {
  'alay@zocket.com': ['reach-report', 'lead-report', 'payment-report', 'add-reward'],
  'dharshini@zocket.com': ['reach-report', 'lead-report', 'payment-report', 'rewards-status', 'business-vise-report'],
  'vidhyaa@zocket.com': ['reach-report', 'lead-report', 'payment-report', 'rewards-status', 'business-vise-report'],
  'bhavana@zocket.com': ['reach-report', 'lead-report', 'payment-report'],
  'sneha@zocket.com':['reach-report', 'lead-report'],
  'fauzia@zocket.com':['reach-report', 'lead-report'],
  'shenaz@zocket.com':['reach-report', 'lead-report','add-reward','rewards-status', 'business-vise-report'],
  'parveen@zocket.com': ['business-vise-report'],
  'rithwik@zocket.com': ['business-vise-report'],
  'mukund@zocket.com': ['reach-report', 'lead-report','add-reward', 'payment-report','rewards-status', 'business-vise-report', 'payment-link'],
  'sundard@zocket.com': ['reach-report', 'lead-report','add-reward', 'payment-report', 'rewards-status', 'business-vise-report', 'payment-link'],
  'karthik@zocket.com': ['reach-report', 'lead-report','add-reward', 'payment-report', 'payment-link'],
  'abhinav@zocket.com': ['reach-report', 'lead-report','add-reward', 'payment-report', 'payment-link'],
  'sandeep@zocket.com': ['reach-report', 'lead-report','add-reward', 'payment-report', 'payment-link'],
  'madhan@zocket.com': ['reach-report', 'lead-report','add-reward', 'payment-report', 'rewards-status', 'business-vise-report','payment-link'],
  'yogesh@zocket.com': ['reach-report', 'lead-report','add-reward', 'payment-report', 'payment-link'],
  'jaysinh@zocket.com': ['reach-report', 'lead-report', 'payment-report'],
  'rajesh.singh@zocket.com': ['reach-report', 'lead-report', 'payment-report'],
  'harish@zocket.com': ['reach-report', 'lead-report','add-reward', 'payment-report','rewards-status', 'business-vise-report'],
  'amarnath@zocket.com' : ['reach-report', 'lead-report','add-reward', 'payment-report','rewards-status', 'business-vise-report'],
  'sudhir@zocket.com': [
    'reach-report',
    'lead-report',
    'payment-report',
    'add-reward',
    'reach-reward', 'rewards-status', 'business-vise-report','payment-link'
  ],
  'roopa@zocket.com': ['reach-report', 'lead-report','add-reward', 'payment-report','rewards-status', 'business-vise-report'],
  'nandhu@zocket.com': [
    'reach-report',
    'lead-report',
    'payment-report',
    'add-reward',
    'reach-reward','rewards-status', 'business-vise-report','payment-link'
  ],
  'karthik.sudhakar@zocket.com': [
    'reach-report',
    'lead-report',
    'payment-report','rewards-status', 'business-vise-report'
  ],
  'narendran@zocket.com': [
    'reach-report',
    'lead-report',
    'add-reward',
    'reach-reward',
    'payment-report','rewards-status', 'business-vise-report','payment-link'
  ],
  'karunakar@zocket.com': [
    'reach-report',
    'lead-report',
    'add-reward',
    'reach-reward',
    'payment-report','rewards-status', 'business-vise-report','payment-link'
  ],
};
