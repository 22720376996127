import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  /* padding: 3rem; */
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: #fff;

  & form {
    width: 25rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    h3 {
      text-transform: uppercase;
      font-weight: 500;
    }

    & > * {
      margin-bottom: 1rem;
    }
  }
`;

export const Left = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #001738;
  padding: 3rem;
  position: relative;
  overflow: hidden;

  h3 {
    color: #fff;
    font-weight: 400;
    font-size: 2rem;
    margin-bottom: 0.6rem;
  }

  p {
    color: rgba(255, 255, 255, 0.7);
  }
`;

export const LogoFlex = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 3rem;

  img {
    margin-right: 0.8rem;
    width: 3rem;
  }

  h1 {
    color: #fff;
    font-weight: 400;
  }
`;

export const Illustration = styled.img`
  margin-top: 4rem;
  width: 20rem;
`;

export const Dots = styled.div`
  position: absolute;
  width: 0.3rem;
  height: 0.3rem;
  border-radius: 50%;
  background-color: #f1cb50;
  opacity: 0.8;
  top: ${(props) => props.top};
  left: ${(props) => props.left};
`;

export const Right = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    font-weight: 500;
    font-size: 1.5rem;
  }
  p {
    color: #0b1a3366;
  }
`;

export const GoogleBtn = styled.div`
  margin: 4rem 0 1rem 0;
  position: relative;
  /* width: 10rem; */
  cursor: pointer;

  #googleLoginBtn {
    position: absolute;
    top: 50%;
    left: -50%;
    opacity: 0;
    z-index: 2;
    transform: translate(-50%, -50%);
    /* display: none; */
  }
`;

export const ActualBtn = styled.div`
  position: absolute;
  top: 0;
  left: -50%;
  width: max-content;
  height: max-content;
  display: flex;
  align-items: center;
  z-index: 1;
  transform: translate(-50%, -50%);
  border: 1px solid #eee;
  padding: 0.6rem;
  border-radius: 0.5rem;

  p {
    flex-shrink: 0;
    color: #000;
    font-size: 1rem;
  }

  img {
    width: 2rem;
    height: 2rem;
    object-fit: contain;
    margin-right: 0.6rem;
  }
`;

export const OrDivider = styled.p`
  margin: 2rem 0;
  position: relative;

  &::before {
    content: '';
    width: 8rem;
    height: 0.6px;
    background-color: #0b1a3366;
    position: absolute;
    left: 0%;
    top: 50%;

    transform: translate(-110%, -50%);
  }

  &::after {
    content: '';
    width: 8rem;
    height: 0.6px;
    background-color: #0b1a3366;
    position: absolute;
    right: 0%;
    top: 50%;

    transform: translate(110%, -50%);
  }
`;
