import React, { useState, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import {
  Alert,
  Snackbar,
  IconButton,
  TextField,
  Button,
  InputAdornment,
} from '@mui/material';
import UNIVERSAL from '../../config/config';
import jwtDecode from 'jwt-decode';

import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  ActualBtn,
  Detail1,
  Dots,
  GoogleBtn,
  Illustration,
  Left,
  LogoFlex,
  OrDivider,
  Right,
  Wrapper,
} from './Login.styles';
import {
  AUTHORIZED_USERS,
  PERMISSION,
  SALES_TEAM,
} from '../../config/accessPermission';
import Logo from '../../assets/logo.png';
import GoogleLogo from '../../assets/googleLogo.png';
import DashboardImg from '../../assets/dashboard.gif';

const Login = ({
  snackbar,
  do_static_login,
  set_reload_login,
  reset_login_snackbar,
  set_login,
}) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    /* global google */
    if (window.google) {
      google?.accounts?.id?.initialize({
        client_id: UNIVERSAL.GOOGLE_LOGIN_CLIENT_ID,
        callback: handleCallBackResponse,
      });

      google?.accounts?.id?.renderButton(
        document.getElementById('googleLoginBtn'),
        {
          theme: 'outline',
          type: 'standard',
          size: 'large',
          text: 'Sign in with Google',
        }
      );
    }
  }, []);

  let navigate = useNavigate();
  useEffect(() => {
    if (sessionStorage.getItem('zocket_token')) {
      const token = sessionStorage.getItem('zocket_token');
      const loginData = JSON.parse(sessionStorage?.getItem('zocket_login'));
      set_reload_login({ token, ...loginData });
      if (AUTHORIZED_USERS.includes(loginData.email)) {
        if (SALES_TEAM.includes(loginData.email)) {
          navigate('/lead-report');
        } else {
          navigate('/all-campaigns');
        }
      } else {
        navigate('/unauthorized');
      }
    }
  }, [sessionStorage.getItem('zocket_token')]);

  const handleLogin = (e) => {
    e.preventDefault();

    if (
      email !== UNIVERSAL.LOGIN_EMAIL ||
      password !== UNIVERSAL.LOGIN_PASSWORD
    ) {
      alert('Incorrect Email or Password');
    } else {
      do_static_login({ email: email, password: password });
    }
  };

  const handleCallBackResponse = (response) => {
    console.log('Credential', jwtDecode(response.credential));
    var decoded = jwtDecode(response.credential);

    set_login({
      token: decoded.jti,
      data: { user: { name: decoded.given_name, ...decoded } },
    });

    if (AUTHORIZED_USERS.includes(decoded.email)) {
      if (SALES_TEAM.includes(decoded.email)) {
        navigate('/lead-report');
      } else {
        navigate('/all-campaigns');
      }
    } else {
      navigate('/unauthorized');
    }
  };

  return (
    <Wrapper>
      {/* <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackbar?.login !== ''}
        autoHideDuration={6000}
        onClose={() => reset_login_snackbar()}
      >
        <Alert
          variant="outlined"
          severity={snackbar?.login?.status}
          action={
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => reset_login_snackbar()}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        >
          {snackbar?.login?.message}
        </Alert>
      </Snackbar> */}
      <Left>
        <Dots top="2rem" left="2rem" />
        <Dots top="4rem" left="9rem" />
        <Dots top="6rem" left="40rem" />
        <Dots top="10rem" left="42rem" />
        <Dots top="15rem" left="38rem" />
        <Dots top="17rem" left="15rem" />
        <Dots top="18rem" left="45rem" />
        <Dots top="22rem" left="40rem" />
        <Dots top="27rem" left="10rem" />
        <Dots top="25rem" left="35rem" />
        <Dots top="20rem" left="5rem" />
        <Dots top="29rem" left="40rem" />
        <Dots top="35rem" left="43rem" />
        <Dots top="35rem" left="8rem" />
        <Dots top="37rem" left="14rem" />
        <Dots top="38rem" left="35rem" />
        <Dots top="39rem" left="40rem" />
        <Dots top="39rem" left="5rem" />
        <LogoFlex>
          <img src={Logo} />
          <h1>Zocket</h1>
        </LogoFlex>
        <h3>Dashboard for us!</h3>
        <p>See all the analytics and grow your data remotely, from anywhere!</p>
        <Illustration src={DashboardImg} />
      </Left>
      <Right>
        <h3>Login</h3>
        <p>Enter your credentials to access your account</p>
        <GoogleBtn>
          <div id="googleLoginBtn" />
          <ActualBtn>
            <img src={GoogleLogo} />
            <p>Sign in with Google</p>
          </ActualBtn>
        </GoogleBtn>

        <OrDivider>Or sign in with email</OrDivider>

        <form onSubmit={(e) => handleLogin(e)}>
          <TextField
            value={email}
            label="Email"
            sx={{ width: '100%' }}
            type="email"
            onChange={(e) => setEmail(e.target.value)}
          />

          <TextField
            value={password}
            label="Password"
            type={showPassword ? 'text' : 'password'}
            sx={{ width: '100%' }}
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? (
                      <VisibilityOffIcon sx={{ fontSize: '1.3rem' }} />
                    ) : (
                      <VisibilityIcon sx={{ fontSize: '1.3rem' }} />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <Button variant="contained" type="submit" fullWidth>
            Login
          </Button>

          {/* <p>
              Don&apos;t have an account?{' '}
              <Link onClick={() => setFormType('signup')}>Sign up</Link>{' '}
            </p> */}
        </form>
      </Right>
    </Wrapper>
  );
};

export default Login;
