import React from 'react';

import { connect } from 'react-redux';
import {
  get_business,
  reset_business,
} from '../../actions/business/businessActions';
import CreateCampaign from '../../pages/createCampaign/CreateCampaign';

const CreateCampaignContainer = (props) => {
  return <CreateCampaign {...props} />;
};

const mapStateToProps = (store) => {
  return {
    login: store.login,
    snackbar: store.snackbar,
    business: store.business.details,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    get_business: (phone) => {
      dispatch(get_business(phone));
    },
    reset_business: () => {
      dispatch(reset_business());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateCampaignContainer);
