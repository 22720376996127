import UNIVERSAL from '../../config/config';

import {
    SET_CAMPAIGNS_REWARDS_STATUS,
    SET_CAMPAIGN_REWARDS_STATUS_LOADER,
} from '../../constants/rewardsStatus/rewardsStatusConstant';
import { set_snackbar } from '../snackbar/snackbarActions';

export function get_campaigns_rewards_status(fromDate, toDate) {
  return (dispatch) => {
    dispatch(set_campaign_rewards_status_loader(true));
    return fetch(UNIVERSAL.BASEURL2 + `/customer/user/api/v1/rewards?end_date=${toDate}&start_date=${fromDate}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-Auth-Token': 'nCHuFvhSxtj!ecQ-PS8!aDBMkbC==3fd=Pal=j0FRD7yD3EhyMx'
      }
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        if (responseJson.success) {
          dispatch(set_campaigns_rewards_status(responseJson.data.rewardList));
          dispatch(set_campaign_rewards_status_loader(false));
        } else {
          dispatch(set_campaigns_rewards_status([]));
          dispatch(set_campaign_rewards_status_loader(false));
          dispatch(
            set_snackbar({
              status: 'error',
              message: responseJson.message,
            })
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function set_campaigns_rewards_status(payload) {
    return {
        type: SET_CAMPAIGNS_REWARDS_STATUS,
        payload: payload,
    };
}

export function set_campaign_rewards_status_loader(payload) {
    return {
        type: SET_CAMPAIGN_REWARDS_STATUS_LOADER,
        payload: payload,
    };
}
