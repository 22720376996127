import React from 'react';
import { connect } from 'react-redux';
import RevenueReport from '../../pages/revenueReport/RevenueReport';
import { get_revenue_reports } from '../../actions/revenueReport/revenueReportActions';
import { set_reload_login } from '../../actions/login/loginActions';

const RevenueReportContainer = (props) => {
  return <RevenueReport {...props} />;
};

const mapStateToProps = (store) => {
  return {
    login: store.login,
    snackbar: store.snackbar,
    business: store.business.details,
    revenueReport: store.revenueReport,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    get_revenue_reports: (startDate, endDate) => {
      dispatch(get_revenue_reports(startDate, endDate));
    },
    set_reload_login: (login) => {
      dispatch(set_reload_login(login));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RevenueReportContainer);
