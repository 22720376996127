import {
  SET_USER_CONFIRM_PASSWORD,
  SET_USER_PASSWORD,
  SET_USER_EMAIL,
  SET_USER_NAME,
  RESET_USER,
} from '../../constants/user/userConst';
// import { set_login_snackbar } from '../snackbar/snackbarActions';

import { RELOAD_LOGIN, LOGIN, LOGOUT } from '../../constants/login/loginConst';
import UNIVERSAL from '../../config/config';
import {
  get_business,
  set_business_details,
} from '../business/businessActions';

// import { useNavigate } from 'react-router-dom';

export function do_login(number) {
  return (dispatch) => {
    return fetch(UNIVERSAL.BASEURL + '/biz/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': '6gZ2J0WfUx9iAmKIwvwG3NiX1783yn094tzrssHe',
      },
      body: JSON.stringify({
        mobile: number,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        if (responseJson.message === 'Business details') {
          dispatch(set_business_details(responseJson.data));
        } else {
          dispatch();
          // set_login_snackbar({
          //   status: 'error',
          //   message: responseJson.message,
          // })
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function do_static_login(user) {
  return (dispatch) => {
    if (
      user.email === UNIVERSAL.LOGIN_EMAIL &&
      user.password === UNIVERSAL.LOGIN_PASSWORD
    ) {
      dispatch(
        set_login({
          token: user.password,
          data: { user: { name: 'user', ...user } },
        })
      );
      dispatch();
      // set_login_snackbar({
      //   status: 'success',
      //   message: 'Login succesfull',
      // })
      dispatch(reset_user());
    } else {
      dispatch();
      // set_login_snackbar({
      //   status: 'error',
      //   message: 'Wrong username or password',
      // })
    }
  };
}

export function set_login(payload) {
  console.log(payload, 'PAYLOAD');
  sessionStorage.setItem('zocket_token', payload.token);
  sessionStorage.setItem('zocket_login', JSON.stringify(payload.data.user));

  return {
    type: LOGIN,
    payload: { token: payload.token, ...payload.data.user },
  };
}

export function set_reload_login(payload) {
  return {
    type: RELOAD_LOGIN,
    payload: payload,
  };
}

export function logout() {
  return (dispatch) => {
    sessionStorage.removeItem('zocket_token');
    sessionStorage.removeItem('zocket_login');
    // var navigate = useNavigate();
    // navigate('/');
    dispatch({
      type: LOGOUT,
    });
  };
}

export function set_user_name(payload) {
  return {
    type: SET_USER_NAME,
    payload: payload,
  };
}

export function set_user_email(payload) {
  return {
    type: SET_USER_EMAIL,
    payload: payload,
  };
}

export function set_user_password(payload) {
  return {
    type: SET_USER_PASSWORD,
    payload: payload,
  };
}

export function set_user_confirm_password(payload) {
  return {
    type: SET_USER_CONFIRM_PASSWORD,
    payload: payload,
  };
}

export function reset_user() {
  return {
    type: RESET_USER,
  };
}
