import {
  SET_BUSINESS_DETAILS,
  RESET_BUSINESS,
  SET_PAYMENT_HISTORY,
} from '../../constants/business/businessConstants';

const initial_state = {
  details: '',
  payment_history: '',
};

export default function reducer(state = initial_state, action) {
  switch (action.type) {
    case SET_BUSINESS_DETAILS:
      return (state = { ...state, details: action.payload });
    case SET_PAYMENT_HISTORY:
      return (state = { ...state, payment_history: action.payload });
    case RESET_BUSINESS:
      return (state = { ...state, details: '' });
    default:
      return state;
  }
}
