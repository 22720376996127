import styled from 'styled-components';
import { Card } from '@mui/material';

export const Layout = styled.section`
  width: 100%;
  /* height: 20rem; */
  padding: 1.5rem;

  h3 {
    font-weight: 500;
    font-size: 1rem;
    color: #444;
    margin-bottom: 0.6rem;
  }
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.6rem 3rem;
  /* background-color: #001738; */
  /* border-bottom: 1px solid #c1c1c1; */
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
  /* background: linear-gradient(-180deg, #fff 78%, hsla(0, 0%, 100%, 0)); */

  h1 {
    font-size: 1rem;
    color: #444;
    font-weight: 500;
  }

  img {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
  }
`;

export const Main = styled(Card)`
  margin-top: 1rem;
  margin-bottom: 1.6rem;
  padding: 1rem;
  width: ${(props) => props.width || 'max-content'};
  display: flex;
  /* align-items: center; */
  box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%) !important;

  p {
    /* color: #74788d; */
    font-size: 0.85rem;
  }

  h4 {
    font-weight: 500;
    font-size: 1rem;
  }

  & > *:not(:last-child) {
    margin-bottom: 0.5rem;
  }
`;

export const Left = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 5rem;
`;

export const GenImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: transparent;

  h4 {
    margin-bottom: 0.8rem;
    color: #444;
  }
`;

export const Image = styled.div`
  background-color: #ff9d06;
  width: 23rem;
  height: 11rem;
  border-radius: 1rem;
  padding: 1rem;
  position: relative;
  overflow: hidden;
  font-family: 'Eudoxus Sans', sans-serif !important;

  h5 {
    font-weight: 600;
    color: #faf9f6;
    margin-bottom: 1rem;
    text-decoration: underline;
    letter-spacing: 0.1rem;
    font-size: 0.7rem;
    text-underline-offset: 0.2rem;
  }

  p {
    width: 14rem;
    font-size: 1rem;
    line-height: 1.4rem;
    color: #faf9f6;
  }
  img {
    position: absolute;
    right: 0;
    bottom: 0rem;
    width: 8rem;
  }

  u {
    text-underline-offset: 0.2rem;
    color: #fff;
  }
`;

export const Desc = styled.p`
  width: 14rem;
  font-size: 1rem !important;
  line-height: 1.4rem !important;
  letter-spacing: normal;
  color: #faf9f6;
`;

export const Cost = styled.p`
  color: #fff;
  font-size: 1.8rem !important;
  font-family: 'Righteous';
  font-weight: 800;
  display: inline;
`;

export const RunAd = styled.div`
  margin-top: 1rem;
  background-color: azure;
  border-radius: 0.5rem;
  width: 8.6rem;
  height: 2rem;
  /* padding: 0.3rem 1rem; */
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;

  p {
    font-size: 0.8rem;
    color: #000;
    width: max-content;
    letter-spacing: normal;
    margin-right: 0.6rem;
  }
`;

export const Right = styled.div`
  width: 40%;
  padding: 2rem;
  /* background-color: azure !important; */
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #444;
  height: max-content;

  p {
    font-size: 0.9rem;
    flex-shrink: 0;
  }
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;

  p {
    width: 10rem;
  }
`;

export const Label = styled.p`
  font-size: 1rem;
  margin-bottom: 0.5rem;

    em {
    font-style: normal;
    color: gray;
    
  }
`;

export const Field = styled.div`
  margin-bottom: 1rem;
  width: 25rem;
  border-color: #c2c2c2;
  border-width: 2px;
  /* border-bottom: 1px solid #c1c1c1; */
`;

export const ImgCont = styled.div`
  width: 10rem;
  max-height: 10rem;

  img {
    width: 10rem;
    object-fit: contain;
  }
`;

export const CostDetail = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  /* border-style: dashed; */

  h5 {
    font-weight: 600;
    font-size: 1.1rem;
    color: #333;
  }
`;

export const ModalContent = styled.div`
  width: 25rem;
  height: max-content;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 0.7rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 0;

  p {
    margin-bottom: 1rem;
  }
`;

export const FileUploadLabel = styled.label`
  width: max-content;
  font-size: 0.9rem;
  background-color: #1976d2;
  padding: 0.5rem 1rem;
  color: #fff;
  border-radius: 0.4rem;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FileUploadInput = styled.input`
  display: none;
`;

export const MobileInput = styled(Card)`
  margin-top: 1rem;
  margin-bottom: 1.6rem;
  padding: 1rem;
  width: ${(props) => props.width || 'max-content'};
  display: flex;
  align-items: center;
  justify-content: center;
  /* align-items: center; */
  box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%) !important;

  p {
    /* color: #74788d; */
    font-size: 0.85rem;
  }

  h4 {
    font-weight: 500;
    font-size: 1rem;
  }
`;

export const BusinessDetailWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const BusinessDetail = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 0.5rem;
  p {
    width: 10rem;
  }
`;
