import { Button, TextField, Select, MenuItem } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import React, { useState } from 'react';
import {
  Wrapper,
  CustomCard,
  Form,
  FormLeft,
  FormRight,
  FormItem,
  Flex,
  TickFlex,
  Element,
  BtnCont,
} from './CreateCampaign.styles';

const CreateCampaign = ({ business, get_business, reset_business }) => {
  const [phoneNo, setPhoneNo] = useState('');
  const [campaignObjective, setCampaignObjective] = useState('');
  const [currentStep, setCurrentStep] = useState(0);

  return (
    <Wrapper>
      <h3>START CAMPAIGN</h3>
      {!business && (
        <CustomCard>
          <TextField
            size="small"
            label="Phone No."
            value={phoneNo}
            onChange={(e) => setPhoneNo(e.target.value)}
          />
          <Button
            size="small"
            variant="contained"
            onClick={() => get_business(phoneNo)}
          >
            Login
          </Button>
        </CustomCard>
      )}
      {business && (
        <>
          <CustomCard width="100%">
            <Flex>
              <h4>Business information</h4>
              <Button
                size="small"
                variant="outlined"
                onClick={() => reset_business()}
              >
                Change Business
              </Button>
            </Flex>

            <Form>
              <FormLeft>
                <FormItem>
                  <label>Business Name</label>
                  <TextField
                    size="small"
                    inputProps={{ readOnly: true }}
                    value={business?.business_name}
                  />
                </FormItem>
                <FormItem>
                  <label>Business Category</label>
                  <TextField
                    size="small"
                    inputProps={{ readOnly: true }}
                    value={business?.business_category}
                  />
                </FormItem>
              </FormLeft>
              <FormRight>
                <FormItem>
                  <label>Mobile No.</label>
                  <TextField
                    size="small"
                    inputProps={{ readOnly: true }}
                    value={business?.mobile}
                  />
                </FormItem>
                <FormItem>
                  <label>Address</label>
                  <TextField
                    size="small"
                    inputProps={{ readOnly: true }}
                    value={`${business?.address_2}, ${business?.city}`}
                  />
                </FormItem>
              </FormRight>
            </Form>
          </CustomCard>
          <CustomCard width="100%">
            <h4>Campaign information</h4>
            <Form>
              <FormLeft>
                <FormItem>
                  <TickFlex>
                    <CheckCircleIcon
                      sx={{
                        fontSize: '1.4rem',
                        color: '#1565c0',
                        visibility: currentStep > 0 ? 'visible' : 'hidden',
                      }}
                    />
                    <label>Campaign Objective</label>
                  </TickFlex>
                  {currentStep === 0 && (
                    <Element>
                      <Select size="small">
                        <MenuItem value="FREE CAMPAIGN">FREE CAMPAIGN</MenuItem>
                        <MenuItem value="LEADS">LEADS</MenuItem>
                      </Select>
                      <BtnCont>
                        <Button
                          size="small"
                          variant="contained"
                          onClick={() => setCurrentStep(currentStep + 1)}
                        >
                          Next
                        </Button>
                      </BtnCont>
                    </Element>
                  )}
                </FormItem>
                <FormItem>
                  <TickFlex>
                    <CheckCircleIcon
                      sx={{
                        fontSize: '1.4rem',
                        color: '#1565c0',
                        visibility: currentStep > 1 ? 'visible' : 'hidden',
                      }}
                    />
                    <label>Platform</label>
                  </TickFlex>
                  {currentStep === 1 && (
                    <Element>
                      <Select size="small">
                        <MenuItem value="FB">Facebook</MenuItem>
                        <MenuItem value="Google">Google</MenuItem>
                      </Select>
                      <BtnCont>
                        <Button
                          size="small"
                          variant="outlined"
                          onClick={() => setCurrentStep(currentStep - 1)}
                        >
                          Back
                        </Button>
                        <Button
                          size="small"
                          variant="contained"
                          onClick={() => setCurrentStep(currentStep + 1)}
                        >
                          Next
                        </Button>
                      </BtnCont>
                    </Element>
                  )}
                </FormItem>
                <FormItem>
                  <TickFlex>
                    <CheckCircleIcon
                      sx={{
                        fontSize: '1.4rem',
                        color: '#1565c0',
                        visibility: currentStep > 2 ? 'visible' : 'hidden',
                      }}
                    />
                    <label>Budget & Duration</label>
                  </TickFlex>
                  {currentStep === 2 && (
                    <Element>
                      <Select size="small">
                        <MenuItem value="FB">Facebook</MenuItem>
                        <MenuItem value="Google">Google</MenuItem>
                      </Select>
                      <BtnCont>
                        <Button
                          size="small"
                          variant="outlined"
                          onClick={() => setCurrentStep(currentStep - 1)}
                        >
                          Back
                        </Button>
                        <Button
                          size="small"
                          variant="contained"
                          onClick={() => setCurrentStep(currentStep + 1)}
                        >
                          Next
                        </Button>
                      </BtnCont>
                    </Element>
                  )}
                </FormItem>
              </FormLeft>
              <FormRight />
            </Form>
          </CustomCard>
        </>
      )}
    </Wrapper>
  );
};

export default CreateCampaign;
