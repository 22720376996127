import React, { useEffect } from 'react';
import { CustomCard, Wrapper } from './Unauthorized.styles';
import { useNavigate } from 'react-router-dom';
import { AUTHORIZED_USERS,SALES_TEAM } from '../../config/accessPermission';
import ForbiddenImg from '../../assets/forbidden.jpg';

const UnAuthorized = ({ login }) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (AUTHORIZED_USERS.includes(login.email)) {
      
      if (SALES_TEAM.includes(login.email)) {
        navigate('/lead-report');
        console.log('naren');
      } else {
        navigate('/all-campaigns');
        console.log('naren-else');
      }
    }
  }, [login]);
  return (
    <Wrapper>
      <CustomCard>
        <img src={ForbiddenImg} />
        <h2>You are not subscribed to Zocket</h2>
        <p>Contact zocket</p>
      </CustomCard>
    </Wrapper>
  );
};

export default UnAuthorized;
