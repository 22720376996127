import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import CreateCampaignContainer from '../container/createCampaign/CreateCampaignContainer';
import {
  AvatarMenu,
  AvatartCont,
  List,
  ListItem,
  LogoCont,
  Main,
  SideBar,
  Topbar,
  TopbarLeft,
  TopbarRight,
  Wrapper,
} from './Layout.styles';

import Logo from '../assets/logo.png';

import { Avatar, Menu, MenuItem } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CampaignIcon from '@mui/icons-material/Campaign';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import SummarizeIcon from '@mui/icons-material/Summarize';
import DiscountIcon from '@mui/icons-material/Discount';

import { useNavigate } from 'react-router-dom';
import AllCampaignContainer from '../container/allCampaign/AllCampaignContainer';
import AllBusinessContainer from '../container/allBusiness/AllBusinessContainer';
import NeedAttentionContainer from '../container/needAttention/NeedAttentionContainer';
import RevenueReportContainer from '../container/revenueReport/RevenueReportContainer';
import SalesReachReportContainer from '../container/revenueReport/SalesReachReportContainer';
import PaymentLinkContainer from "../container/paymentLink/PaymentLinkContainer"
import {
  AUTHORIZED_USERS,
  KNOWN_USERS,
  PERMISSION,
  SALES_TEAM,
} from '../config/accessPermission';
import NotPermitted from '../pages/notpermitted/NotPermitted';
import UnAuthorized from '../pages/unAutorized/UnAuthorized';
import UnauthorizedContainer from '../container/unathorized/UnauthorizedContainer';
import SalesLeadsReportContainer from '../container/revenueReport/SalesLeadsReportContainer';
import LeadRewardContainer from '../container/leadReward/LeadRewardContainer';
import ReachRewardContainer from '../container/reachReward/ReachRewardContainer';
import { set_reload_login } from '../actions/login/loginActions';
import PaymentReportContainer from '../container/paymentReport/PaymentReportContainer';
import RewardsStatusContainer from '../container/rewardsStatus/RewardsStatusContainer';
import BusinessViseReportContainer from '../container/businessViseReport/BusinessViseReportContainer';
import {set_business_details} from "../actions/business/businessActions";

const getRouteFromLocalStorage = () => {
  let data = localStorage.getItem('route');
  if (data) {
    return data;
  } else {
    return '/lead-report';
  }
};

const Layout = ({ set_business_details ,type, login, set_reload_login }) => {
  const [avatarMenu, setAvatarMenu] = useState(false);
  const [sideBarExtended, setSideBarExtended] = useState(false);
  const [permitedLinks, setPermitedLinks] = useState([]);
  // const [currentNav, setCurrentNav] = useState(0);
  const navigate = useNavigate();

  const [route, setRoute] = useState(getRouteFromLocalStorage);

  useEffect(() => {
    localStorage.setItem('route', location.pathname);
  });

  useEffect(()=>{
    set_business_details('');
  },[location.pathname])

  useEffect(() => {
    if (sessionStorage.getItem('zocket_token')) {
      const token = sessionStorage.getItem('zocket_token');
      const loginData = JSON.parse(sessionStorage?.getItem('zocket_login'));
      set_reload_login({ token, ...loginData });
      if (AUTHORIZED_USERS.includes(loginData.email)) {
        if (SALES_TEAM.includes(loginData.email)) {
          // navigate('/lead-report');
          navigate(route);
        } else {
          navigate('/all-campaigns');
        }
      } else {
        navigate('/unauthorized');
      }
    }
  }, [sessionStorage.getItem('zocket_token')]);

  const handle_logout = () => {
    sessionStorage.removeItem('zocket_token');
    sessionStorage.removeItem('zocket_login');
    navigate('/');
  };

  useEffect(() => {
    if (!sessionStorage.getItem('zocket_login')) return navigate("/")
    const currentUser = JSON.parse(
      sessionStorage.getItem('zocket_login')
    ).email;

    if (!KNOWN_USERS.includes(currentUser)) {
      setPermitedLinks([]);
    } else {
      if (PERMISSION[currentUser].includes('all-campaigns')) {
        let tmp = permitedLinks;
        tmp.push('all-campaigns');
        setPermitedLinks(tmp);
      }
      if (PERMISSION[currentUser].includes('need-attention')) {
        let tmp = permitedLinks;
        tmp.push('need-attention');
        setPermitedLinks(tmp);
      }
      if (PERMISSION[currentUser].includes('revenue-report')) {
        let tmp = permitedLinks;
        tmp.push('revenue-report');
        setPermitedLinks(tmp);
      }
      if (PERMISSION[currentUser].includes('reach-report')) {
        let tmp = permitedLinks;
        tmp.push('reach-report');
        setPermitedLinks(tmp);
      }
      if (PERMISSION[currentUser].includes('payment-report')) {
        let tmp = permitedLinks;
        tmp.push('payment-report');
        setPermitedLinks(tmp);
      }
      if (PERMISSION[currentUser].includes('rewards-status')) {
        let tmp = permitedLinks;
        tmp.push('rewards-status');
        setPermitedLinks(tmp);
      }
      if (PERMISSION[currentUser].includes('lead-report')) {
        let tmp = permitedLinks;
        tmp.push('lead-report');
        setPermitedLinks(tmp);
      }
      if (PERMISSION[currentUser].includes('add-reward')) {
        let tmp = permitedLinks;
        tmp.push('add-reward');
        setPermitedLinks(tmp);
      }
      if (PERMISSION[currentUser].includes('business-vise-report')) {
        let tmp = permitedLinks;
        tmp.push('business-vise-report');
        setPermitedLinks(tmp);
      }
      if (PERMISSION[currentUser].includes('reach-reward')) {
        let tmp = permitedLinks;
        tmp.push('reach-reward');
        setPermitedLinks(tmp);
      }
      if (PERMISSION[currentUser].includes('payment-link')) {
        let tmp = permitedLinks;
        tmp.push('payment-link');
        setPermitedLinks(tmp);
      }
      // if (PERMISSION[currentUser].includes('all-business')) {
      //   let tmp = permitedLinks;
      //   tmp.push('all-business');
      //   setPermitedLinks(tmp);
      // }
      // if (PERMISSION[currentUser].includes('create-campaign')) {
      //   let tmp = permitedLinks;
      //   tmp.push('create-campaign');
      //   setPermitedLinks(tmp);
      // }

      let tmp = permitedLinks;
      tmp = [...new Set(tmp)];
      setPermitedLinks(tmp);
    }
  }, [login, PERMISSION, KNOWN_USERS]);

  useEffect(() => {
    console.log(permitedLinks, login.email, 'ooo');
  }, []);

  return (
    <Wrapper>
      <SideBar
        onMouseEnter={() => setSideBarExtended(true)}
        onMouseLeave={() => setSideBarExtended(false)}
      >
        <LogoCont>
          <img src={Logo} alt="logo" />
          {sideBarExtended && <h2>Zocket</h2>}
        </LogoCont>

        <List>
          {permitedLinks.includes('all-campaigns') && (
            <ListItem
              onClick={() => {
                // setCurrentNav(0);
                navigate('/all-campaigns');
              }}
              active={location.pathname === '/all-campaigns'}
            >
              <CampaignIcon sx={{ fontSize: '1.4rem' }} />
              {sideBarExtended && <p>Campaigns</p>}
            </ListItem>
          )}
          {permitedLinks.includes('need-attention') && (
            <ListItem
              onClick={() => {
                // setCurrentNav(0);
                navigate('/need-attention');
              }}
              active={location.pathname === '/need-attention'}
            >
              <PriorityHighIcon sx={{ fontSize: '1.4rem' }} />
              {sideBarExtended && <p>Need Attention</p>}
            </ListItem>
          )}
          {permitedLinks.includes('revenue-report') && (
            <ListItem
              onClick={() => {
                // setCurrentNav(0);
                navigate('/revenue-report');
              }}
              active={location.pathname === '/revenue-report'}
            >
              <SummarizeIcon sx={{ fontSize: '1.4rem' }} />
              {sideBarExtended && <p>Revenue Report</p>}
            </ListItem>
          )}
          {permitedLinks.includes('reach-report') && (
            <ListItem
              onClick={() => {
                // setCurrentNav(0);
                navigate('/reach-report');
              }}
              active={location.pathname === '/reach-report'}
            >
              <SummarizeIcon sx={{ fontSize: '1.4rem' }} />
              {sideBarExtended && <p>Reach Report</p>}
            </ListItem>
          )}
          {permitedLinks.includes('payment-report') && (
            <ListItem
              onClick={() => {
                // setCurrentNav(0);
                navigate('/payment-report');
              }}
              active={location.pathname === '/payment-report'}
            >
              <SummarizeIcon sx={{ fontSize: '1.4rem' }} />
              {sideBarExtended && <p>Payment Report</p>}
            </ListItem>
          )}
          {permitedLinks.includes('rewards-status') && (
            <ListItem
              onClick={() => {
                // setCurrentNav(0);
                navigate('/rewards-status');
              }}
              active={location.pathname === '/rewards-status'}
            >
              <SummarizeIcon sx={{ fontSize: '1.4rem' }} />
              {sideBarExtended && <p>Rewards Status</p>}
            </ListItem>
          )}
          {permitedLinks.includes('lead-report') && (
            <ListItem
              onClick={() => {
                // setCurrentNav(0);
                navigate('/lead-report');
              }}
              active={location.pathname === '/lead-report'}
            >
              <SummarizeIcon sx={{ fontSize: '1.4rem' }} />
              {sideBarExtended && <p>Lead Report</p>}
            </ListItem>
          )}
          {permitedLinks.includes('add-reward') && (
            <ListItem
              onClick={() => {
                // setCurrentNav(0);
                navigate('/add-reward');
              }}
              active={location.pathname === '/add-reward'}
            >
              <DiscountIcon sx={{ fontSize: '1.4rem' }} />
              {sideBarExtended && <p>Add Reward</p>}
            </ListItem>
          )}
           {permitedLinks.includes('payment-link') && (
            <ListItem
              onClick={() => {
                // setCurrentNav(0);
                navigate('/payment-link');
              }}
              active={location.pathname === '/payment-link'}
            >
              <DiscountIcon sx={{ fontSize: '1.4rem' }} />
              {sideBarExtended && <p>Payment Link</p>}
            </ListItem>
          )}
          {permitedLinks.includes('business-vise-report') && (
            <ListItem
              onClick={() => {
                // setCurrentNav(0);
                navigate('/business-vise-report');
              }}
              active={location.pathname === '/business-vise-report'}
            >
              <DiscountIcon sx={{ fontSize: '1.4rem' }} />
              {sideBarExtended && <p>Business Vise Report</p>}
            </ListItem>
          )}
          {/* {permitedLinks.includes('reach-reward') && (
            <ListItem
              onClick={() => {
                // setCurrentNav(0);
                navigate('/reach-reward');
              }}
              active={location.pathname === '/reach-reward'}
            >
              <DiscountIcon sx={{ fontSize: '1.4rem' }} />
              {sideBarExtended && <p>Reach Reward</p>}
            </ListItem>
          )} */}
          {permitedLinks.includes('all-business') && (
            <ListItem
              onClick={() => {
                // setCurrentNav(0);
                navigate('/all-business');
              }}
              active={location.pathname === '/all-business'}
            >
              <BusinessCenterIcon sx={{ fontSize: '1.4rem' }} />
              {sideBarExtended && <p>Businesses</p>}
            </ListItem>
          )}
          {permitedLinks.includes('create-campaign') && (
            <ListItem
              onClick={() => {
                // setCurrentNav(1);
                navigate('/create-campaign');
              }}
              active={location.pathname === '/create-campaign'}
            >
              <AddBusinessIcon sx={{ fontSize: '1.4rem' }} />
              {sideBarExtended && <p>New Campaign</p>}
            </ListItem>
          )}
        </List>
      </SideBar>
      <Topbar>
        <TopbarLeft />
        <TopbarRight>
          <AvatartCont>
            <Avatar sx={{ width: 35, height: 35 }} src={login.picture} />
            <AvatarMenu onClick={(e) => setAvatarMenu(e.currentTarget)}>
              <p>{login.name}</p>
              <KeyboardArrowDownIcon
                sx={{ fontSize: '1rem', color: '#74788d' }}
              />
            </AvatarMenu>
            <Menu
              id="basic-menu"
              anchorEl={avatarMenu}
              open={avatarMenu}
              onClose={() => setAvatarMenu(false)}
            >
              <MenuItem onClick={() => setAvatarMenu(false)}>Profile</MenuItem>
              <MenuItem onClick={() => setAvatarMenu(false)}>
                My account
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setAvatarMenu(false);
                  handle_logout();
                }}
              >
                Logout
              </MenuItem>
            </Menu>
          </AvatartCont>
        </TopbarRight>
      </Topbar>

      <Main>
        {/* {type === 'create-campaign' && <CreateCampaignContainer />}
        {type === 'all-campaigns' && <AllCampaignContainer />}
        {type === 'all-business' && <AllBusinessContainer />}
        {type === 'need-attention' && <NeedAttentionContainer />}
        {type === 'revenue-report' && <RevenueReportContainer />} */}
        {type === 'reach-report' && <SalesReachReportContainer />}
        {type === 'lead-report' && <SalesLeadsReportContainer />}
        {type === 'payment-report' && <PaymentReportContainer />}
        {type === 'rewards-status' && <RewardsStatusContainer />}
        {type === 'add-reward' && <LeadRewardContainer />}
        {type === 'payment-link' && <PaymentLinkContainer />}
        {type === 'business-vise-report' && <BusinessViseReportContainer />}
        {/* {type === 'reach-reward' && <ReachRewardContainer />} */}
        {type === 'unauthorized' && <UnauthorizedContainer />}
      </Main>
    </Wrapper>
  );
};

const mapStateToProps = (store) => {
  return {
    login: store.login,
    snackbar: store.snackbar,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    set_reload_login: (user) => {
      dispatch(set_reload_login(user));
    },
    set_business_details:(payload)=>{
      dispatch(set_business_details(payload))
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
