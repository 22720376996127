import React, { useEffect, useRef, useState } from 'react';
import {
  Wrapper,
  CustomCard,
  Flex,
  FilterItem,
  TableContainer,
  ProductCell,
  ModalContent,
  EmptyCampaign,
  Detail,
  DetailHead,
  DetailHeadLeft,
  DetailHeadRight,
  Desc,
  AddCopyContainer,
  AddCopy,
  Product,
  ProductDetail,
  ProductDescription,
  StatusChip,
  BusinessDetail,
  Filters,
  CustomChip,
  StatsFlex,
  TableHeadCell,
  SortIcon,
  AllocateCampaign,
  CampaignModalWrapper,
  AlloteeList,
  TitleFlex,
  PdfSnapshot,
  PDF,
  PDFTitle,
  PDFFilters,
} from './PaymentReport.styles';
import {
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Select,
  MenuItem,
  TextField,
  Pagination,
  Modal,
  CircularProgress,
  Skeleton,
  InputAdornment,
  Checkbox,
  Button,
  Radio,
} from '@mui/material';

import { AUTHORIZED_USERS, SUPER_ADMIN } from '../../config/accessPermission';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import SearchIcon from '@mui/icons-material/Search';
import SaveIcon from '@mui/icons-material/Save';
import SouthIcon from '@mui/icons-material/South';
import NorthIcon from '@mui/icons-material/North';

import Cake from '../../assets/cake.jpg';
import NoDataImg from '../../assets/noData.jpg';
import logo from '../../assets/logo.png';

const PaymentReport = ({
  login,
  campaign,
  payment,
  business,
  get_campaigns_payment_report,
  allocate_campaign_payment_report,
  set_allocte_campaign_payment_report_status,
  set_reload_login,
  get_payment_history,
}) => {
  const navigate = useNavigate();
  const [allCampaign, setAllCampaign] = useState([]);
  const [productModal, setProductModal] = useState(false);
  const [toDate, setToDate] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [expandedRow, setExpandedRow] = useState(null);
  const [campaignStatus, setCampaignStatus] = useState('ALL_STATUS');
  const [filtered, setFiltered] = useState([]);
  const [businessSearch, setBusinessSearch] = useState('');
  const [page, setPage] = useState(1);
  const [sortCol, setSortCol] = useState('');
  const [sortType, setSortType] = useState(0);
  const [allocateCampaignModal, setAllocateCampaignModal] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState([]);
  const [consultants, setConsultants] = useState([]);
  const [selectedAllotee, setSelectedAllotee] = useState('');
  const [alloteeSearch, setAlloteeSearch] = useState('');
  const [pdfModal, setPdfModal] = useState(false);
  const [paymentHistoryAnchor, setPaymentHistoryAnchor] = useState('');

  useEffect(() => {
    if (!AUTHORIZED_USERS.includes(login.email)) {
      console.log('JJJJ');
      navigate('/unauthorized');
    }
  }, [login]);

  useEffect(() => {
    set_allocte_campaign_payment_report_status('');
  }, []);

  useEffect(() => {
    setConsultants(campaign.marketing_consultant);
  }, [campaign]);

  useEffect(() => {
    if (alloteeSearch === '') {
      setConsultants(campaign.marketing_consultant);
    } else {
      var filter_data = campaign.marketing_consultant.filter((item) =>
        item.consultant_name.toLowerCase().includes(alloteeSearch.toLowerCase())
          ? true
          : false
      );

      setConsultants(filter_data);
    }
  }, [alloteeSearch]);

  useEffect(() => {
    setAllCampaign(payment?.all_campaigns_payment_report);
  }, [payment]);

  useEffect(() => {
    var today = new Date();

    var to = `${today.getFullYear()}-${today.getMonth() + 1 < 10
      ? `0${today.getMonth() + 1}`
      : today.getMonth() + 1
      }-${today.getDate() < 10 ? `0${today.getDate()}` : today.getDate()}`;

    today.setDate(today.getDate() - 14);

    var from = `${today.getFullYear()}-${today.getMonth() + 1 < 10
      ? `0${today.getMonth() + 1}`
      : today.getMonth() + 1
      }-${today.getDate() < 10 ? `0${today.getDate()}` : today.getDate()}`;

    setFromDate(from);
    setToDate(to);

    get_campaigns_payment_report(from, to);
    console.log('PAYMENT REPROT REDNER');
  }, [login]);

  useEffect(() => {
    get_campaigns_payment_report(fromDate, toDate);
  }, [toDate, fromDate]);

  useEffect(() => {
    setSelectedCampaign([]);
    setSelectedAllotee('');
    if (businessSearch === '' && campaignStatus === 'ALL_STATUS') {
      setFiltered([]);
    } else {
      var newList = [];
      if (campaignStatus === 'ALL_STATUS' && businessSearch !== '') {
        newList = payment?.all_campaigns_payment_report?.filter((item) => {
          var tmp = item?.business_name?.toLowerCase();
          return tmp.includes(businessSearch.toLowerCase());
        });
      } else if (campaignStatus !== 'ALL_STATUS' && businessSearch === '') {
        newList = payment?.all_campaigns_payment_report?.filter((item) => {
          return String(item.status) === campaignStatus;
        });
      } else if (campaignStatus !== 'ALL_STATUS' && businessSearch !== '') {
        newList = payment?.all_campaigns_payment_report?.filter((item) => {
          var tmp = item?.business_name?.toLowerCase();
          return (
            tmp.includes(businessSearch.toLowerCase()) &&
            String(item.status) === campaignStatus
          );
        });
      }
      setFiltered(newList);
      setPage(1);
    }
  }, [businessSearch, campaignStatus]);

  useEffect(() => {
    if (
      businessSearch === '' &&
      campaignStatus === 'ALL_STATUS' &&
      sortCol !== ''
    ) {
      let tmp1 = allCampaign;
      if (sortCol === 'leadsBought') {
        tmp1.sort((a, b) => b.promised_leads - a.promised_leads);
      } else if (sortCol === 'budget') {
        tmp1.sort((a, b) => b.budget - a.budget);
      } else if (sortCol === 'leadsReceived') {
        tmp1.sort((a, b) => b.results - a.results);
      } else if (sortCol === 'amountSpent') {
        tmp1.sort((a, b) => b.spend - a.spend);
      } else if (sortCol === 'costPerResult') {
        tmp1.sort((a, b) => b.spend / b.results - a.spend / a.results);

        let splitIndex = 0;

        tmp1.map((item, i) => {
          if (!item.results || item.results === 0) {
            splitIndex = i;
          }
        });

        let emptyVal = tmp1.slice(0, splitIndex);
        let actualVal = tmp1.slice(splitIndex + 1, -1);
        // tmp1.reverse();
        tmp1 = [...actualVal, ...emptyVal];
      } else if (sortCol === 'margin') {
        tmp1.sort(
          (a, b) =>
            (b.budget - b.spend) / b.budget - (a.budget - a.spend) / a.budget
        );

        let splitIndex = 0;

        tmp1.map((item, i) => {
          if (!item.spend || !item.budget) {
            splitIndex = i;
          }
        });

        let emptyVal = tmp1.slice(0, splitIndex);
        let actualVal = tmp1.slice(splitIndex + 1, -1);
        // tmp1.reverse();
        tmp1 = [...actualVal, ...emptyVal];
      } else if (sortCol === 'ctr') {
        tmp1.sort((a, b) => b.ctr - a.ctr);
      } else if (sortCol === 'paymentAmount') {
        tmp1.sort((a, b) => b.AMOUNT_WITH_TAX - a.AMOUNT_WITH_TAX);
      } else if (sortCol === 'leadBalance') {
        tmp1.sort(
          (a, b) =>
            b.promised_leads - b.results - (a.promised_leads - a.results)
        );
      } else if (sortCol === 'convRate') {
        tmp1.sort((a, b) =>
          !b.clicks || !b.results || !a.results || !a.clicks
            ? 1
            : b.results / b.clicks - a.results / a.clicks
        );
      } else if (sortCol === 'createdAt') {
        tmp1.sort(
          (a, b) =>
            new Date(b.CREATED_AT).getTime() - new Date(a.CREATED_AT).getTime()
        );
      } else if (sortCol === 'endAt') {
        tmp1.sort(
          (a, b) =>
            new Date(b.campaign_end_date).getTime() -
            new Date(a.campaign_end_date).getTime()
        );
      } else if (sortCol === 'city') {
        tmp1.sort((a, b) =>
          b?.city?.toLowerCase().localeCompare(a?.city.toLowerCase())
        );
      } else if (sortCol === 'phone') {
        tmp1.sort((a, b) => b.mobile - a.mobile);
      }
      if (sortType === 1) {
        tmp1.reverse();
      }

      setAllCampaign([...tmp1]);
    } else if (
      businessSearch !== '' ||
      (campaignStatus !== 'ALL_STATUS' && sortCol !== '')
    ) {
      let tmp2 = filtered;
      if (sortCol === 'leadsBought') {
        tmp2.sort((a, b) => b.promised_leads - a.promised_leads);
      } else if (sortCol === 'budget') {
        tmp2.sort((a, b) => b.budget - a.budget);
      } else if (sortCol === 'leadsReceived') {
        tmp2.sort((a, b) => b.results - a.results);
      } else if (sortCol === 'amountSpent') {
        tmp2.sort((a, b) => b.spend - a.spend);
      } else if (sortCol === 'paymentAmount') {
        tmp2.sort((a, b) => b.AMOUNT_WITH_TAX - a.AMOUNT_WITH_TAX);
      } else if (sortCol === 'costPerResult') {
        tmp2.sort((a, b) => b.spend / b.results - a.spend / a.results);

        let splitIndex = 0;

        tmp2.map((item, i) => {
          if (!item.results || item.results === 0) {
            splitIndex = i;
          }
        });

        let emptyVal = tmp2.slice(0, splitIndex);
        let actualVal = tmp2.slice(splitIndex + 1, -1);
        // tmp2.reverse();
        tmp2 = [...actualVal, ...emptyVal];
      } else if (sortCol === 'margin') {
        tmp2.sort(
          (a, b) =>
            (b.budget - b.spend) / b.budget - (a.budget - a.spend) / a.budget
        );

        let splitIndex = 0;

        tmp2.map((item, i) => {
          if (!item.spend || !item.budget) {
            splitIndex = i;
          }
        });

        let emptyVal = tmp2.slice(0, splitIndex);
        let actualVal = tmp2.slice(splitIndex + 1, -1);
        // tmp2.reverse();
        tmp2 = [...actualVal, ...emptyVal];
      } else if (sortCol === 'ctr') {
        tmp2.sort((a, b) => b.ctr - a.ctr);
      } else if (sortCol === 'leadBalance') {
        tmp2.sort(
          (a, b) =>
            b.promised_leads - b.results - (a.promised_leads - a.results)
        );
      } else if (sortCol === 'convRate') {
        tmp2.sort((a, b) =>
          !b.clicks || !b.results || !a.results || !a.clicks
            ? 1
            : b.results / b.clicks - a.results / a.clicks
        );
      } else if (sortCol === 'createdAt') {
        tmp2.sort(
          (a, b) =>
            new Date(b.CREATED_AT).getTime() - new Date(a.CREATED_AT).getTime()
        );
      } else if (sortCol === 'endAt') {
        tmp2.sort(
          (a, b) =>
            new Date(b.campaign_end_date).getTime() -
            new Date(a.campaign_end_date).getTime()
        );
      } else if (sortCol === 'city') {
        tmp2.sort((a, b) =>
          b?.city?.toLowerCase().localeCompare(a?.city.toLowerCase())
        );
      } else if (sortCol === 'phone') {
        tmp2.sort((a, b) => b.mobile - a.mobile);
      }
      if (sortType === 1) {
        tmp2.reverse();
      }
      setFiltered([...tmp2]);
    }
  }, [sortCol, sortType]);

  const handle_sort = (col) => {
    setSortCol(col);
    if (sortType === 1) {
      setSortType(0);
    } else if (sortType === 0) {
      setSortType(1);
      // setSortCol("")
    }
  };

  useEffect(() => {
    if (sessionStorage.getItem('zocket_token')) {
      const token = sessionStorage.getItem('zocket_token');
      const loginData = JSON.parse(sessionStorage?.getItem('zocket_login'));
      set_reload_login({ token, ...loginData });
    } else {
      navigate('/');
    }
  }, [sessionStorage.getItem('zocket_token')]);

  const get_add_copy = (media) =>
    media?.map((item, i) => {
      return (
        <AddCopy key={i}>
          {item.adImage && <img src={item.adImage} />}
          {item.adVideo && (
            <video width="144" height="96" controls>
              <source src={item.adVideo} type="video/mp4" />
            </video>
          )}
          <p>{item.adCaption}</p>
        </AddCopy>
      );
    });

  const add_to_selected_campaign_list = (row) => {
    console.log(row, 'ROW');
    setSelectedCampaign([...selectedCampaign, row]);
  };

  const remove_from_selected_campaign_list = (id) => {
    var new_list = selectedCampaign.filter((item) => {
      if (item.campaign_id === id) {
        return false;
      } else {
        return true;
      }
    });
    setSelectedCampaign(new_list);
  };

  const handle_allocate_consultant = () => {
    var allocations = [];
    selectedCampaign.map((item) => {
      allocations.push({
        campaign_id: String(item.campaign_id),
        consultant_id: String(selectedAllotee),
      });
    });

    console.log(allocations, selectedCampaign, 'ALLOCATION DATA');

    allocate_campaign_payment_report(
      { allocations: allocations },
      fromDate,
      toDate
    );
  };

  useEffect(() => {
    if (campaign.allocate_campaign_status === 'success') {
      setAllocateCampaignModal(false);
      setSelectedCampaign([]);
      setSelectedAllotee('');
    }
  }, [campaign]);

  return (
    <Wrapper>
      <TitleFlex>
        <h3>Payment Report</h3>
      </TitleFlex>
      <StatsFlex></StatsFlex>
      {selectedCampaign.length !== 0 && SUPER_ADMIN.includes(login.email) && (
        <AllocateCampaign>
          <p>{selectedCampaign.length} campagins selected</p>
          <Button
            onClick={(e) => setAllocateCampaignModal(true)}
            variant="contained"
          >
            Allocate these campaign
          </Button>
        </AllocateCampaign>
      )}

      <CustomCard width="100%">
        <Filters>
          <Flex>
            <FilterItem>
              <searchBox>
                <TextField
                  sx={{ width: '20rem' }}
                  size="small"
                  placeholder="Enter Business Name"
                  value={businessSearch}
                  onChange={(e) => setBusinessSearch(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon sx={{ fontSize: '1.3rem' }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </searchBox>
            </FilterItem>
          </Flex>
          <Flex>
            <FilterItem>
              <label>Campaign Status:</label>
              <Select
                size="small"
                style={{ width: '10rem' }}
                value={campaignStatus}
                onChange={(e) => setCampaignStatus(e.target.value)}
              >
                <MenuItem value="ALL_STATUS">All Status</MenuItem>
                <MenuItem value="1">Paid</MenuItem>
                <MenuItem value="0">Not paid</MenuItem>
              </Select>
            </FilterItem>
            <FilterItem>
              <label>From:</label>
              <TextField
                size="small"
                style={{ width: '10rem' }}
                type="date"
                value={fromDate}
                onChange={(e) => setFromDate(e.target.value)}
              />
            </FilterItem>
            <FilterItem>
              <label>To:</label>
              <TextField
                size="small"
                style={{ width: '10rem' }}
                type="date"
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
              />
            </FilterItem>
          </Flex>
        </Filters>
        <TableContainer>
          <Table sx={{ minWidth: 650, width: '100%' }}>
            <TableHead
              sx={{
                backgroundColor: '#F8F8F8',
                color: 'rgba(0, 0, 0, 0.5)',
                padding: '0',
                border: '1.5px solid #EAEAEA',
              }}
            >
              <TableRow
                sx={{
                  fontSize: '0.8rem',
                  padding: '0',
                }}
              >
                <TableCell
                  sx={{
                    color: 'rgba(0, 0, 0, 0.5)',
                    padding: '0.5rem',
                    border: '2px solid #EAEAEA',
                  }}
                  align="center"
                >
                  <p>Business ID</p>
                </TableCell>
                <TableCell
                  sx={{
                    color: 'rgba(0, 0, 0, 0.5)',
                    padding: '0.5rem',
                    border: '2px solid #EAEAEA',
                  }}
                  align="center"
                >
                  <p>Business Name</p>
                </TableCell>
                <TableCell
                  sx={{
                    color: 'rgba(0, 0, 0, 0.5)',
                    width: '12rem',
                    padding: '0.5rem',
                    border: '2px solid #EAEAEA',
                  }}
                  align="center"
                >
                  <p>Phone Number</p>
                </TableCell>

                <TableCell
                  sx={{
                    color:
                      sortCol === 'paymentAmount'
                        ? '#5D4037'
                        : 'rgba(0, 0, 0, 0.5)',
                    padding: '0.5rem',
                    cursor: 'pointer',
                    border: '2px solid #EAEAEA',
                  }}
                  align="center"
                  onClick={() => handle_sort('paymentAmount')}
                >
                  <TableHeadCell>
                    <p>Payment Amount</p>
                    <SortIcon>
                      {sortCol === 'paymentAmount' && sortType === 1 && (
                        <NorthIcon
                          style={{
                            fontSize: '1.4rem',
                            marginLeft: '0.5rem',
                            color:
                              sortCol === 'paymentAmount'
                                ? '#5D4037'
                                : '#9E9E9E',
                          }}
                        />
                      )}{' '}
                      {sortCol === 'paymentAmount' && sortType === 0 && (
                        <SouthIcon
                          style={{
                            fontSize: '1.4rem',
                            marginLeft: '0.5rem',
                            color:
                              sortCol === 'paymentAmount'
                                ? '#5D4037'
                                : '#9E9E9E',
                          }}
                        />
                      )}
                    </SortIcon>
                  </TableHeadCell>
                </TableCell>
                <TableCell
                  sx={{
                    color:
                      sortCol === 'createdAt'
                        ? '#5D4037'
                        : 'rgba(0, 0, 0, 0.5)',
                    padding: '0.5rem',
                    cursor: 'pointer',
                    border: '2px solid #EAEAEA',
                  }}
                  onClick={() => handle_sort('createdAt')}
                  align="center"
                >
                  <TableHeadCell>
                    <p>Payment Created At</p>
                    <SortIcon>
                      {sortCol === 'createdAt' && sortType === 1 && (
                        <NorthIcon
                          style={{
                            fontSize: '1.4rem',
                            marginLeft: '0.5rem',
                            color:
                              sortCol === 'createdAt' ? '#5D4037' : '#9E9E9E',
                          }}
                        />
                      )}{' '}
                      {sortCol === 'createdAt' && sortType === 0 && (
                        <SouthIcon
                          style={{
                            fontSize: '1.4rem',
                            marginLeft: '0.5rem',
                            color:
                              sortCol === 'createdAt' ? '#5D4037' : '#9E9E9E',
                          }}
                        />
                      )}
                    </SortIcon>
                  </TableHeadCell>
                </TableCell>
                <TableCell
                  sx={{
                    color:
                      sortCol === 'endAt'
                        ? '#5D4037'
                        : 'rgba(0, 0, 0, 0.5)',
                    padding: '0.5rem',
                    cursor: 'pointer',
                    border: '2px solid #EAEAEA',
                  }}
                  onClick={() => handle_sort('endAt')}
                  align="center"
                >
                  <TableHeadCell>
                    <p>Transaction End At</p>
                    <SortIcon>
                      {sortCol === 'endAt' && sortType === 1 && (
                        <NorthIcon
                          style={{
                            fontSize: '1.4rem',
                            marginLeft: '0.5rem',
                            color:
                              sortCol === 'endAt' ? '#5D4037' : '#9E9E9E',
                          }}
                        />
                      )}{' '}
                      {sortCol === 'endAt' && sortType === 0 && (
                        <SouthIcon
                          style={{
                            fontSize: '1.4rem',
                            marginLeft: '0.5rem',
                            color:
                              sortCol === 'endAt' ? '#5D4037' : '#9E9E9E',
                          }}
                        />
                      )}
                    </SortIcon>
                  </TableHeadCell>
                </TableCell>
                <TableCell
                  sx={{
                    color: 'rgba(0, 0, 0, 0.5)',
                    padding: '0.5rem',
                    border: '2px solid #EAEAEA',
                  }}
                  align="center"
                >
                  <p>Status</p>
                </TableCell>
              </TableRow>
            </TableHead>
            {payment?.all_campaigns_payment_report?.length === 0 &&
              campaign.campaign_loader === false && (
                <TableBody>
                  <TableRow>
                    <TableCell />
                    <TableCell />

                    <TableCell colSpan={2}>
                      <EmptyCampaign>
                        <p>No campaigns to show!!</p>
                        <img src={NoDataImg} />
                      </EmptyCampaign>
                    </TableCell>

                    <TableCell />
                  </TableRow>
                </TableBody>
              )}
            {campaign.campaign_loader && (
              <TableBody>
                {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((row, i) => {
                  return (
                    <>
                      <TableRow
                        key={i}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          <Skeleton
                            variant="rectangular"
                            width="100%"
                            height={10}
                          />
                        </TableCell>

                        <TableCell align="center">
                          <Skeleton
                            variant="rounded"
                            width="100%"
                            height={15}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <Skeleton
                            variant="rounded"
                            width="100%"
                            height={15}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <Skeleton
                            variant="rounded"
                            width="100%"
                            height={15}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <Skeleton
                            variant="rounded"
                            width="100%"
                            height={15}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <Skeleton
                            variant="rounded"
                            width="100%"
                            height={15}
                          />
                        </TableCell>

                        <TableCell align="center">
                          <Skeleton
                            variant="rounded"
                            width="100%"
                            height={15}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <Skeleton
                            variant="rounded"
                            width="100%"
                            height={15}
                          />
                        </TableCell>

                        <TableCell align="center">
                          <Skeleton
                            variant="rounded"
                            width="100%"
                            height={15}
                          />
                        </TableCell>

                        {SUPER_ADMIN.includes(login.email) && (
                          <TableCell align="center">
                            <Skeleton
                              variant="rounded"
                              width="100%"
                              height={15}
                            />
                          </TableCell>
                        )}
                      </TableRow>
                    </>
                  );
                })}
              </TableBody>
            )}
            {!campaign.campaign_loader &&
              (businessSearch !== '' || campaignStatus !== 'ALL_STATUS') &&
              filtered.length === 0 && (
                <TableBody>
                  <TableRow>
                    <TableCell />
                    <TableCell />

                    <TableCell colSpan={2}>
                      <EmptyCampaign>
                        <p>No campaigns to show!!</p>
                        <img src={NoDataImg} />
                      </EmptyCampaign>
                    </TableCell>

                    <TableCell />
                  </TableRow>
                </TableBody>
              )}
            {!campaign.campaign_loader && (
              <TableBody>
                {filtered.length !== 0 &&
                  filtered
                    ?.slice((page - 1) * 10, (page - 1) * 10 + 10)
                    ?.map((row, i) => {
                      return (
                        <>
                          <TableRow key={row?.CLIENT_ID}>
                            <TableCell
                              align="center"
                              sx={{
                                border: '1px solid #EAEAEA',
                                padding: '0.5rem',
                              }}
                            >
                              {row?.CLIENT_ID}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                width: '12rem',
                                border: '1px solid #eaeaea',
                                padding: '0.5rem',
                              }}
                              component="th"
                              scope="row"
                            >
                              <BusinessDetail>
                                <p>{row.business_name}</p>
                              </BusinessDetail>
                            </TableCell>

                            <TableCell
                              align="center"
                              sx={{
                                border: '1px solid #EAEAEA',
                                padding: '0.5rem',
                              }}
                            >
                              {row?.mobile ? row?.mobile : '-'}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                width: '12rem',
                                border: '1px solid #EAEAEA',
                                padding: '0.5rem',
                              }}
                              component="th"
                              scope="row"
                            >
                              <BusinessDetail>
                                <p>Rs. {row?.AMOUNT_WITH_TAX || '-'}</p>
                              </BusinessDetail>
                            </TableCell>
                            <TableCell
                              sx={{
                                width: '7rem',
                                border: '1px solid #EAEAEA',
                                padding: '0.5rem',
                              }}
                              align="center"
                            >
                              {row?.CREATED_AT ? (moment(row.CREATED_AT).format('ll')) : "-"}
                            </TableCell>
                            <TableCell
                              sx={{
                                width: '7rem',
                                border: '1px solid #EAEAEA',
                                padding: '0.5rem',
                              }}
                              align="center"
                            >
                              { row?.TRANSACTION_ENDED_AT ? (moment(row.TRANSACTION_ENDED_AT).format('ll')) : "-"}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                border: '1px solid #EAEAEA',
                                padding: '0.5rem',
                              }}
                            >
                              <StatusChip status={row.status}>
                                {row.status === 1 ? 'Paid' : 'Not paid'}
                              </StatusChip>
                            </TableCell>
                            
                          </TableRow>
                          {expandedRow === i && (
                            <TableRow>
                              <TableCell
                                colSpan={
                                  SUPER_ADMIN.includes(login.email) ? 18 : 17
                                }
                              >
                                <Detail>
                                  <DetailHead>
                                    <DetailHeadLeft>
                                      <p>Ad Copies</p>
                                    </DetailHeadLeft>
                                    <DetailHeadRight
                                      style={{
                                        display:
                                          row.media === null ? 'none' : 'flex',
                                      }}
                                    >
                                      <p>Product</p>
                                      <p>Product Description</p>
                                    </DetailHeadRight>
                                  </DetailHead>
                                  <Desc>
                                    <AddCopyContainer>
                                      {get_add_copy(
                                        JSON.parse(row.campaign_platform_meta)
                                          .adPreview
                                      )}
                                    </AddCopyContainer>
                                    <Product
                                      style={{
                                        display:
                                          row.media === null ? 'none' : 'flex',
                                      }}
                                    >
                                      {JSON.parse(row.media) !== null && (
                                        <img
                                          src={JSON.parse(row.media)[0]?.url}
                                        />
                                      )}
                                      <ProductDetail
                                        style={{
                                          display:
                                            row.media === null ? 'none' : 'block',
                                        }}
                                      >
                                        <h4>{row.product_name}</h4>
                                        <p>Price : {row.selling_price}</p>
                                      </ProductDetail>
                                      <ProductDescription>
                                        {row.promotion_tags !== null &&
                                          JSON.parse(row?.promotion_tags)
                                            .length !== 0 &&
                                          JSON.parse(row?.promotion_tags)?.map(
                                            (item, i) => {
                                              return (
                                                <CustomChip key={i}>
                                                  {item}
                                                </CustomChip>
                                              );
                                            }
                                          )}
                                        {row.promotion_tags !== null &&
                                          JSON.parse(row?.promotion_tags)
                                            .length === 0 && (
                                            <CustomChip>
                                              {row.product_description
                                                ? row.product_description
                                                : 'No Description'}
                                            </CustomChip>
                                          )}
                                      </ProductDescription>
                                    </Product>
                                  </Desc>
                                </Detail>
                              </TableCell>
                            </TableRow>
                          )}
                        </>
                      )
                    })}
              </TableBody>
            )}
            {!campaign.campaign_loader && (
              <TableBody>
                {filtered.length === 0 &&
                  businessSearch === '' &&
                  campaignStatus === 'ALL_STATUS' &&
                  allCampaign
                    ?.slice((page - 1) * 10, (page - 1) * 10 + 10)
                    ?.map((row, i) => (
                      <>
                        <TableRow key={row?.CLIENT_ID}>
                          <TableCell
                            align="center"
                            sx={{
                              border: '1px solid #EAEAEA',
                              padding: '0.5rem',
                            }}
                          >
                            {row?.CLIENT_ID}
                          </TableCell>

                          <TableCell
                            align="center"
                            sx={{
                              width: '12rem',
                              border: '1px solid #EAEAEA',
                              padding: '0.5rem',
                            }}
                            component="th"
                            scope="row"
                          >
                            <BusinessDetail>
                              <p>{row?.business_name}</p>
                              <span>{row?.business_category}</span>
                            </BusinessDetail>
                          </TableCell>

                          <TableCell
                            align="center"
                            sx={{
                              width: '12rem',
                              border: '1px solid #EAEAEA',
                              padding: '0.5rem',
                            }}
                            component="th"
                            scope="row"
                          >
                            <BusinessDetail>
                              <p>{row?.mobile}</p>
                            </BusinessDetail>
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              width: '12rem',
                              border: '1px solid #EAEAEA',
                              padding: '0.5rem',
                            }}
                            component="th"
                            scope="row"
                          >
                            <BusinessDetail>
                              <p>Rs. {row?.AMOUNT_WITH_TAX || '-'}</p>
                            </BusinessDetail>
                          </TableCell>
                          <TableCell
                            sx={{
                              width: '7rem',
                              border: '1px solid #EAEAEA',
                              padding: '0.5rem',
                            }}
                            align="center"
                          >
                            {row?.CREATED_AT ? (moment(row.CREATED_AT).format('ll')) : "-"}
                          </TableCell>
                          <TableCell
                            sx={{
                              width: '7rem',
                              border: '1px solid #EAEAEA',
                              padding: '0.5rem',
                            }}
                            align="center"
                          >
                            { row?.TRANSACTION_ENDED_AT ? (moment(row.TRANSACTION_ENDED_AT).format('ll')) : "-"}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              border: '1px solid #EAEAEA',
                              padding: '0.5rem',
                            }}
                          >
                            <StatusChip status={row?.status}>
                              {row.status === 1 ? 'Paid' : 'Not paid'}
                            </StatusChip>
                          </TableCell>
                        </TableRow>
                        {expandedRow === i && (
                          <TableRow>
                            <TableCell
                              colSpan={
                                SUPER_ADMIN.includes(login.email) ? 18 : 17
                              }
                            >
                              <Detail>
                                <DetailHead>
                                  <DetailHeadLeft>
                                    <p>Ad Copies</p>
                                  </DetailHeadLeft>
                                  <DetailHeadRight
                                    style={{
                                      display:
                                        row.media === null ? 'none' : 'flex',
                                    }}
                                  >
                                    <p>Product</p>
                                    <p>Product Description</p>
                                  </DetailHeadRight>
                                </DetailHead>
                                <Desc>
                                  <AddCopyContainer>
                                    {get_add_copy(
                                      JSON.parse(row?.campaign_platform_meta)
                                        .adPreview
                                    )}
                                  </AddCopyContainer>
                                  <Product
                                    style={{
                                      display:
                                        row.media === null ? 'none' : 'flex',
                                    }}
                                  >
                                    {JSON.parse(row.media) !== null && (
                                      <img
                                        src={JSON.parse(row.media)[0]?.url}
                                      />
                                    )}
                                    <ProductDetail
                                      style={{
                                        display:
                                          row.media === null ? 'none' : 'block',
                                      }}
                                    >
                                      <h4>{row?.product_name}</h4>
                                      <p>Price : {row?.selling_price}</p>
                                    </ProductDetail>
                                    <ProductDescription>
                                      {row.promotion_tags !== null &&
                                        JSON.parse(row?.promotion_tags)?.map(
                                          (item, i) => {
                                            return (
                                              <CustomChip key={i}>
                                                {item}
                                              </CustomChip>
                                            );
                                          }
                                        )}
                                      {row.promotion_tags !== null &&
                                        JSON.parse(row.promotion_tags)
                                          .length === 0 && (
                                          <CustomChip>
                                            {row.product_description
                                              ? row.product_description
                                              : 'No Description'}
                                          </CustomChip>
                                        )}
                                    </ProductDescription>
                                  </Product>
                                </Desc>
                              </Detail>
                            </TableCell>
                          </TableRow>
                        )}
                      </>
                    ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>

        <Pagination
          sx={{
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: '2rem',
          }}
          count={
            filtered.length === 0
              ? Math.ceil(payment?.all_campaigns_payment_report?.length / 10)
              : Math.ceil(filtered.length / 10)
          }
          page={page}
          onChange={(e, val) => setPage(val)}
          color="secondary"
        />
      </CustomCard>
    </Wrapper>
  );
};

export default PaymentReport;
