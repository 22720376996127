import {
  SET_CAMPAIGNS,
  SET_CAMPAIGN_LOADER,
  SET_MARKETING_CONSULTANT,
  SET_ALLOCATE_CAMPAIGN_STATUS,
} from '../../constants/campaign/campaignConstants';

const initial_state = {
  all_campaigns: [],
  marketing_consultant: [],
  campaign_loader: false,
  allocate_campaign_status: '',
};

export default function reducer(state = initial_state, action) {
  switch (action.type) {
    case SET_CAMPAIGNS:
      return (state = { ...state, all_campaigns: action.payload });
    case SET_MARKETING_CONSULTANT:
      return (state = { ...state, marketing_consultant: action.payload });
    case SET_ALLOCATE_CAMPAIGN_STATUS:
      return (state = { ...state, allocate_campaign_status: action.payload });
    case SET_CAMPAIGN_LOADER:
      return (state = { ...state, campaign_loader: action.payload });
    default:
      return state;
  }
}
