import UNIVERSAL from '../../config/config';
import {
  SET_PAYMENT_LINK,
  SET_PAYMENT_LINK_STATUS,
} from '../../constants/paymentLink/paymentLinkConst';

export function generate_payment_link(businessId, amount, phone, countryCode) {
  return (dispatch) => {
    dispatch(set_payment_link_status('loading'));
    return fetch(UNIVERSAL.BASEURL + '/payments/payment_link', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': '6gZ2J0WfUx9iAmKIwvwG3NiX1783yn094tzrssHe',
      },
      body: JSON.stringify({
        businessId: businessId,
        amount: amount,
        mobile: phone,
        country_dialling_code: '91',
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === true) {
          console.log(responseJson.status, 'RESPONSE KK');
          dispatch(set_payment_link_status('success'));
          dispatch(set_payment_link(responseJson.data.payment_link));
        } else {
          //   dispatch(
          //     set_login_snackbar({
          //       status: 'error',
          //       message: responseJson.message,
          //     })
          //   );
          set_payment_link_status('error');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function set_payment_link_status(payload) {
  return {
    type: SET_PAYMENT_LINK_STATUS,
    payload: payload,
  };
}

export function set_payment_link(payload) {
  return {
    type: SET_PAYMENT_LINK,
    payload: payload,
  };
}
