import {
    SET_CAMPAIGNS_REWARDS_STATUS,
    SET_CAMPAIGN_REWARDS_STATUS_LOADER,
  } from '../../constants/rewardsStatus/rewardsStatusConstant';
  
  const initial_state = {
    all_campaigns_rewards_status: [],
    campaign_rewards_status_loader: false,
    allocate_campaign_rewards_status_status: '',
  };
  
  export default function reducer(state = initial_state, action) {
    switch (action.type) {
      case SET_CAMPAIGNS_REWARDS_STATUS:
        return (state = { ...state, all_campaigns_rewards_status: action.payload });
      case SET_CAMPAIGN_REWARDS_STATUS_LOADER:
        return (state = { ...state, campaign_rewards_status_loader: action.payload });
      default:
        return state;
    }
  }
  