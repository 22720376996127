import UNIVERSAL from '../../config/config';

import {
    SET_CAMPAIGNS_PAYMENT_REPORT,
    SET_CAMPAIGN_PAYMENT_REPORT_LOADER,
    SET_ALLOCATE_CAMPAIGN_PAYMENT_REPORT_STATUS,
} from '../../constants/payment/paymentConstant';
import { set_snackbar } from '../snackbar/snackbarActions';

export function get_campaigns_payment_report_by_role(email, fromDate, toDate,campaignType) {
  return (dispatch) => {
    console.log('CHECK 3');
    dispatch(set_campaign_payment_report_loader(true));
    return fetch(UNIVERSAL.BASEURL + `/sales/marketing_consultant_details`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': '6gZ2J0WfUx9iAmKIwvwG3NiX1783yn094tzrssHe',
      },
      body: JSON.stringify({
        consultant_email: email,
        startDate: fromDate,
        endDate: toDate,
        campaign_type:campaignType
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        if (responseJson.success) {
          dispatch(set_campaigns_payment_report(responseJson.data.consultant_campaigns));
          dispatch(set_campaign_payment_report_loader(false));
          //   dispatch(get_business_profile(responseJson.data));
        } else {
          dispatch(set_campaigns_payment_report([]));
          dispatch(set_campaign_payment_report_loader(false));
          dispatch(
            set_snackbar({
              status: 'error',
              message: responseJson.message,
            })
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function get_campaigns_payment_report(fromDate, toDate) {
    return (dispatch) => {
        dispatch(set_campaign_payment_report_loader(true));
        return fetch(
            UNIVERSAL.BASEURL +
            `/sales/payments/links?startDate=${fromDate}&endDate=${toDate}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': '6gZ2J0WfUx9iAmKIwvwG3NiX1783yn094tzrssHe',
                },
            }
        )
            .then((response) => response.json())
            .then((responseJson) => {
                console.log(responseJson);
                if (responseJson.success) {
                    dispatch(set_campaigns_payment_report(responseJson.data));
                    dispatch(set_campaign_payment_report_loader(false));
                } else {
                    dispatch(set_campaign_payment_report_loader(false));
                    dispatch(
                        set_snackbar({
                            status: 'error',
                            message: responseJson.message,
                        })
                    );
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
}


export function allocate_campaign_payment_report(data, fromDate, toDate) {
  return (dispatch) => {
    dispatch(set_allocte_campaign_payment_report_status('loading'));
    return fetch(UNIVERSAL.BASEURL + `/sales/allocate_marketing_consultant`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': '6gZ2J0WfUx9iAmKIwvwG3NiX1783yn094tzrssHe',
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.success) {
          dispatch(set_allocte_campaign_payment_report_status('success'));
          dispatch(get_campaigns_payment_report(fromDate, toDate));
        } else {
          dispatch(set_allocte_campaign_payment_report_status('error'));
          dispatch(
            set_snackbar({
              status: 'error',
              message: responseJson.message,
            })
          );
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch(set_allocte_campaign_payment_report_status('error'));
      });
  };
}

export function set_campaigns_payment_report(payload) {
    return {
        type: SET_CAMPAIGNS_PAYMENT_REPORT,
        payload: payload,
    };
}


export function set_allocte_campaign_payment_report_status(payload) {
  return {
    type: SET_ALLOCATE_CAMPAIGN_PAYMENT_REPORT_STATUS,
    payload: payload,
  };
}

export function set_campaign_payment_report_loader(payload) {
    return {
        type: SET_CAMPAIGN_PAYMENT_REPORT_LOADER,
        payload: payload,
    };
}
