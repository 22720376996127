import UNIVERSAL from '../../config/config';

import {
  SET_ALLOCATE_CAMPAIGN_STATUS,
  SET_CAMPAIGNS,
  SET_CAMPAIGN_LOADER,
  SET_MARKETING_CONSULTANT,
} from '../../constants/campaign/campaignConstants';
import { set_snackbar } from '../snackbar/snackbarActions';

export function get_campaigns_by_role(email, fromDate, toDate,campaignType) {
  return (dispatch) => {
    console.log('CHECK 3');
    dispatch(set_campaign_loader(true));
    return fetch(UNIVERSAL.BASEURL + `/sales/marketing_consultant_details`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': '6gZ2J0WfUx9iAmKIwvwG3NiX1783yn094tzrssHe',
      },
      body: JSON.stringify({
        consultant_email: email,
        startDate: fromDate,
        endDate: toDate,
        campaign_type:campaignType
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        if (responseJson.success) {
          dispatch(set_campaigns(responseJson.data.consultant_campaigns));
          dispatch(set_campaign_loader(false));
          //   dispatch(get_business_profile(responseJson.data));
        } else {
          dispatch(set_campaigns([]));
          dispatch(set_campaign_loader(false));
          dispatch(
            set_snackbar({
              status: 'error',
              message: responseJson.message,
            })
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function get_campaigns(fromDate, toDate) {
  return (dispatch) => {
    dispatch(set_campaign_loader(true));
    return fetch(
      UNIVERSAL.BASEURL +
        `/sales/campaigns/campaign_products_data?type=date&startDate=${fromDate}&endDate=${toDate}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': '6gZ2J0WfUx9iAmKIwvwG3NiX1783yn094tzrssHe',
        },
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        if (responseJson.success) {
          dispatch(set_campaigns(responseJson.data));
          dispatch(set_campaign_loader(false));
          //   dispatch(get_business_profile(responseJson.data));
        } else {
          dispatch(set_campaign_loader(false));
          dispatch(
            set_snackbar({
              status: 'error',
              message: responseJson.message,
            })
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function get_marketing_consultants() {
  return (dispatch) => {
    return fetch(UNIVERSAL.BASEURL + `/sales/marketing_consultants`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': '6gZ2J0WfUx9iAmKIwvwG3NiX1783yn094tzrssHe',
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.success) {
          dispatch(set_marketing_consultant(responseJson.data));

          //   dispatch(get_business_profile(responseJson.data));
        } else {
          dispatch(
            set_snackbar({
              status: 'error',
              message: responseJson.message,
            })
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function allocate_campaign(data, fromDate, toDate) {
  return (dispatch) => {
    dispatch(set_allocte_campaign_status('loading'));
    return fetch(UNIVERSAL.BASEURL + `/sales/allocate_marketing_consultant`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': '6gZ2J0WfUx9iAmKIwvwG3NiX1783yn094tzrssHe',
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.success) {
          dispatch(set_allocte_campaign_status('success'));
          dispatch(get_campaigns(fromDate, toDate));
        } else {
          dispatch(set_allocte_campaign_status('error'));
          dispatch(
            set_snackbar({
              status: 'error',
              message: responseJson.message,
            })
          );
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch(set_allocte_campaign_status('error'));
      });
  };
}

export function set_campaigns(payload) {
  return {
    type: SET_CAMPAIGNS,
    payload: payload,
  };
}

export function set_marketing_consultant(payload) {
  return {
    type: SET_MARKETING_CONSULTANT,
    payload: payload,
  };
}

export function set_allocte_campaign_status(payload) {
  return {
    type: SET_ALLOCATE_CAMPAIGN_STATUS,
    payload: payload,
  };
}

export function set_campaign_loader(payload) {
  return {
    type: SET_CAMPAIGN_LOADER,
    payload: payload,
  };
}
