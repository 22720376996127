import styled from 'styled-components';
import { Card } from '@mui/material';

export const Wrapper = styled.section`
  width: 100%;
  /* height: 20rem; */
  padding: 1.5rem;

  h3 {
    font-weight: 500;
    font-size: 1rem;
    color: #444;
    margin-bottom: 0.6rem;
  }
`;

export const CustomCard = styled(Card)`
  margin-bottom: 1.6rem;
  padding: 1rem;
  width: ${(props) => props.width || 'max-content'};
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%) !important;

  p {
    color: #74788d;
    font-size: 0.85rem;
  }

  h4 {
    font-weight: 500;
    font-size: 1rem;
  }

  & > *:not(:last-child) {
    margin-bottom: 0.5rem;
  }
`;
