import React from 'react';
import { connect } from 'react-redux';
import UnAuthorized from '../../pages/unAutorized/UnAuthorized';

const UnauthorizedContainer = (props) => {
  return <UnAuthorized {...props} />;
};

const mapStateToProps = (store) => {
  return {
    login: store.login,
    snackbar: store.snackbar,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UnauthorizedContainer);
