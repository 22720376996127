import UNIVERSAL from '../../config/config';
import {
  SET_LEAD_REWARD_ID,
  SET_LEAD_REWARD_STATUS,
} from '../../constants/leadReward/leadRewardConst';

export function lead_reward(reward) {
  return (dispatch) => {
    return fetch(UNIVERSAL.BASEURL + '/sales/v2/add_reward', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': '6gZ2J0WfUx9iAmKIwvwG3NiX1783yn094tzrssHe',
      },
      body: JSON.stringify({
        business_id: `${reward.business_id}`,
        reward_type: 'LEADS',
        // is_fb_needed: reward.is_fb_needed,
        reward_sent_by: reward.reward_sent_by,
        reward_details: {
          reward_image: reward.reward_image,
          total_leads: reward.total_leads * 1,
          cost_per_lead: reward.cost_per_lead * 1,
        },
        subscription: {
          years: reward.subscription === '6 months'  ? 0 :  reward.subscription === '3 months' ? 0 : 1,
          months: reward.subscription === '6 months' ? 6  : reward.subscription === '3 months' ? 3:  0,
        },
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        if (responseJson.message === 'Successfully Created reward') {
          //   dispatch(set_business_details(responseJson.data));
          //   dispatch(get_business_profile(responseJson.data));
          dispatch(set_lead_reward_status(true));
        } else {
          //   dispatch(
          //     set_login_snackbar({
          //       status: 'error',
          //       message: responseJson.message,
          //     })
          //   );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function set_lead_reward_status(payload) {
  return {
    type: SET_LEAD_REWARD_STATUS,
    payload: payload,
  };
}

export function set_lead_reward_id(payload) {
  return {
    type: SET_LEAD_REWARD_ID,
    payload: payload,
  };
}
