import { SET_SNACKBAR } from '../../constants/snackbar/snackbarConstants';

const initial_state = {
  business: '',
};

export default function reducer(state = initial_state, action) {
  switch (action.type) {
    case SET_SNACKBAR:
      return (state = { ...state, login: action.payload });
    default:
      return state;
  }
}
