import React from 'react';
import { connect } from 'react-redux';
import {
  get_business,
  reset_business,
} from '../../actions/business/businessActions';
import NeedAttention from '../../pages/needAttention/NeedAttention';
import { get_campaigns } from '../../actions/campaign/campaignActions';
import { set_reload_login } from '../../actions/login/loginActions';

const NeedAttentionContainer = (props) => {
  return <NeedAttention {...props} />;
};

const mapStateToProps = (store) => {
  return {
    login: store.login,
    snackbar: store.snackbar,
    business: store.business.details,
    campaign: store.campaign,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    get_business: (phone) => {
      dispatch(get_business(phone));
    },
    get_campaigns: (startDate, endDate) => {
      dispatch(get_campaigns(startDate, endDate));
    },
    reset_business: () => {
      dispatch(reset_business());
    },
    set_reload_login: (login) => {
      dispatch(set_reload_login(login));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NeedAttentionContainer);
