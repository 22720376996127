import {
  SET_LEAD_REWARD_STATUS,
  SET_LEAD_REWARD_ID,
} from '../../constants/leadReward/leadRewardConst';

const initial_state = {
  add_reward_status: false,
  add_reward_id: '',
};

export default function reducer(state = initial_state, action) {
  switch (action.type) {
    case SET_LEAD_REWARD_STATUS:
      return (state = { ...state, add_reward_status: action.payload });
    case SET_LEAD_REWARD_ID:
      return (state = { ...state, add_reward_id: action.payload });
    default:
      return state;
  }
}
